import { Component } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faXmarkLarge } from '@fortawesome/pro-regular-svg-icons';

@Component({
	selector: 'app-close-icon',
	template: '<fa-icon [icon]="icon" />',
	standalone: true,
	imports: [FontAwesomeModule]
})
export class CloseIconComponent {
	icon = faXmarkLarge;
}
