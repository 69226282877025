
import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { ReplaySubject, tap, catchError, throwError, lastValueFrom } from 'rxjs';

import { AuthService } from './auth.service';
import { GlobalFiltersService } from './global-filters.service';

import { AffAppListViewModel, AffAppDetailsViewModel, AffAppDocumentViewModel, AffAppEditModel } from '../models/affapp.models';

import { handleHttpError, convertRandomDateTimeToISOString } from '../../functions';
import { Environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class AffAppService {
	private readonly baseUrl = `${Environment.apiBaseUrl}/affiliations`;

	private httpClient: HttpClient = inject(HttpClient);
	private authService: AuthService = inject(AuthService);
	private globalFiltersService: GlobalFiltersService = inject(GlobalFiltersService);

	getLeagueAffApps(leagueID: number) {
		console.log(`LOADING AFF APPS => ${this.globalFiltersService.getLeagueYearID() || 'NULL'}...`);

		const httpParams = new HttpParams().appendAll({
			leagueId: leagueID,
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.get<AffAppListViewModel[]>(
					`${this.baseUrl}`,
					{ params: httpParams })
				.pipe(
					tap(apps => {
						apps.forEach(app => {
							app.dateFinalSubmission = convertRandomDateTimeToISOString(app.dateFinalSubmission);
						});

					}),
					catchError(handleHttpError)
				)
		);
	}

	getLeagueAffAppDetails(affAppID: number) {
		console.log(`LOADING AFF APP DETAILS => ${this.globalFiltersService.getLeagueYearID() || 'NULL'}...`);

		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.get<AffAppDetailsViewModel>(
					`${this.baseUrl}/${affAppID}`,
					{ params: httpParams })
				.pipe(
					tap(appDetails => this.computeAffAppProperties(appDetails)),
					catchError(handleHttpError)
				)
		);
	}

	saveAffAppDraft(affApp: AffAppEditModel) {
		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		this.prepareAffAppForSave(affApp);

		return lastValueFrom(
			this.httpClient
				.post<AffAppDetailsViewModel>(
					`${this.baseUrl}`,
					affApp,
					{ params: httpParams })
				.pipe(
					tap(appDetails => this.computeAffAppProperties(appDetails)),
					catchError(handleHttpError)
				)
		);
	}

	submitAffAppForReview(affApp: AffAppEditModel) {
		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		this.prepareAffAppForSave(affApp);

		return lastValueFrom(
			this.httpClient
				.post<AffAppDetailsViewModel>(
					`${this.baseUrl}/submit`,
					affApp,
					{ params: httpParams })
				.pipe(
					tap(appDetails => this.computeAffAppProperties(appDetails)),
					catchError(handleHttpError)
				)
		);
	}

	approveAffApp(affAppID: number) {
		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.post<AffAppDetailsViewModel>(
					`${this.baseUrl}/${affAppID}/approve`,
					null,
					{ params: httpParams })
				.pipe(
					tap(appDetails => this.computeAffAppProperties(appDetails)),
					catchError(handleHttpError)
				)
		);
	}

	rejectAffApp(affAppID: number, rejectionNotes: string) {
		const httpParams = new HttpParams().appendAll({
			rejectionNotes: rejectionNotes,
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.post<AffAppDetailsViewModel>(
					`${this.baseUrl}/${affAppID}/reject`,
					null,
					{ params: httpParams })
				.pipe(
					tap(appDetails => this.computeAffAppProperties(appDetails)),
					catchError(handleHttpError)
				)
		);
	}

	uploadAffAppDocument(affAppID: number, documentTypeCode: number, file: File | Blob) {
		const formData = new FormData();
		formData.append('affAppId', affAppID.toString());
		formData.append('affAppDocTypeId', documentTypeCode.toString());
		formData.append('file', file);

		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.post<AffAppDocumentViewModel>(
					`${this.baseUrl}/upload`,
					formData,
					{ params: httpParams })
				.pipe(catchError(handleHttpError))
		);
	}

	private prepareAffAppForSave(affApp: AffAppEditModel) {
		if (affApp.shippingSameAsOrgAddress) {
			affApp.shippingAddress1 = affApp.orgStreet;
			affApp.shippingAddress2 = affApp.orgOffice;
			affApp.shippingCity = affApp.orgCity;
			affApp.shippingStateId = affApp.orgStateId;
			affApp.shippingPostalCode = affApp.orgPostalCode;
			affApp.shippingCountryId = affApp.orgCountryId;
		}

		// zero unset team and race counts
		affApp.programs.forEach(program => {
			if (program.yes === true) {
				program.teamDivisions.forEach(teamDivision => {
					teamDivision.teamCount = teamDivision.teamCount || 0;
					// iterate over all race id keys inside ethnicities and set to 0 if not set
					Object.keys(teamDivision.boyEthnicities).forEach(key => {
						const keyNumber = parseInt(key, 10);
						teamDivision.boyEthnicities[keyNumber].ethnicityCount = teamDivision.boyEthnicities[keyNumber].ethnicityCount || 0;
					});
					Object.keys(teamDivision.girlEthnicities).forEach(key => {
						const keyNumber = parseInt(key, 10);
						teamDivision.girlEthnicities[keyNumber].ethnicityCount = teamDivision.girlEthnicities[keyNumber].ethnicityCount || 0;
					});
				});
			}
		});

		affApp.zipCodes = affApp.zipCodesString?.split(',').map(z => z.trim());
	}

	private computeAffAppProperties(appDetails: AffAppDetailsViewModel) {
		if (!appDetails) return;

		// adjust malformed date strings to utc iso strings
		appDetails.dateFinalSubmission = convertRandomDateTimeToISOString(appDetails.dateFinalSubmission);
		appDetails.dateLastUpdated = convertRandomDateTimeToISOString(appDetails.dateLastUpdated)!;

		// correct formatting on the dates
		appDetails.registrationOpeningDate = this.removeTimeFromDateString(appDetails.registrationOpeningDate);

		// compute program props
		appDetails.programs.forEach(program => {
			// adjust mm/dd/yyyy to yyyy-mm-dd
			program.opensDate = this.removeTimeFromDateString(program.opensDate ? new Date(program.opensDate).toISOString() : null);
			program.startDate = this.removeTimeFromDateString(program.startDate ? new Date(program.startDate).toISOString() : null);

			program.programmingChoicesString = program.programmingChoices?.join(', ');
		});

		// join zipcodes
		appDetails.zipCodesString = appDetails.zipCodes?.join(', ');
	}

	private removeTimeFromDateString(date: string | null) {
		if (!date) {
			return date;
		}
		return date.split('T')?.[0];
	}
}
