@if (thumbnailLoading) {
<div class="d-inline-block placeholder-glow">
	<!-- [ngClass]="bgClass" -->
	<div class="thumbnail placeholder" [class.thumbnail-sm]="size === 'sm'" [class.thumbnail-lg]="size === 'lg'"
		[class.thumbnail-circle]="isCircle"></div>
</div>
} @else if (thumbnailError) {
<!-- [ngClass]="bgClass" -->
<div class="d-inline-block thumbnail" [class.thumbnail-sm]="size === 'sm'" [class.thumbnail-lg]="size === 'lg'"
	[class.thumbnail-circle]="isCircle"><fa-icon class="text-danger" [icon]="errorIcon"></fa-icon></div>
}
@else if (!documentFileID && !thumbnailURL) {
<div class="thumbnail d-inline-flex justify-content-center align-items-center" [ngClass]="bgClass"
	[class.thumbnail-sm]="size === 'sm'" [class.thumbnail-lg]="size === 'lg'" [class.thumbnail-circle]="isCircle">
	@if (emptyChars) {
	<span class="h5 mt-1 mb-0 text-secondary" [class.fs-6]="size === 'sm'" [class.fs-3]="size === 'lg'">{{ emptyChars
		}}</span>
	} @else if (emptyIcon) {
	<fa-icon [icon]="emptyIcon" />
	} @else {
	<span class="h4 mt-1 mb-0 text-secondary">??</span>
	}
</div>
}
@else if (thumbnailURL) {
<img class="thumbnail" [ngClass]="bgClass" [class.thumbnail-sm]="size === 'sm'" [class.thumbnail-lg]="size === 'lg'"
	[class.thumbnail-circle]="isCircle" [src]="thumbnailURL" alt="thumbnail">
}
