<div class="modal-header px-4">
	<h4 class="modal-title">
		{{ title() }}
	</h4>

	<button type="button" class="btn-close" (click)="onCancel()">
		<span class="visually-hidden">&times;</span>
	</button>
</div>

@if (messageHtml()) {
<div class="modal-body px-4 py-3" [innerHTML]="messageHtml()">
</div>
}

<div class="modal-footer bg-white px-4">
	<button type="submit" class="btn" [ngClass]="okButtonClass()" (click)="onConfirm()">
		{{ okTitle() }}
	</button>
</div>
