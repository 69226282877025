import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-vertical-line',
	templateUrl: 'vline.component.html',
	styleUrls: ['vline.component.scss'],
	standalone: true
})
export class VerticalLineComponent {

}
