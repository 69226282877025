<div [class.card]="!cardless" [ngClass]="!cardless ? DocumentStatusCardColors[document.statusId] : null">
	<div [class.card-body]="!cardless">
		<div class="d-flex align-items-center gap-2">
			<app-status-icon [statusType]="StatusTypes.Document" [statusCode]="document.statusId"
				[statusName]="document.statusName" />
			<h6 class="card-title mb-0 flex-grow-1 flex-wrap">
				@switch (titleType) {
				@case ('doc') {
				{{ document.documentName }}
				}
				@case ('entity') {
				{{ document.playerName || document.coachName ||
				document.teamName || document.leagueName }}
				}
				@case ('status') {
				{{ document.statusName }}
				}
				}
				@if (document.required && titleType === 'doc') {
				<app-required-icon class="ms-1" />
				}
				@if (!showInlineStatusNotes && document.statusId === DocumentStatusCodes.Rejected &&
				document.statusNotes) {
				<app-info-icon class="ms-2 text-danger" popoverTemplate="{{ document.statusNotes }}" />
				}
			</h6>
			<app-document-actions class="small" [leagueID]="document.leagueId" [leagueName]="document.leagueName"
				[teamID]="document.teamId" [teamName]="document.teamName" [coachID]="document.coachId"
				[coachName]="document.coachName" [teamPlayerID]="document.playerTeamId"
				[teamPlayerName]="document.playerName" [instructionsFileID]="document.downloadableFileId"
				[instructionsFileName]="document.documentName + ' Instructions.' + document.downloadableFileType"
				[viewFileID]="document.uploadedFileId" [viewSubmissionID]="document.documentSubmissionID"
				[viewFileType]="document.uploadedFileType" [viewFileName]="document.documentName"
				[uploadDocumentInstanceID]="document.documentInstanceId" [uploadDocumentName]="document.documentName"
				[showIntake]="false" [showUpload]="showUpload && document.uploadable" [uploadLabel]="uploadLabel"
				[buttonSizeClass]="buttonSizeClass" (uploaded)="uploaded.emit($event)"
				(approved)="approved.emit($event)" (rejected)="rejected.emit($event)" />
		</div>
		@if (showInlineStatusNotes && document.statusId === DocumentStatusCodes.Rejected && document.statusNotes) {
		<div class="alert alert-danger mt-3 mb-0" role="alert">
			{{ document.statusNotes }}
		</div>
		}
	</div>
</div>