import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faSquareInfo, faArrowUp } from '@fortawesome/pro-regular-svg-icons';

@Component({
	selector: 'app-intake-alert',
	template: `
	@if (!dismissed) {
	<div class="alert alert-light alert-dismissible border-2 fade show" [ngClass]="class()" role="alert">
		<div><fa-icon class="me-1" [icon]="infoIcon" /> Make use of the new <a class="fw-medium" [routerLink]="['/document-intake']" [queryParams]="{ leagueID: leagueID(), leagueName: leagueName(), teamID: teamID() }">Document Intake</a> page to manage documents across all of your teams, players, and coaches.</div>
		<div>Also try the new <span class="badge text-bg-light"><fa-icon [icon]="uploadIcon" /></span> upload button to immediately take a photo, select an image or a file for any team, player, or coach document.</div>
		<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click)="onDismiss()"></button>
	</div>
	}
	`,
	standalone: true,
	imports: [CommonModule, RouterModule, FontAwesomeModule]
})
export class IntakeAlertComponent {
	// inputs
	leagueID = input.required<number>();
	leagueName = input.required<string>();
	teamID = input<number | undefined>();
	class = input<string>('');

	infoIcon = faSquareInfo;
	uploadIcon = faArrowUp;

	dismissed = !!localStorage.getItem('dismissed-intake-alert');

	onDismiss() {
		localStorage.setItem('dismissed-intake-alert', new Date().toISOString());
	}
}
