import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faSpinnerThird, faBaseball } from '@fortawesome/pro-duotone-svg-icons';

@Component({
	selector: 'app-spinner',
	template: '<span class="text-muted me-2" *ngIf="message">{{ message }}</span><fa-icon [ngClass]="class" [icon]="spinnerIcon" [spin]="true"></fa-icon>',
	standalone: true,
	imports: [CommonModule, FontAwesomeModule]
})
export class SpinnerComponent {
	spinnerIcon = faBaseball;

	@Input()
	message?: string;

	@Input()
	class?: string;
}
