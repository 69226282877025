import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { catchError, lastValueFrom, map, Observable } from 'rxjs';

import { AuthService } from './auth.service';
import { GlobalFiltersService } from '@app/root/app/shared/services';

import { UserModel } from '../models/user.models';

import { handleHttpError } from '../../functions';
import { Environment } from '@app/environment';
import { PagedModel } from '@app/root/app/shared';

@Injectable({
	providedIn: 'root',
})
export class UsersService {
	private readonly baseUrl = `${Environment.apiBaseUrl}/users`;

	private httpClient: HttpClient = inject(HttpClient);
	private authService: AuthService = inject(AuthService);
	private globalFiltersService: GlobalFiltersService = inject(GlobalFiltersService);

	getUserDetails(userId: number): Promise<UserModel> {
		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? '',
		});

		return lastValueFrom(
			this.httpClient.get<UserModel>(`${this.baseUrl}/${userId}`, { params: httpParams }).pipe(catchError(handleHttpError))
		);
	}

	getUsers$(
		roleID: number | null = null,
		name: string | null,
		includeInactive: boolean,
		page: number,
		regionID: number | null = null
	): Observable<PagedModel<UserModel>> {
		const httpParams = new HttpParams().appendAll({
			roleid: roleID ?? '',
			name: name ?? '',
			includeInactive: includeInactive,
			regionId: regionID ?? '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? '',
			page: true,
			pageIndex: page,
			pageSize: 50,
		});

		return this.httpClient.get<PagedModel<UserModel>>(`${this.baseUrl}`, { params: httpParams }).pipe(
			map((data) => {
				return {
					...data,
					pageData: data.pageData.map((user) => {
						return {
							...user,
							affiliations: user.affiliations
								? user.affiliations.replace(/(\r\n|\r|\n)/g, ', ')
								: user.affiliations,
						};
					}),
				};
			}),
			catchError(handleHttpError)
		);
	}

	getUsers(
		roleID: number | null,
		name: string | null,
		includeInactive: boolean,
		page: number,
		regionID: number | null = null
	): Promise<PagedModel<UserModel>> {
		return lastValueFrom(this.getUsers$(roleID, name, includeInactive, page, regionID));
	}

	getCurrentUser() {
		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? '',
		});

		return lastValueFrom(
			this.httpClient
				.get<UserModel>(
					`${this.baseUrl}/${this.authService.getUserProfile()?.sub}`,
					{ params: httpParams })
				.pipe(catchError(handleHttpError))
		);
	}

	updateUser(user: UserModel) {
		return lastValueFrom(
			this.httpClient
				.put<UserModel>(
					`${this.baseUrl}/${user.userId}`,
					user)
				.pipe(catchError(handleHttpError))
		);
	}

	saveUser(user: UserModel, userId: number): Promise<UserModel> {
		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? '',
		});

		return lastValueFrom(
			this.httpClient
				.post<UserModel>(`${this.baseUrl}/${userId}`, user, { params: httpParams })
				.pipe(catchError(handleHttpError))
		);
	}

	deleteUser(userId: number, user: UserModel) {
		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? '',
		});

		return lastValueFrom(
			this.httpClient
				.put<UserModel>(`${this.baseUrl}/${userId}/false`, user, { params: httpParams })
				.pipe(catchError(handleHttpError))
		);
	}

	changeUserPassword(userId: number, newPassword: string): Promise<any> {
		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? '',
		});

		const body = new Blob([`"${newPassword}"`], { type: 'application/json' });
		const headers = new HttpHeaders().set('Content-Type', 'application/json');

		return lastValueFrom(
			this.httpClient
				.post<any>(`${this.baseUrl}/${userId}/changepassword`,
					body,
					{
						headers: headers,
						params: httpParams
					})
				.pipe(catchError(handleHttpError))
		);
	}
}
