import {
	Component,
	Input,
	OnInit,
	inject,
	booleanAttribute,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { SpinnerComponent } from '../../components/loaders/spinner/spinner.component';

@Component({
	templateUrl: 'action-confirmation.component.html',
	styleUrl: 'action-confirmation.component.scss',
	standalone: true,
	imports: [CommonModule, FormsModule, SpinnerComponent],
})
export class ActionConfirmationComponent implements OnInit {
	// inputs
	@Input() title: string = '??????';
	@Input() messageHtml?: string;
	@Input({ transform: booleanAttribute }) showPrompt: boolean = false;
	@Input({ transform: booleanAttribute }) promptRequired: boolean = false;
	@Input() promptPlaceholder?: string;

	@Input() actionTitle: string = '?????';
	@Input() actionButtonClass: string = 'btn-danger';
	@Input() actionFunc?: (promptValue?: string) => Promise<boolean>;

	@Input() cancelTitle: string = 'Cancel';
	@Input() cancelButtonClass: string = 'btn-outline-secondary';
	@Input() cancelFunc?: () => Promise<boolean>;

	// internal state
	protected promptValue?: string;
	protected inProgress: boolean = false;

	// output variables
	confirmed: boolean = false;

	// services
	private activeModal = inject(NgbActiveModal);

	ngOnInit() { }

	async onConfirm() {
		if (!this.actionFunc) {
			this.confirmed = true;
			this.activeModal.close();
			return;
		}

		try {
			this.inProgress = true;
			const success = await this.actionFunc(this.promptValue);
			if (success) {
				this.confirmed = true;
				this.activeModal.close();
			} else {
				this.confirmed = false;
			}
		} catch (error: any) {
			this.confirmed = false;
		} finally {
			this.inProgress = false;
		}
	}

	onCancel() {
		if (this.cancelFunc) {
			this.cancelFunc();
		}

		this.activeModal.dismiss();
	}
}
