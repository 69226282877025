import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { catchError, lastValueFrom, map } from 'rxjs';

import { AuthService, GlobalFiltersService } from '@app/shared/services';
import {
	LeagueAppsSiteViewModel,
	LeagueAppsMetricsViewModel,
	LeagueAppProgramTeamViewModel,
	LeagueAppProgramViewModel,
	LeagueAppTeamViewModel,
	LeagueAppsRegistrationViewModel,
	LeagueAppsMappedLeagueViewModel,
	ProgramDetailsViewModel,
} from '@app/shared/models';
import { Environment } from '@app/environment';
import { handleHttpError } from '@app/root/app/functions';



@Injectable({
	providedIn: 'root',
})
export class LeagueAppsService {
	private readonly baseUrl = `${Environment.apiBaseUrl}/v2/leagueapps`;

	private httpClient: HttpClient = inject(HttpClient);
	private authService: AuthService = inject(AuthService);
	private globalFiltersService: GlobalFiltersService = inject(GlobalFiltersService);

	getLeagueAppsConfigsForSiteLeague(
		leagueYearID: number | null,
		leagueID: number | null,
		searchText: string | null,
		notConnectedOnly: boolean | null,
		siteID: number | null
	) {
		return lastValueFrom(
			this.getLeagueAppsConfigsForSiteLeague$(
				leagueYearID,
				leagueID,
				searchText,
				notConnectedOnly,
				siteID
			)
		);
	}

	getLeagueAppsConfigsForSiteLeague$(
		leagueYearID: number | null,
		leagueID: number | null,
		searchText: string | null,
		notConnectedOnly: boolean | null,
		siteID: number | null
	) {
		const httpParams = new HttpParams().appendAll({
			siteId: siteID || '',
			leagueId: leagueID || '',
			search: searchText || '',
			regionId: '',
			yearId: leagueYearID || '',
			notConnectedOnly: notConnectedOnly ? 'true' : ''
		});

		return this.httpClient
			.get<LeagueAppsSiteViewModel[]>(
				`${this.baseUrl}/sites`,
				{ params: httpParams })
			.pipe(catchError(handleHttpError));
	}

	getLeagueAppsMetrics(
		leagueYearID: number | null,
		leagueID: number | null,
		searchText: string | null,
		notConnectedOnly: boolean | null
	) {
		const httpParams = new HttpParams().appendAll({
			leagueId: leagueID || '',
			search: searchText || '',
			regionId: '',
			yearId: leagueYearID || '',
			notConnectedOnly: notConnectedOnly ? 'true' : ''
		});

		return lastValueFrom(
			this.httpClient
				.get<LeagueAppsMetricsViewModel>(
					`${this.baseUrl}/metrics`,
					{ params: httpParams })
				.pipe(catchError(handleHttpError))
		);
	}

	connectLeague(siteID: number, leagueID: number) {
		const httpParams = new HttpParams().appendAll({
			leagueId: leagueID || ''
		});

		return lastValueFrom(
			this.httpClient
				.post<LeagueAppsMappedLeagueViewModel>(
					`${this.baseUrl}/sites/${siteID}/connect`,
					null,
					{ params: httpParams })
				.pipe(catchError(handleHttpError))
		);
	}

	approveConnection(siteID: number, leagueID: number) {
		const httpParams = new HttpParams().appendAll({
			leagueId: leagueID || ''
		});

		return lastValueFrom(
			this.httpClient
				.put<LeagueAppsMappedLeagueViewModel>(
					`${this.baseUrl}/sites/${siteID}/approve`,
					null,
					{ params: httpParams })
				.pipe(catchError(handleHttpError))
		);
	}

	disconnectLeague(siteID: number, leagueID: number) {
		const httpParams = new HttpParams().appendAll({
			leagueId: leagueID || ''
		});

		return lastValueFrom(
			this.httpClient
				.delete<void>(
					`${this.baseUrl}/sites/${siteID}/disconnect`,
					{ params: httpParams })
				.pipe(catchError(handleHttpError))
		);
	}

	getLeagueAppsProgramsTeams(leagueID: number) {
		const httpParams = new HttpParams().appendAll({
			leagueId: leagueID,
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.get<LeagueAppProgramTeamViewModel[]>(
					`${this.baseUrl}/programs-teams`,
					{ params: httpParams })
				.pipe(
					map(programsTeams => {
						const programs: LeagueAppProgramViewModel[] = [];

						programsTeams.forEach(programTeam => {
							let program = programs.find(p => p.programID === programTeam.ProgramID);

							if (!program) {
								const teams = programsTeams.filter(pt => pt.ProgramID === programTeam.ProgramID);

								program = {
									leagueAppsSiteID: programTeam.LeagueAppsSiteID,
									siteName: programTeam.SiteName,
									programID: programTeam.ProgramID,
									programName: programTeam.ProgramName,
									programStatus: programTeam.ProgramStatus,
									programLeagueAppsURL: programTeam.ProgramLeagueAppsURL,
									startDateTime: programTeam.StartDateTime,
									endDateTime: programTeam.EndDateTime,
									description: programTeam.Description,
									programType: programTeam.ProgramType,
									programSport: programTeam.ProgramSport,
									programMode: programTeam.ProgramMode,
									programSeason: programTeam.ProgramSeason,

									playerTotal: teams.reduce((acc, pt) => acc + (pt.PlayerCount || 0), 0),
									coachTotal: teams.reduce((acc, pt) => acc + (pt.CoachCount || 0), 0),

									teams: []
								};
								programs.push(program);
							}

							if (programTeam.TeamID) {
								const team: LeagueAppTeamViewModel = {
									teamID: programTeam.TeamID,
									teamName: programTeam.TeamName,
									teamDivision: programTeam.TeamDivision,
									teamStatus: programTeam.TeamStatus,
									playerCount: programTeam.PlayerCount,
									coachCount: programTeam.CoachCount,
									teamLeagueAppsURL: programTeam.TeamLeagueAppsURL
								};

								program!.teams.push(team);
							}
						});

						return programs;
					}),
					catchError(handleHttpError)
				)
		);
	}

	syncRegistrations(siteID: number, lastUpdated: string | null = null) {
		const httpParams = new HttpParams().appendAll({
			lastUpdated: lastUpdated ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.post<void>(
					`${this.baseUrl}/sync/registrations`,
					[siteID],
					{ params: httpParams })
				.pipe(catchError(handleHttpError))
		);
	}

	syncMembers(siteID: number, lastUpdated: string | null = null) {
		const httpParams = new HttpParams().appendAll({
			lastUpdated: lastUpdated ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.post<void>(
					`${this.baseUrl}/sync/members`,
					[siteID],
					{ params: httpParams })
				.pipe(catchError(handleHttpError))
		);
	}

	syncTeams(siteID: number) {
		const httpParams = new HttpParams().appendAll({
		});

		return lastValueFrom(
			this.httpClient
				.post<void>(
					`${this.baseUrl}/sync/teams`,
					[siteID],
					{ params: httpParams })
				.pipe(catchError(handleHttpError))
		);
	}

	syncPrograms(siteID: number) {
		const httpParams = new HttpParams().appendAll({
		});

		return lastValueFrom(
			this.httpClient
				.post<void>(
					`${this.baseUrl}/sync/programs`,
					[siteID],
					{ params: httpParams })
				.pipe(catchError(handleHttpError))
		);
	}

	getRegistrationDetails(registrationID: number) {
		const httpParams = new HttpParams().appendAll({
		});

		return lastValueFrom(
			this.httpClient
				.get<LeagueAppsRegistrationViewModel>(`${this.baseUrl}/registrations/${registrationID}`, { params: httpParams })
				.pipe(
					// map((response: any) => {
					// 	const mappedResponse: LeagueAppsRegistrationViewModel = {
					// 		registrationId: response.RegistrationId,
					// 		siteId: response.SiteId,
					// 		firstName: response.FirstName,
					// 		lastName: response.LastName,
					// 		registrationStatus: response.RegistrationStatus,
					// 		siteName: response.SiteName,
					// 		lastUpdated: response.LastUpdated,
					// 		created: response.Created,
					// 		userId: response.UserId,
					// 		userType: response.UserType,
					// 		birthDate: response.BirthDate,
					// 		gender: response.Gender,
					// 		zipCode: response.ZipCode,
					// 		groupName: response.GroupName,
					// 		groupId: response.GroupId,
					// 		parentUserId: response.ParentUserId,
					// 		parentFirstName: response.ParentFirstName,
					// 		parentLastName: response.ParentLastName,
					// 		parentEmail: response.ParentEmail,
					// 		parentPhone: response.ParentPhone,
					// 		paymentStatus: response.PaymentStatus,
					// 		invoiceId: response.InvoiceId,
					// 		totalAmountDue: response.TotalAmountDue,
					// 		outstandingBalance: response.OutstandingBalance,
					// 		amountPaid: response.AmountPaid,
					// 		price: response.Price,
					// 		programId: response.ProgramId,
					// 		programType: response.ProgramType,
					// 		programName: response.ProgramName,
					// 		programCode: response.ProgramCode,
					// 		programState: response.ProgramState,
					// 		integrationCode: response.IntegrationCode,
					// 		programStartDate: response.ProgramStartDate,
					// 		sportId: response.SportId,
					// 		sport: response.Sport,
					// 		teamId: response.TeamId,
					// 		team: response.Team,
					// 		waiverAcceptedTimestamp: response.WaiverAcceptedTimestamp,
					// 		role: response.Role,
					// 		id: response.Id,
					// 		userName: response.UserName,
					// 		userProfileId: response.UserProfileId,
					// 		photo: response.Photo,
					// 		phone: response.Phone,
					// 		address1: response.Address1,
					// 		address2: response.Address2,
					// 		city: response.City,
					// 		state: response.State,
					// 		email: response.Email,
					// 		lastPaymentDate: response.LastPaymentDate,
					// 		isStaff: response.IsStaff,
					// 		discountCode: response.DiscountCode,
					// 		paymentPlan: response.PaymentPlan,
					// 		paymentPlanStatus: response.PaymentPlanStatus,
					// 		masterProgramId: response.MasterProgramId,
					// 		masterProgramName: response.MasterProgramName,
					// 		host: response.Host,
					// 		programEndDate: response.ProgramEndDate,
					// 		registrationStartDate: response.RegistrationStartDate,
					// 		registrationEndDate: response.RegistrationEndDate,
					// 		division: response.Division,
					// 		season: response.Season,
					// 		location: response.Location,
					// 		locationId: response.LocationId,
					// 		notes: response.Notes,
					// 		customFormFieldQuestions: response.CustomFormFieldQuestions,

					// 		isCoCaptain: response.IsCoCaptain,
					// 		bats: response.Bats,
					// 		throws: response.Throws,
					// 		position: response.Position,
					// 		grade: response.Grade,
					// 		schoolZipCode: response.SchoolZipCode,
					// 		school: response.School,
					// 		emergencyContactName: response.EmergencyContactName,
					// 		emergencyContactRelationshipToChild: response.EmergencyContactRelationshipToChild,
					// 		emergencyContactHomeAddress: response.EmergencyContactHomeAddress,
					// 		emergencyContactPhoneNumber: response.EmergencyContactPhoneNumber,
					// 		ethnicity: response.Ethnicity,
					// 		highSchoolGraduationYear: response.HighSchoolGraduationYear,
					// 		middleName: response.MiddleName,
					// 		height: response.Height,
					// 		weight: response.Weight,
					// 		detailsPhone: response.DetailsPhone,
					// 		jerseyNumber: response.JerseyNumber,
					// 		gamesPlayed: response.GamesPlayed,
					// 		healthInsuranceCompany: response.HealthInsuranceCompany,
					// 		healthInsurancePolicy: response.HealthInsurancePolicy,
					// 		healthInsuranceSubscriber: response.HealthInsuranceSubscriber
					// 	};
					// 	return mappedResponse;
					// }),
					catchError(handleHttpError)
				)
		);
	}

	getProgramDetails(leagueAppsProgramID: number, teamId: number): Promise<ProgramDetailsViewModel> {
		return lastValueFrom(
			this.httpClient
				.get<ProgramDetailsViewModel>(`${this.baseUrl}/programs/${leagueAppsProgramID}/teams/${teamId}`)
				.pipe(
					catchError(handleHttpError)
				)
		);
	}
}
