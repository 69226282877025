<div class="placeholder-glow" [ngClass]="class">
	<table class="table mb-0" [class.table-sm]="isSmall">
		<tbody>
			<tr *ngFor="let row of [].constructor(rowsCount)">
				<td *ngFor="let column of [].constructor(columnsCount)">
					<div class="d-block placeholder">&nbsp;</div>
				</td>
			</tr>
		</tbody>
	</table>
</div>
