import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faHyphen, faEllipsis, faCheck, faXmark, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import {
	AffiliationApplicationStatusCodes,
	TournamentApplicationStatusCodes,
	DocumentStatusCodes,

	AffiliationApplicationStatusIconColors,
	LeagueAgreementStatusIconColors,
	TournamentApplicationStatusIconColors,
	DocumentStatusIconColors
} from '../../../constants';

export enum StatusTypes {
	AffiliationApp,
	LeagueAgreement,
	TournamentApp,
	Document,
}

@Component({
	selector: 'app-status-icon',
	template: `
	<div class="d-inline-block text-nowrap">
	<span class="status" [class.status-sm]="size === 'sm'" [ngClass]="statusColorMapping[statusCode]" container="body" ngbTooltip="{{ statusName }}">
		<fa-icon [icon]="statusIconMapping[statusType][statusCode]" />
	</span>
	@if (showStatusName && statusName) { <span class="ms-2">{{ statusName }}</span> }
	</div>
	`,
	styles: [`
	.status {
		border: 1px solid;
		border-bottom-left-radius: 10px;
		border-top-right-radius: 10px;
		padding: 7px 10px;
		&.status-sm {
			padding: 2px 7px;
		}

		::ng-deep fa-icon {
			svg {
				min-width: 13px;
			}
		}
	}
	`],
	standalone: true,
	imports: [CommonModule, FontAwesomeModule, NgbTooltipModule]
})
export class StatusIconComponent implements OnChanges {
	@Input({ required: true }) statusType!: StatusTypes;
	@Input({ required: true }) statusCode!: number;
	@Input() statusName?: string;
	@Input() showStatusName: boolean = false;
	@Input() size: 'sm' | 'lg' | null = null;
	@Input() class?: string;

	notStartedIcon = faHyphen;
	inProgressIcon = faEllipsis;
	approvedIcon = faCheck;
	rejectedIcon = faXmark;

	statusIconMapping: { [key in StatusTypes]: { [key: number]: IconDefinition } } = {
		[StatusTypes.AffiliationApp]: {
			[AffiliationApplicationStatusCodes.Unknown]: this.notStartedIcon,
			[AffiliationApplicationStatusCodes.NotStarted]: this.notStartedIcon,
			[AffiliationApplicationStatusCodes.InProgress]: this.inProgressIcon,
			[AffiliationApplicationStatusCodes.Rejected]: this.rejectedIcon,
			[AffiliationApplicationStatusCodes.Approved]: this.approvedIcon
		},
		[StatusTypes.LeagueAgreement]: {
			[0]: this.inProgressIcon,
			[1]: this.approvedIcon
		},
		[StatusTypes.TournamentApp]: {
			[TournamentApplicationStatusCodes.Unknown]: this.notStartedIcon,
			[TournamentApplicationStatusCodes.Draft]: this.notStartedIcon,
			[TournamentApplicationStatusCodes.InProgress]: this.inProgressIcon,
			[TournamentApplicationStatusCodes.Rejected]: this.rejectedIcon,
			[TournamentApplicationStatusCodes.Approved]: this.approvedIcon
		},
		[StatusTypes.Document]: {
			[DocumentStatusCodes.NotStarted]: this.notStartedIcon,
			[DocumentStatusCodes.InProgress]: this.inProgressIcon,
			[DocumentStatusCodes.Rejected]: this.rejectedIcon,
			[DocumentStatusCodes.Approved]: this.approvedIcon
		}
	};

	statusColorMapping!: { [key: number]: string };

	ngOnChanges() {
		switch (this.statusType) {
			case StatusTypes.AffiliationApp:
				this.statusColorMapping = AffiliationApplicationStatusIconColors;
				break;
			case StatusTypes.LeagueAgreement:
				this.statusColorMapping = LeagueAgreementStatusIconColors;
				break;
			case StatusTypes.TournamentApp:
				this.statusColorMapping = TournamentApplicationStatusIconColors;
				break;
			case StatusTypes.Document:
				this.statusColorMapping = DocumentStatusIconColors;
				break;
			default:
				this.statusColorMapping = {};
		}
	}
}
