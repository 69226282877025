<div class="row mb-3 d-none d-sm-flex">
	<div class="col">
		<h1>
			Leagues
			@if (leaguesTotal !== null) { <span class="text-secondary">({{ leaguesTotal | number }})</span> }
		</h1>
	</div>
	@if (authService.hasPermission(AppPermissionCodes.League_Create)) {
	<div class="col-auto">
		<button class="btn btn-lg btn-primary" [routerLink]="['/leagues/new']">
			<fa-icon class="me-2" [icon]="addIcon" /> Add League
		</button>
	</div>
	}
</div>

<!-- league filters -->
@if (authService.hasPermission(AppPermissionCodes.Leagues_Dashboard_Filter)) {
<div class="row gx-3 gy-2 mb-4">
	<div class="col-24 col-sm-auto">
		<select id="categorySelect" name="categorySelect" class="form-select"
			[class.fw-bold]="filters.categoryID != LeagueFilterCategoryCodes.All" aria-label="league filter categories"
			[(ngModel)]="filters.categoryID" (change)="onLoadLeagues(true)" [disabled]="leaguesLoading">
			@for (category of (filterCategoriesLookup$ | async); track category.id) {
			<option [ngValue]="category.id">{{ category.name }}</option>
			}
		</select>
	</div>
	<div class="col-24 col-sm-6">
		<input class="form-control form-control-search" type="search" autocomplete="noautocomplete"
			placeholder="Search leagues..." aria-label="text search filter" [ngModel]="filters.q"
			(ngModelChange)="searchChange.next($event)">
		<!-- [readOnly]="leaguesLoading" -->
	</div>
	<div class="col-11 col-sm-auto">
		<select id="regionSelect" name="regionSelect" class="form-select" [class.fw-bold]="filters.regionID"
			aria-label="region filter" [(ngModel)]="filters.regionID" (change)="onLoadLeagues(true)"
			[disabled]="leaguesLoading">
			<option [ngValue]="null" selected>All Regions</option>
			@for (region of (regionsLookup$ | async); track region.id) {
			<option [ngValue]="region.id">{{ region.name }}</option>
			}
		</select>
	</div>
	<div class="col-10 col-sm-auto">
		<select id="zoneSelect" name="zoneSelect" class="form-select" [class.fw-bold]="filters.zoneID"
			aria-label="zone filter" [(ngModel)]="filters.zoneID" (change)="onLoadLeagues(true)"
			[disabled]="leaguesLoading">
			<option [ngValue]="null" selected>All Zones</option>
			@for (zone of (zonesLookup$ | async); track zone.id) {
			<option [ngValue]="zone.id">{{ zone.name }}</option>
			}
		</select>
	</div>
</div>
}

<!-- league listing -->
<div class="row mb-5">
	<div class="col">
		<!-- DESKTOP VIEW -->
		<div class="card d-none d-xl-flex" [class.border-0]="leagues.length">
			<div class="card-body" [class.p-0]="leagues.length || (leaguesLoading && leaguesCurrentPage === 1)">
				@if (leaguesLoading && leaguesCurrentPage === 1) {
				<app-table-placeholder [columnsCount]="4" [rowsCount]="4" />
				} @else if (leaguesError) {
				<app-error message="Error loading leagues" (retry)="onLoadLeagues(true)" />
				} @else if (!leagues.length) {
				<app-empty message="no leagues for league year {{ globalFiltersService.getLeagueYearName() }}" />
				} @else {
				<div infiniteScroll (scrolled)="onLoadLeagues(false)">
					<div class="table-responsive">
						<table class="table table-hover table-bordered align-middle mb-0">
							<thead class="table-secondary">
								<tr>
									<th scope="col">League</th>
									<th class="text-center" scope="col">Affiliation</th>
									<th scope="col">Applications & Agreements</th>
									<th class="text-center" scope="col">Documents</th>
								</tr>
							</thead>
							<tbody>
								@for (league of leagues; track league.leagueId; let isLast = $last, isOdd = $odd) {
								<tr class="stretchable-container table-row-clickable">
									<td>
										<div class="row g-2 align-items-center">
											<div class="col-auto">
												@if (league.thumbnailURL) {
												@defer (on viewport) {
												<app-document-thumbnail [thumbnailURL]="league.thumbnailURL"
													[isCircle]="false" />
												} @placeholder {
												<app-spinner />
												}
												}
											</div>
											<div class="col">
												<h5 class="hstack gap-2">
													<a class="text-reset text-decoration-none stretched-link"
														[routerLink]="['/leagues/', league.leagueId]">{{
														league.leagueName
														}}</a>
													@if (league.guestAccess) { <span class="badge text-bg-light"
														ngbTooltip="Guest access"><fa-icon class="me-1"
															[icon]="guestUserIcon" /> Guest</span> }
												</h5>
												<div class="d-flex align-items-center gap-2">
													<app-teams-badge [teamCount]="league.tournamentTeamCount"
														[hasWarning]="league.hasTeamWarning" />
													<app-players-badge [playerCount]="league.playerCount"
														[teamCount]="league.tournamentTeamCount"
														[hasWarning]="league.hasPlayerWarning"
														[hasSuccess]="league.hasPlayerSuccess" />
													<app-coaches-badge [coachCount]="league.coachCount"
														[teamCount]="league.tournamentTeamCount"
														[hasWarning]="league.hasCoachWarning" />
													<app-location-badge [countryCode]="league.countryCode"
														[zoneName]="league.zoneName" />
												</div>
											</div>
										</div>
									</td>
									<td class="text-center">
										<app-years-badge [leagueYears]="league.leagueYears" />
									</td>
									<td>
										<div>
											<app-status [affAppStatusCode]="league.affiliationApplicationStatusId"
												[affAppStatusName]="league.affiliationApplicationStatusName"
												[leagueAgreementSubmitted]="league.leagueAgreementSubmitted"
												[tournamentAppStatusCode]="league.tournamentApplicationStatusId"
												[tournamentAppStatusName]="league.tournamentApplicationStatusName" />
										</div>
									</td>
									<td class="text-center">
										<app-documents-badge [inProgressCount]="league.inProgressCount"
											[approvedCount]="league.approvedCount" [totalCount]="league.totalCount"
											[hasWarning]="league.hasDocumentWarning"
											[hasSuccess]="league.hasDocumentSuccess" />
									</td>
								</tr>
								}
							</tbody>
							@if (leaguesLoading && leaguesCurrentPage > 1) {
							<tfoot>
								<tr>
									<td colspan="4">
										<app-table-placeholder [columnsCount]="4"
											[rowsCount]="4"></app-table-placeholder>
									</td>
								</tr>
							</tfoot>
							}
						</table>
					</div>
				</div>
				}
			</div>
		</div>

		<!-- MOBILE VIEW -->
		<div infiniteScroll (scrolled)="onLoadLeagues(false)">
			<div class="row g-3 d-flex d-xl-none">
				@if (leaguesLoading && leaguesCurrentPage === 1) {
				<div class="col-24 col-sm-12"><app-card-placeholder [hasTitle]="true" [listItemCount]="3"
						[bodyRowItemCounts]="[[4]]" [twoLineItems]="false" /></div>
				<div class="col-24 col-sm-12"><app-card-placeholder [hasTitle]="true" [listItemCount]="3"
						[bodyRowItemCounts]="[[4]]" [twoLineItems]="false" /></div>
				@if (authService.hasPermission(AppPermissionCodes.League_Update)) {
				<div class="col-24 col-sm-12"><app-card-placeholder [hasTitle]="true" [listItemCount]="3"
						[bodyRowItemCounts]="[[4]]" [twoLineItems]="false" /></div>
				<div class="col-24 col-sm-12"><app-card-placeholder [hasTitle]="true" [listItemCount]="3"
						[bodyRowItemCounts]="[[4]]" [twoLineItems]="false" /></div>
				<div class="col-24 col-sm-12"><app-card-placeholder [hasTitle]="true" [listItemCount]="3"
						[bodyRowItemCounts]="[[4]]" [twoLineItems]="false" /></div>
				}
				} @else if (leaguesError) {
				<div class="col-24"><app-error message="Error loading leagues" (retry)="onLoadLeagues(true)" /></div>
				} @else if (!leagues.length) {
				<div class="col-24"><app-empty
						message="No leagues for league year {{ globalFiltersService.getLeagueYearName() }}" /></div>
				} @else {
				@for (league of leagues; track league.leagueId; let isLast = $last, isOdd = $odd) {
				<div class="col-24 col-sm-12">
					<div class="card border-2">
						<div class="card-body">
							<div class="card-title c-pointer mb-3" [routerLink]="['/leagues/', league.leagueId]">
								<div class="d-flex justify-content-between">
									<h3 class="mb-0">
										@if (league.thumbnailURL) {
										<app-document-thumbnail class="me-1" [thumbnailURL]="league.thumbnailURL"
											[isCircle]="false" size="sm" />
										}
										{{ league.leagueName }}
									</h3>
									<app-details-icon ngbTooltip="View League Details" />
								</div>
								<div class="hstack gap-2 flex-wrap mt-2">
									@if (league.guestAccess) { <span class="badge text-bg-light"
										ngbTooltip="Guest access"><fa-icon [icon]="guestUserIcon" /></span> }
									<app-location-badge [countryCode]="league.countryCode"
										[zoneName]="league.zoneName" />
									<app-years-badge [leagueYears]="league.leagueYears" />
								</div>
							</div>

							<hr class="mt-1 mb-3" />

							<div class="row mb-1">
								<div class="col-3">
									<app-status-icon [statusType]="StatusTypes.AffiliationApp"
										[statusCode]="league.affiliationApplicationStatusId"
										[statusName]="league.affiliationApplicationStatusName" size="sm" />
								</div>
								<div class="col">
									<span>Affiliation Application</span>
								</div>
							</div>
							<div class="row mb-1">
								<div class="col-3">
									<app-status-icon [statusType]="StatusTypes.LeagueAgreement"
										[statusCode]="league.leagueAgreementSubmitted ? LeagueAgreementStatusCodes.Submitted : LeagueAgreementStatusCodes.NotSubmitted"
										[statusName]="league.leagueAgreementSubmitted ? 'Submitted' : 'Not Submitted'"
										size="sm" />
								</div>
								<div class="col">
									<span>League Agreement</span>
								</div>
							</div>
							<div class="row">
								<div class="col-3">
									<app-status-icon [statusType]="StatusTypes.TournamentApp"
										[statusCode]="league.tournamentApplicationStatusId"
										[statusName]="league.tournamentApplicationStatusName" size="sm" />
								</div>
								<div class="col">
									<span>Tournament Application</span>
								</div>
							</div>

							<hr class="mt-3 mb-1" />

							<div class="hstack gap-2 flex-wrap mt-3">
								<app-teams-badge [teamCount]="league.tournamentTeamCount"
									[hasWarning]="league.hasTeamWarning" [showLabel]="true" />
								<app-players-badge [playerCount]="league.playerCount"
									[teamCount]="league.tournamentTeamCount" [hasWarning]="league.hasPlayerWarning"
									[hasSuccess]="league.hasPlayerSuccess" [showLabel]="true" />
								<app-coaches-badge [coachCount]="league.coachCount"
									[teamCount]="league.tournamentTeamCount" [hasWarning]="league.hasCoachWarning"
									[showLabel]="true" />
								<app-documents-badge [inProgressCount]="league.inProgressCount"
									[approvedCount]="league.approvedCount" [totalCount]="league.totalCount"
									[hasWarning]="league.hasDocumentWarning" [hasSuccess]="league.hasDocumentSuccess"
									[showLabel]="true" />
							</div>
						</div>
					</div>
				</div>
				}
				<!-- paging loader -->
				@if (leaguesLoading && leaguesCurrentPage > 1) {
				<div class="col-24 col-sm-12"><app-card-placeholder [hasTitle]="true" [listItemCount]="3"
						[bodyRowItemCounts]="[[4]]" [twoLineItems]="false" /></div>
				<div class="col-24 col-sm-12"><app-card-placeholder [hasTitle]="true" [listItemCount]="3"
						[bodyRowItemCounts]="[[4]]" [twoLineItems]="false" /></div>
				}
				}
			</div>
		</div>
	</div>
</div>
