import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';

import { Environment } from '@app/environment';

@Injectable({
	providedIn: 'root',
})
export class AppInsightsService {
	private appInsights?: ApplicationInsights;
	private router: Router = inject(Router);

	constructor() {
		if (!Environment.AppInsigtsInstrumentationKey) {
			console.log('Skipping AppInsights initialization.');
			return;
		}

		const angularPlugin = new AngularPlugin();
		// const clickPluginInstance = new ClickAnalyticsPlugin();
		// const clickPluginConfig = {
		// 	autoCapture: true
		// };
		this.appInsights = new ApplicationInsights({
			config: {
				instrumentationKey: Environment.AppInsigtsInstrumentationKey,
				extensions: [angularPlugin],
				// extensions: [angularPlugin, clickPluginInstance],
				extensionConfig: {
					[angularPlugin.identifier]: { router: this.router },
					// [clickPluginInstance.identifier]: clickPluginConfig
				},
				enableAutoRouteTracking: true,
			}
		});
		this.appInsights.loadAppInsights();
	}

	logPageView(name?: string, uri?: string) {
		this.appInsights?.trackPageView({ name, uri });
	}

	logEvent(name: string, properties?: { [key: string]: any }) {
		this.appInsights?.trackEvent({ name }, properties);
	}

	logException(exception: Error, severityLevel?: number) {
		this.appInsights?.trackException({ exception, severityLevel });
	}

	logTrace(message: string, properties?: { [key: string]: any }) {
		this.appInsights?.trackTrace({ message }, properties);
	}

	logMetric(name: string, average: number, properties?: { [key: string]: any }) {
		this.appInsights?.trackMetric({ name, average }, properties);
	}

	setAuthenticatedUserContext(accountId: string | null | undefined) {
		if (accountId) {
			this.appInsights?.setAuthenticatedUserContext(accountId, undefined, true);
		} else {
			this.clearAuthenticatedUserContext();
		}
	}

	clearAuthenticatedUserContext() {
		this.appInsights?.flush();
		this.appInsights?.clearAuthenticatedUserContext();
	}
}
