import { CommonModule } from '@angular/common';
import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { Subject, Subscription, debounceTime, distinctUntilChanged, switchMap, catchError, of } from 'rxjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
	faMagnifyingGlass, faSort, faPlus,
	faThumbTack, faMessageDots, faTriangle as faTriangleRegular, faEllipsis, faMessagePlus, faPenToSquare
} from '@fortawesome/pro-regular-svg-icons';
import { faTriangle as faTriangleSolid, faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { MomentModule } from 'ngx-moment';
import { ToastrService } from 'ngx-toastr';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { ConfirmationService } from '../../../services/confirmation.service';
import { GlobalFiltersService } from '../../../services/global-filters.service';
import { ForumService } from '../../../services/forum.service';

import { ActionConfirmationComponent } from '../../../components/action-confirmation/action-confirmation.component';
import { SpinnerComponent } from '../../../components/loaders/spinner/spinner.component';
import { CardPlaceholderComponent } from '../../../components/loaders/card-placeholder/card-placeholder.component';
import { ErrorComponent } from '../../../components/error/error.component';
import { EmptyComponent } from '../../../components/empty/empty.component';
import { FieldSeparatorComponent } from '../../field-separator/field-separator.component';
import { VerticalLineComponent } from '../../../components/vline/vline.component';
import { CancelIconComponent } from '../../../components/action-icons/cancel-icon.component';
import { ForumCommentsComponent } from './forum-comments/forum-comments.component';
import { ShortTimeAgoPipe } from '../../../pipes/short-time-ago.pipe';
import { PagedModel } from '../../../models/paged.model';
import { ForumPostViewModel, ForumCommentViewModel, ForumCommentEditModel } from '../../../models/forum.models';

import { ForumPostSortCodes } from '../../../../constants';

@Component({
	selector: 'app-forum',
	templateUrl: './forum.component.html',
	styleUrl: './forum.component.scss',
	standalone: true,
	imports: [
		CommonModule, RouterModule, FormsModule,
		FontAwesomeModule, MomentModule, NgbTooltipModule, NgbDropdownModule,
		ActionConfirmationComponent, SpinnerComponent, CardPlaceholderComponent, ErrorComponent, EmptyComponent, FieldSeparatorComponent, VerticalLineComponent, CancelIconComponent, ForumCommentsComponent,
		ShortTimeAgoPipe
	]
})
export class ForumComponent implements OnInit, OnDestroy {
	// internal state
	postSearchActive = false;
	postSearchChange = new Subject<string>();
	postSearchSubscription: Subscription | null = null;
	postSearchText: string | null = null;
	postSortCode: string = ForumPostSortCodes.Newest;

	posts: ForumPostViewModel[] | null = null;
	postsLoading = false;
	postsError = false;
	postsCurrentPage = 1;

	commentSaving = false;

	// services
	private toastrService: ToastrService = inject(ToastrService);
	confirmationService: ConfirmationService = inject(ConfirmationService);
	globalFiltersService: GlobalFiltersService = inject(GlobalFiltersService);
	private forumService: ForumService = inject(ForumService);

	// icons
	searchIcon = faMagnifyingGlass;
	sortIcon = faSort;
	dropdownIcon = faCaretDown;
	newPostIcon = faPlus;

	pinnedIcon = faThumbTack;
	commentIcon = faMessageDots;
	upvoteIcon = faTriangleRegular;
	upvotedIcon = faTriangleSolid;
	actionsIcon = faEllipsis;
	lastEditedIcon = faPenToSquare;
	lastCommentIcon = faMessagePlus;

	// constants
	ForumPostSortCodes = ForumPostSortCodes;

	ngOnInit() {
		this.connectPostSearch();
		this.onLoadPosts(true);
	}

	ngOnDestroy() {
		this.postSearchSubscription?.unsubscribe();
	}

	connectPostSearch() {
		this.postSearchSubscription = this.postSearchChange
			.pipe(
				// 500ms input delay
				debounceTime(500),
				// ignore if query hasn't changed
				distinctUntilChanged(),
				// switch to new search observable (and cancel previous)
				switchMap(query => {
					// save search query
					this.postSearchText = query;

					// reset results and page
					this.resetPostResults();

					// show loading indicator
					this.postsLoading = true;
					this.postsError = false;

					return this.forumService.getPosts$(this.postSearchText, this.postSortCode, 1)
						.pipe(
							// handle fetch errors
							catchError(error => {
								console.error('error searching posts', error);
								this.postsError = true;
								this.postsLoading = false;
								return of(null);
							}));
				})
			)
			.subscribe(response => {
				this.processPostsPageData(response);
				this.postsLoading = false;
			});
	}

	private resetPostResults() {
		this.posts = null;
		this.postsCurrentPage = 1;
	}

	private processPostsPageData(pageResult: PagedModel<ForumPostViewModel> | null) {
		if (!pageResult) {
			return;
		}

		if (!this.posts) {
			this.posts = [];
		}

		this.posts!.push(...pageResult.pageData.filter(p => this.posts!.findIndex(pp => pp.postID === p.postID) === -1));

		if (pageResult.pageData.length > 0) {
			this.postsCurrentPage++;
		}
	}

	async onLoadPosts(reset: boolean) {
		if (reset) {
			this.resetPostResults();
		}

		try {
			this.postsLoading = true;
			this.postsError = false;

			const response = await this.forumService.getPosts(
				this.postSearchText,
				this.postSortCode,
				this.postsCurrentPage);

			this.processPostsPageData(response);
		} catch (error) {
			this.postsError = true;
		} finally {
			this.postsLoading = false;
		}
	}

	onTogglePostSearch() {
		this.postSearchActive = !this.postSearchActive;

		if (!this.postSearchActive && this.postSearchText) {
			this.postSearchText = null;
			this.onLoadPosts(true);
		}
	}

	onPostSortChange(sortCode: string) {
		if (this.postSortCode === sortCode) {
			return;
		}

		this.postSortCode = sortCode;
		this.onLoadPosts(true);
	}

	async onDeletePost(post: ForumPostViewModel) {
		this.confirmationService.confirm(
			'Delete Post',
			`Are you sure you want to delete the <strong>${post.title}</strong> post?`,
			'Delete',
			'btn-danger',
			async () => {
				try {
					await this.forumService.deletePost(post.postID);

					const postIndex = this.posts!.findIndex(x => x.postID === post.postID);
					if (postIndex >= 0) {
						this.posts!.splice(postIndex, 1);
					}

					this.toastrService.success(`Post has been deleted.`);

					return true;
				} catch (error) {
					this.toastrService.error(`Failed to delete the post. Please try again.`)
					return false;
				}
			});
	};

	async onReportPost(post: ForumPostViewModel) {
		this.confirmationService.confirm(
			'Report Post',
			`Are you sure you want to report the <strong>${post.title}</strong> post to MLB/RBI for review?<br/><br/>Reported posts are hidden while they're being reviewed.`,
			'Report',
			'btn-warning',
			async () => {
				try {
					await this.forumService.reportPost(post.postID);

					const postIndex = this.posts!.findIndex(x => x.postID === post.postID);
					if (postIndex >= 0) {
						this.posts!.splice(postIndex, 1);
					}

					this.toastrService.success(`Post reported to MLB/RBI. Post will be hidden while it's being reviewed.`);

					return true;
				} catch (error) {
					this.toastrService.error(`Failed to report the post. Please try again.`)
					return false;
				}
			});
	}

	async onTogglePostLiked(post: ForumPostViewModel) {
		try {
			post.togglingLike = true;

			post = post.isLikedByMe
				? await this.forumService.unlikePost(post.postID)
				: await this.forumService.likePost(post.postID);
			post.togglingLike = false;

			const postIndex = this.posts!.findIndex(x => x.postID === post.postID);
			if (postIndex >= 0) {
				this.posts![postIndex] = post;
			}
		} catch (error) {
			this.toastrService.error(`Failed to ${post.isLikedByMe ? 'unlike' : 'like'} the post. Please try again.`)
		} finally {
			post.togglingLike = false;
		}
	}

	onCommentsChange(post: ForumPostViewModel, comments: ForumCommentViewModel[]) {
		post.commentCount = comments?.length || 0;
	}
}
