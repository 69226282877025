import { CommonModule } from '@angular/common';
import { Component, Input, booleanAttribute, numberAttribute } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { TeamIconComponent } from '../entity-icons/team-icon.component';

@Component({
	selector: 'app-teams-badge',
	template: `
	<span class="badge" [ngClass]="[ hasWarning ? 'text-warning-emphasis bg-warning-subtle' : 'text-success-emphasis bg-success-subtle', badgeClass ]" ngbTooltip="{{ tooltip }}"><app-team-icon class="me-1" /> {{ teamCount | number }} @if (showLabel) { Team{{ teamCount === 1 ? '' : 's' }} }</span>
	`,
	standalone: true,
	imports: [CommonModule, FontAwesomeModule, NgbTooltipModule, TeamIconComponent]
})
export class TeamsBadgeComponent {
	@Input({ required: true, transform: numberAttribute }) teamCount!: number;
	@Input({ transform: booleanAttribute }) hasWarning: boolean = false;
	@Input({ transform: booleanAttribute }) showLabel: boolean = false;
	@Input() tooltip: string = 'Number of teams';
	@Input() badgeClass: string = '';
}
