<div class="modal-header px-4">
	<h4 class="modal-title">
		{{ title }}
	</h4>

	<button type="button" class="btn-close" (click)="onCancel()">
		<span class="visually-hidden">&times;</span>
	</button>
</div>

@if (messageHtml) {
<div class="modal-body px-4 py-3">
	<div [innerHTML]="messageHtml"></div>
	@if (showPrompt) {
	<div>
		<input id="confirmationPromptInput" type="text" class="form-control mt-3" [placeholder]="promptPlaceholder"
			[(ngModel)]="promptValue" />
	</div>
	}
</div>
}

<div class="modal-footer bg-white px-4">
	<button type="button" class="btn" [ngClass]="cancelButtonClass" (click)="onCancel()" [disabled]="inProgress"
		ngbAutofocus>
		{{ cancelTitle }}
	</button>

	<button type="submit" class="btn" [ngClass]="actionButtonClass" (click)="onConfirm()"
		[disabled]="inProgress || (promptRequired && !promptValue)">
		{{ actionTitle }} @if(inProgress) { <app-spinner class="ms-1" /> }
	</button>
</div>
