import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'app-empty',
	templateUrl: 'empty.component.html',
	styleUrl: 'empty.component.scss',
	standalone: true,
	imports: [CommonModule]
})
export class EmptyComponent {
	// inputs
	message = input<string>('no data');
	class = input<string>('');
}
