export const Environment = {
	production: false,

	authBaseUrl: 'https://authdev.mlbrpm.com/oauth2',
	apiBaseUrl: 'https://apidev.mlbrpm.com/api',

	RECAPTCHA_V3_SITE_KEY: '6LdIoc0pAAAAAKxQAW9Jr7hjbN3Ihe-Ngat-ldtY',

	AppInsigtsInstrumentationKey: 'd0190742-be3d-4aa3-a843-1c0ef3c44ddf'
};
