import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { ReplaySubject, tap, map, catchError, throwError, lastValueFrom } from 'rxjs';

import { AuthService } from './auth.service';
import { GlobalFiltersService } from './global-filters.service';

import { PagedModel } from '../models/paged.model';
import { ApprovalViewModel } from '../models/approvals.models';
import { EntityDocumentViewModel } from '../models/document.models';

import { handleHttpError, removeEmptyParams } from '../../functions';
import { DocumentSubjectCodes } from '../../constants';
import { Environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class ApprovalsService {
	private readonly baseUrl = `${Environment.apiBaseUrl}/approvals`;

	private httpClient: HttpClient = inject(HttpClient);
	private globalFiltersService: GlobalFiltersService = inject(GlobalFiltersService);

	private pageSize = 30;

	getApprovals(
		leagueID: number | null,
		teamID: number | null,
		documentStatusID: number | null,
		documentSubjectID: number | null,
		pageNumber: number
	) {
		const httpParams = new HttpParams().appendAll({
			searchText: '',
			leagueId: leagueID?.toString() ?? '',
			teamId: teamID?.toString() ?? '',
			statusId: documentStatusID?.toString() ?? '',
			subjectId: documentSubjectID?.toString() ?? '',
			documentId: '',
			includeDeleted: 'false',
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? '',
			page: true,
			pageSize: this.pageSize,
			pageIndex: pageNumber
		});

		return lastValueFrom(
			this.httpClient
				.get<PagedModel<ApprovalViewModel>>(
					`${this.baseUrl}/documents`,
					{ params: httpParams })
				.pipe(
					map(pagedApprovals => {
						const pagedApprovalDocs: PagedModel<EntityDocumentViewModel> = {
							pageData: [],
							totalRecords: pagedApprovals?.totalRecords || 0,
							pageSize: this.pageSize,
							pageIndex: pageNumber
						};

						pagedApprovals.pageData.forEach(approval => {
							pagedApprovalDocs.pageData.push(this.mapApprovalToEntityDocumentViewMode(approval));
						});

						return pagedApprovalDocs;
					}),
					catchError(handleHttpError)
				)
		);
	}

	private mapApprovalToEntityDocumentViewMode(approval: ApprovalViewModel): EntityDocumentViewModel {
		return {
			regionId: 0,
			leagueYearId: approval.year,
			leagueId: approval.leagueId,
			leagueName: approval.organizationLeagueName,
			teamId: approval.teamId,
			teamName: approval.teamName,
			playerTeamId: approval.documentSubjectId === DocumentSubjectCodes.PlayerDocument ? approval.playerCoachId : null,
			playerName: approval.documentSubjectId === DocumentSubjectCodes.PlayerDocument ? approval.playerCoachName : null,
			coachId: approval.documentSubjectId === DocumentSubjectCodes.CoachDocument ? approval.playerCoachId : null,
			coachName: approval.documentSubjectId === DocumentSubjectCodes.CoachDocument ? approval.playerCoachName : null,
			documentSubjectId: approval.documentSubjectId,
			documentSubjectName: approval.documentSubjectName,
			documentId: approval.documentId,
			documentName: approval.documentName,
			documentInstanceId: approval.documentInstanceId,
			downloadable: approval.downloadable,
			downloadableFileId: approval.downloadableFileId,
			downloadableFileType: approval.downloadableFileType,
			documentSubmissionID: approval.submissionId,
			uploadable: true,
			uploadedFileId: approval.uploadedFileId,
			uploadedFileType: approval.uploadedFileType,
			uploaderName: approval.submittedByEmployeeName,
			uploadedDateTime: approval.dateSubmitted,
			required: approval.required,
			statusId: approval.statusId,
			statusName: approval.statusName,
			statusNotes: approval.statusNotes,
			version: approval.version,
			isCurrentVersion: true,
			olderVersions: null
		} as EntityDocumentViewModel;
	}

	approveDocument(submissionID: number) {
		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.post<ApprovalViewModel>(
					`${this.baseUrl}/documents/${submissionID}/approve`,
					null,
					{ params: httpParams })
				.pipe(
					map(approval => this.mapApprovalToEntityDocumentViewMode(approval)),
					catchError(handleHttpError)
				)
		);
	}

	rejectDocument(submissionID: number, reason: string) {
		const httpParams = new HttpParams().appendAll({
			rejectionNotes: reason,
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.post<ApprovalViewModel>(
					`${this.baseUrl}/documents/${submissionID}/reject`,
					null,
					{ params: httpParams })
				.pipe(
					map(approval => this.mapApprovalToEntityDocumentViewMode(approval)),
					catchError(handleHttpError)
				)
		);
	}
}
