<div class="row" [class.field-required]="isRequired">
	<label class="col-24 col-md-{{ labelValueCols.label }} col-form-label" for="{{ fieldID }}" [innerHTML]="label"></label>
	<div class="col-24 col-md-{{ labelValueCols.value }}">
		@if (isUrl && value) {
			<!-- class="form-control-plaintext" -->
			<a id="{{ fieldID }}" [ngClass]="{ 'fw-medium': value, 'fst-italic fw-normal text-secondary': !value }"
			   [href]="cleanUrl" target="_blank">
				<span [innerHTML]="value"></span>
				<app-browse-icon class="ms-2" />
			</a>
		} @else {
			<div id="{{ fieldID }}" class="form-control-plaintext text-preserve-whitespace"
				 [ngClass]="{ 'fw-medium': value, 'fst-italic fw-normal text-secondary': !value }"
				 [innerHTML]="value || 'empty'">
			</div>
		}
	</div>
</div>
