import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { ReplaySubject, tap, map, catchError, throwError, lastValueFrom } from 'rxjs';

import { AuthService } from './auth.service';
import { GlobalFiltersService } from './global-filters.service';

import { PagedModel } from '../models/paged.model';
import {
	EntityDocumentViewModel,
	DocumentIntakeViewModel,

	DocumentIntakeTableViewModel,
	EntityDocumentIntakeTableViewModel,
	TeamDocumentIntakeTableViewModel,
	CoachDocumentIntakeTableViewModel,
	PlayerDocumentIntakeTableViewModel,

	TeamDocumentIntakeViewModel,
} from '../models/document.models';

import { DocumentSubjectCodes } from '../../constants';
import { handleHttpError, convertRandomDateTimeToISOString } from '../../functions';
import { Environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class DocumentsService {
	private readonly baseUrl = `${Environment.apiBaseUrl}/documents`;

	private httpClient: HttpClient = inject(HttpClient);
	private globalFiltersService: GlobalFiltersService = inject(GlobalFiltersService);

	getDocumentIntake(leagueID: number, teamID: number | null) {
		const httpParams = new HttpParams().appendAll({
			leagueID: leagueID.toString(),
			teamID: teamID?.toString() || '',
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.get<DocumentIntakeViewModel[]>(
					`${this.baseUrl}/intake`,
					{ params: httpParams })
				.pipe(
					map(docs => {
						// map flat documents into table and list views for desktop and mobile display

						// map for desktop table view
						const tableDocs: DocumentIntakeTableViewModel = {
							teamDocuments: [],
							teamDocumentTypes: [],
							coachDocuments: [],
							coachDocumentTypes: [],
							playerDocuments: [],
							playerDocumentTypes: []
						};
						docs.forEach(doc => {
							doc.uploadedDate = convertRandomDateTimeToISOString(doc.uploadedDate);

							let entityDocIntake: EntityDocumentIntakeTableViewModel | null = null;
							if (doc.documentSubjectID === DocumentSubjectCodes.TeamDocument) {
								// team doc
								let teamDoc = tableDocs.teamDocuments.find(td => td.entityID === doc.teamID);
								if (!teamDoc) {
									teamDoc = {
										entityID: doc.teamID,
										entityName: doc.teamName,
										entityTypeCode: 'team',

										teamID: doc.teamID,
										teamName: doc.teamName,

										documents: {}
									};
									tableDocs.teamDocuments.push(teamDoc);
								}
								entityDocIntake = teamDoc;

								// add document type to the list
								if (!tableDocs.teamDocumentTypes.find(dt => dt.documentTypeID === doc.documentID)) {
									tableDocs.teamDocumentTypes.push({
										documentTypeID: doc.documentID,
										documentTypeName: doc.documentName,
										downloadable: doc.downloadable,
										downloadFileID: doc.downloadableFileID,
										uploadable: doc.uploadable,
										required: doc.uploadRequired
									});
								}
							} else if (doc.documentSubjectID === DocumentSubjectCodes.CoachDocument) {
								// coach doc
								let coachDoc = tableDocs.coachDocuments.find(cd => cd.entityID === doc.coachID);
								if (!coachDoc) {
									coachDoc = {
										entityID: doc.coachID!,
										entityName: doc.coachName ?? '',
										entityTypeCode: 'coach',

										teamID: doc.teamID,
										teamName: doc.teamName,

										isManager: doc.isCoachManager,
										documents: {}
									};
									tableDocs.coachDocuments.push(coachDoc);
								}
								entityDocIntake = coachDoc;

								// add document type to the list
								if (!tableDocs.coachDocumentTypes.find(dt => dt.documentTypeID === doc.documentID)) {
									tableDocs.coachDocumentTypes.push({
										documentTypeID: doc.documentID,
										documentTypeName: doc.documentName,
										downloadable: doc.downloadable,
										downloadFileID: doc.downloadableFileID,
										uploadable: doc.uploadable,
										required: doc.uploadRequired
									});
								}
							} else if (doc.documentSubjectID === DocumentSubjectCodes.PlayerDocument) {
								// player doc
								let playerDoc = tableDocs.playerDocuments.find(pd => pd.entityID === doc.playerTeamID);
								if (!playerDoc) {
									playerDoc = {
										entityID: doc.playerTeamID!,
										entityName: doc.playerName ?? '',
										entityTypeCode: 'player',

										teamID: doc.teamID,
										teamName: doc.teamName,

										initials: doc.playerName?.split(' ').map(n => n[0]).join('') ?? '??',
										headshotFileID: doc.playerHeadshotFileID ?? 0,
										dateOfBirth: doc.playerDateOfBirth ?? '',
										age: doc.playerAge ?? 0,
										documents: {}
									};
									tableDocs.playerDocuments.push(playerDoc);
								}
								entityDocIntake = playerDoc;

								// add document type to the list
								if (!tableDocs.playerDocumentTypes.find(dt => dt.documentTypeID === doc.documentID)) {
									tableDocs.playerDocumentTypes.push({
										documentTypeID: doc.documentID,
										documentTypeName: doc.documentName,
										downloadable: doc.downloadable,
										downloadFileID: doc.downloadableFileID,
										uploadable: doc.uploadable,
										required: doc.uploadRequired
									});
								}
							}

							// add document to the entity intake container
							if (entityDocIntake) {
								entityDocIntake.documents[doc.documentID] = this.documentIntakeToEntityDocumentViewModel(doc);
							}
						});

						// map for mobile list view
						const listDocs: TeamDocumentIntakeViewModel[] = [];
						// first group everything by team
						docs.forEach(doc => {
							doc.uploadedDate = convertRandomDateTimeToISOString(doc.uploadedDate);

							let teamDoc = listDocs.find(td => td.teamID === doc.teamID);
							if (!teamDoc) {
								teamDoc = {
									teamID: doc.teamID,
									teamName: doc.teamName,

									documents: [],
									coachDocuments: [],
									documentCoaches: [],
									playerDocuments: [],
									documentPlayers: [],

									coachGroupBy: 'coach',
									playerGroupBy: 'player'
								};
								listDocs.push(teamDoc);
							}

							// add document to the appropriate list
							if (doc.documentSubjectID === DocumentSubjectCodes.TeamDocument) {
								// team doc
								teamDoc.documents.push(this.documentIntakeToEntityDocumentViewModel(doc));
							} else if (doc.documentSubjectID === DocumentSubjectCodes.CoachDocument) {
								// coach documents grouping
								let coachDoc = teamDoc.coachDocuments.find(cd => cd.coachID === doc.coachID);
								if (!coachDoc) {
									coachDoc = {
										coachID: doc.coachID!,
										coachName: doc.coachName ?? '',
										isCoachManager: doc.isCoachManager,

										documents: []
									};
									teamDoc.coachDocuments.push(coachDoc);
								}

								coachDoc.documents.push(this.documentIntakeToEntityDocumentViewModel(doc));

								// document coaches grouping
								let documentCoach = teamDoc.documentCoaches.find(dc => dc.documentID === doc.documentID);
								if (!documentCoach) {
									documentCoach = {
										documentID: doc.documentID,
										documentName: doc.documentName,
										required: doc.uploadRequired,

										coaches: []
									};
									teamDoc.documentCoaches.push(documentCoach);
								}

								documentCoach.coaches.push(this.documentIntakeToEntityDocumentViewModel(doc));
							} else if (doc.documentSubjectID === DocumentSubjectCodes.PlayerDocument) {
								// player document grouping
								let playerDoc = teamDoc.playerDocuments.find(pd => pd.playerTeamID === doc.playerTeamID);
								if (!playerDoc) {
									playerDoc = {
										playerTeamID: doc.playerTeamID!,
										playerName: doc.playerName ?? '',
										playerInitials: doc.playerName?.split(' ').map(n => n[0]).join('') ?? 'TP',
										playerHeadshotFileID: doc.playerHeadshotFileID ?? 0,
										playerDateOfBirth: doc.playerDateOfBirth ?? '',
										playerAge: doc.playerAge ?? 0,

										documents: []
									};
									teamDoc.playerDocuments.push(playerDoc);
								}

								playerDoc.documents.push(this.documentIntakeToEntityDocumentViewModel(doc));

								// document players grouping
								let documentPlayer = teamDoc.documentPlayers.find(dp => dp.documentID === doc.documentID);
								if (!documentPlayer) {
									documentPlayer = {
										documentID: doc.documentID,
										documentName: doc.documentName,
										required: doc.uploadRequired,

										players: []
									};
									teamDoc.documentPlayers.push(documentPlayer);
								}

								documentPlayer.players.push(this.documentIntakeToEntityDocumentViewModel(doc));
							}
						});

						return { tableDocs, listDocs };
					}),
					catchError(handleHttpError)
				)
		);
	}

	private documentIntakeToEntityDocumentViewModel(doc: DocumentIntakeViewModel) {
		return {
			regionId: doc.regionID,
			leagueYearId: doc.leagueYearID,
			leagueId: doc.leagueID,
			leagueName: doc.leagueName,
			teamId: doc.teamID,
			teamName: doc.teamName,
			playerTeamId: doc.playerTeamID ?? 0,
			playerName: doc.playerName,
			coachId: doc.coachID ?? 0,
			coachName: doc.coachName,
			documentSubjectId: doc.documentSubjectID,
			documentSubjectName: doc.documentSubject,
			documentId: doc.documentID,
			documentName: doc.documentName,
			documentInstanceId: doc.documentInstanceID,
			downloadable: doc.downloadable,
			downloadableFileId: doc.downloadableFileID,
			downloadableFileType: doc.downloadableFileType,
			documentSubmissionID: doc.documentSubmissionID,
			uploadable: doc.uploadable,
			uploadedFileId: doc.uploadedFileID ?? 0,
			uploadedFileType: doc.uploadedFileType ?? '',
			uploaderName: doc.uploaderName ?? '',
			uploadedDateTime: doc.uploadedDate ?? '',
			required: doc.uploadRequired,
			statusId: doc.statusID,
			statusName: doc.statusName,
			statusNotes: doc.documentStatusNotes ?? '',
			version: doc.submissionVersion,
			isCurrentVersion: doc.isCurrentVersion,
			olderVersions: null
		} as EntityDocumentViewModel;
	}

	downloadFile(documentFileID: number) {
		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.get<Blob>(
					`${this.baseUrl}/files/${documentFileID}`,
					{ params: httpParams, responseType: 'blob' as 'json' })
				.pipe(catchError(handleHttpError))
		);
	}

	getFileThumbnail(documentFileID: number) {
		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.get<Blob>(
					`${this.baseUrl}/files/${documentFileID}/thumbnail`,
					{ params: httpParams, responseType: 'blob' as 'json' })
				.pipe(catchError(handleHttpError))
		);
	}

	uploadDocument(
		documentInstanceID: number,
		teamID: number | null,
		coachID: number | null,
		playerTeamID: number | null,
		file: File | Blob,
		fileName: string
	) {
		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		const formData = new FormData();
		formData.append('documentInstanceId', documentInstanceID.toString());
		formData.append('teamId', teamID?.toString() || '');
		if (coachID) {
			formData.append('coachId', coachID.toString());
		}
		if (playerTeamID) {
			formData.append('playerTeamId', playerTeamID.toString());
		}
		formData.append('file', file, fileName);

		return lastValueFrom(
			this.httpClient
				.post<number>(
					`${this.baseUrl}/upload`,
					formData,
					{ params: httpParams })
				.pipe(catchError(handleHttpError))
		);
	}

	renameDocument(documentId: number, documentName: string) {
		const params: HttpParams = new HttpParams().appendAll({
			documentName: documentName,
			regionId: '',
			yearId: '',
		});

		return lastValueFrom(
			this.httpClient
				.put<void>(`${this.baseUrl}/${documentId}/rename`, null, { params })
				.pipe(catchError(handleHttpError))
		);
	}

	uploadNewDocumentFileVersion(documentID: number, file: File) {
		const params: HttpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		const formData = new FormData();
		formData.append('documentId', documentID.toString());
		formData.append('file', file, file.name);

		return lastValueFrom(
			this.httpClient
				// TODO: SWITCH TO THE UNIFIED MODEL DocumentResourceViewModel
				.post<any>(
					`${this.baseUrl}/files/upload`,
					formData,
					{ params: params })
				.pipe(catchError(handleHttpError))
		);
	}
}
