import { CommonModule } from '@angular/common';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { Subject, Subscription, debounceTime, distinctUntilChanged, switchMap, catchError, of } from 'rxjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
	faTriangle as faTriangleRegular, faEllipsis, faPaperPlaneTop
} from '@fortawesome/pro-regular-svg-icons';
import { faTriangle as faTriangleSolid, faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { MomentModule } from 'ngx-moment';
import { ToastrService } from 'ngx-toastr';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { ConfirmationService } from '../../../../services/confirmation.service';
import { GlobalFiltersService } from '../../../../services/global-filters.service';
import { ForumService } from '../../../../services/forum.service';

import { ActionConfirmationComponent } from '../../../../components/action-confirmation/action-confirmation.component';
import { SpinnerComponent } from '../../../../components/loaders/spinner/spinner.component';
import { TablePlaceholderComponent } from '../../../../components/loaders/table-placeholder/table-placeholder.component';
import { ErrorComponent } from '../../../../components/error/error.component';
import { EmptyComponent } from '../../../../components/empty/empty.component';
import { VerticalLineComponent } from '../../../../components/vline/vline.component';
import { CancelIconComponent } from '../../../../components/action-icons/cancel-icon.component';

import { ShortTimeAgoPipe } from '../../../../pipes/short-time-ago.pipe';

import { PagedModel } from '../../../../models/paged.model';
import { ForumCommentViewModel } from '../../../../models/forum.models';

@Component({
	selector: 'app-forum-comments',
	templateUrl: './forum-comments.component.html',
	styleUrl: './forum-comments.component.scss',
	standalone: true,
	imports: [
		CommonModule, RouterModule, FormsModule,
		FontAwesomeModule, MomentModule, NgbTooltipModule, NgbDropdownModule,
		ActionConfirmationComponent, SpinnerComponent, TablePlaceholderComponent, ErrorComponent, EmptyComponent, VerticalLineComponent,
		CancelIconComponent,
		ShortTimeAgoPipe
	]
})
export class ForumCommentsComponent implements OnInit, OnDestroy {
	@Input({ required: true }) postID!: number;
	@Output() commentsChange = new EventEmitter<ForumCommentViewModel[]>();

	// internal state
	comments: ForumCommentViewModel[] = [];
	commentsLoading: boolean = false;
	commentsError: boolean = false;

	newComment: string = '';
	newCommentSaving: boolean = false;

	// services
	private toastrService: ToastrService = inject(ToastrService);
	private confirmationService: ConfirmationService = inject(ConfirmationService);
	private forumService: ForumService = inject(ForumService);

	// icons
	upvoteIcon = faTriangleRegular;
	upvotedIcon = faTriangleSolid;
	actionsIcon = faEllipsis;
	sendIcon = faPaperPlaneTop;

	ngOnInit() {
		this.onLoadComments();
	}

	ngOnDestroy() {

	}

	async onLoadComments() {
		try {
			this.commentsLoading = true;
			this.commentsError = false;

			this.comments = await this.forumService.getPostComments(this.postID);
		} catch (error) {
			this.commentsError = true;
		} finally {
			this.commentsLoading = false;
		}
	}

	// async onLoadOlderComments(post: ForumPostViewModel) {
	// 	try {
	// 		post.loadingOlderComments = true;

	// 		const comments = await this.forumService.loadOlderComments(post.postID, post.comments.length > 0 ? post.comments[0].commentID : null);

	// 		post.comments.splice(0, 0, ...comments);
	// 	} catch (error) {
	// 		this.toastrService.error(`Failed to older comments. Please try again.`)
	// 	} finally {
	// 		post.loadingOlderComments = false;
	// 	}
	// }

	// async onLoadNewerComments(post: ForumPostViewModel) {
	// 	try {
	// 		post.loadingNewerComments = true;

	// 		const comments = await this.forumService.loadNewerComments(post.postID, post.comments.length > 0 ? post.comments[post.comments.length - 1].commentID : null);

	// 		post.comments.push(...comments);
	// 	} catch (error) {
	// 		this.toastrService.error(`Failed to newer comments. Please try again.`)
	// 	} finally {
	// 		post.loadingNewerComments = false;
	// 	}
	// }

	async onDeleteComment(comment: ForumCommentViewModel) {
		const shortComment = comment.comment.length > 100 ? `${comment.comment.substr(0, 100)}...` : comment.comment;

		this.confirmationService.confirm(
			'Delete Comment',
			`Are you sure you want to delete this comment?<br/><br/>
			<div class="alert alert-secondary">${shortComment}</div>`,
			'Delete',
			'btn-danger',
			async () => {
				try {
					await this.forumService.deletePostComment(comment.commentID, this.postID);

					const commentIndex = this.comments.findIndex(x => x.commentID === comment.commentID);
					if (commentIndex >= 0) {
						this.comments.splice(commentIndex, 1);
					}

					this.toastrService.success(`Comment has been deleted.`);

					return true;
				} catch (error) {
					this.toastrService.error(`Failed to delete the comment. Please try again.`)
					return false;
				}
			});
	}

	async onReportComment(comment: ForumCommentViewModel) {
		const shortComment = comment.comment.length > 100 ? `${comment.comment.substr(0, 100)}...` : comment.comment;

		this.confirmationService.confirm(
			'Report Comment',
			`Are you sure you want to report this comment?<br/><br/>
			<div class="alert alert-secondary">${shortComment}</div>Reported posts are hidden while they're being reviewed.`,
			'Report',
			'btn-warning',
			async () => {
				try {
					await this.forumService.reportPostComment(comment.commentID, this.postID);

					const commentIndex = this.comments.findIndex(x => x.commentID === comment.commentID);
					if (commentIndex >= 0) {
						this.comments.splice(commentIndex, 1);
					}

					this.toastrService.success(`Comment reported to MLB/RBI. Comment will be hidden while it's being reviewed.`);

					return true;
				} catch (error) {
					this.toastrService.error(`Failed to report the comment. Please try again.`)
					return false;
				}
			});
	}

	async onToggleCommentLiked(comment: ForumCommentViewModel) {
		try {
			comment.togglingLike = true;

			comment = comment.isLikedByMe
				? await this.forumService.unlikePostComment(comment.commentID, this.postID)
				: await this.forumService.likePostComment(comment.commentID, this.postID);

			const commentIndex = this.comments.findIndex(x => x.commentID === comment.commentID);
			if (commentIndex >= 0) {
				this.comments[commentIndex] = comment;
			}
		} catch (error) {
			this.toastrService.error(`Failed to ${comment.isLikedByMe ? 'unlike' : 'like'} the comment. Please try again.`)
		} finally {
			comment.togglingLike = false;
		}
	}

	async onAddNewComment() {
		try {
			this.newCommentSaving = true;

			const savedComment = await this.forumService.createPostComment({ comment: this.newComment }, this.postID);

			this.comments.push(savedComment);
			this.commentsChange.emit(this.comments);
			this.newComment = '';
		} catch (error) {
			this.toastrService.error(`Failed to add new comment. Please try again.`)
		} finally {
			this.newCommentSaving = false;
		}
	}
}
