import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, inject } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faMagnifyingGlassPlus, faMagnifyingGlassMinus } from '@fortawesome/pro-regular-svg-icons';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
// import { fileTypeFromBlob } from 'file-type/core';

import { SpinnerComponent, ErrorComponent } from '@app/shared/components';
import { AuthService, DocumentsService, MimeTypeService, ApprovalsService, ConfirmationService } from '@app/shared/services';
import { EntityDocumentViewModel } from '@app/shared/models';
import { SafePipe } from '@app/shared/pipes';
import { Messages, AppPermissionCodes } from '@app/constants';

@Component({
	selector: 'app-doc-viewer-page',
	templateUrl: './document-viewer.page.html',
	styleUrl: './document-viewer.page.scss',
	standalone: true,
	imports: [
		CommonModule, FontAwesomeModule, NgbTooltipModule,
		SpinnerComponent, ErrorComponent,
		SafePipe
	]
})
export class DocumentViewerPage implements OnInit {
	@Input() leagueName?: string;
	@Input() teamName?: string;
	@Input() personName?: string;

	@Input() documentName?: string;
	@Input() documentFileURL?: string;
	@Input() documentFileID?: number;
	@Input() submissionID?: number;
	@Input() extension?: string;

	@Output() approved = new EventEmitter<EntityDocumentViewModel>();
	@Output() rejected = new EventEmitter<EntityDocumentViewModel>();

	// TODO: ADD DECISION CALLBACK

	// computed fields
	get title() {
		return [this.documentName, this.personName, this.teamName, this.leagueName].filter(t => t).join(' - ');
	}

	get canReview() {
		return this.submissionID && this.authService.hasPermission(AppPermissionCodes.Approvals_Execute)
	}

	// internal state
	protected fileUrl: string | null = null;
	protected fileLoading = false;
	protected fileError = false;

	protected isImageFile = false;
	protected imageZoomLevel = 100;

	protected approving = false;
	protected rejecting = false;

	// services
	protected toastrService: ToastrService = inject(ToastrService);
	protected activeModal: NgbActiveModal = inject(NgbActiveModal);
	protected authService: AuthService = inject(AuthService);
	private documentsService: DocumentsService = inject(DocumentsService);
	private mimeTypeService: MimeTypeService = inject(MimeTypeService);
	private approvalsService: ApprovalsService = inject(ApprovalsService);
	private confirmationService: ConfirmationService = inject(ConfirmationService);

	// constants
	AppPermissionCodes = AppPermissionCodes;

	// icons
	zoonIn = faMagnifyingGlassPlus;
	zoomOut = faMagnifyingGlassMinus;

	ngOnInit() {
		this.onLoadDocumentFile();
	}

	ngAfterViewInit() {
		if (this.documentFileURL) {
			const mimeType = this.extension ? this.mimeTypeService.lookup(this.extension) : null;
			this.isImageFile = this.isImageFileType(mimeType || '');
			this.fileUrl = this.documentFileURL || null;
		}
	}

	async onLoadDocumentFile() {
		if (this.fileLoading || !this.documentFileID) return;

		try {
			this.fileLoading = true;
			this.fileError = false;

			let file = await this.documentsService.downloadFile(this.documentFileID);
			const mimeType = this.extension ? this.mimeTypeService.lookup(this.extension) : null;
			file = this.changeBlobMimeType(file, mimeType || file.type.toString());
			this.isImageFile = this.isImageFileType(mimeType || file.type.toString());
			this.fileUrl = URL.createObjectURL(file);
		} catch (error) {
			console.error(error);
			this.fileError = true;
		} finally {
			this.fileLoading = false;
		}
	}

	private isImageFileType(mimeType: string) {
		let imageMimeTypes = [
			'image/bmp',
			'image/jpeg',
			'image/x-png',
			'image/png',
			'image/gif',
			'image/webp',
			'image/svg+xml',
			// pure extensions
			'bmp',
			'jpg',
			'jpeg',
			'png',
			'gif',
			'webp',
			'svg',
			'svgz',
		];

		return imageMimeTypes.includes(mimeType?.trim()?.toLowerCase());
	}

	async onApprove() {
		if (!this.submissionID || this.approving) return;

		try {
			this.approving = true;

			const approval = await this.approvalsService.approveDocument(this.submissionID);
			this.approved.emit(approval);
			this.toastrService.success(`${(this.documentName || 'Document')} Approved`);
			this.onClose();
		} catch (error) {
			this.toastrService.error(Messages.ErrorRetry, `Failed to Approve ${(this.documentName || 'Document')}`);
		} finally {
			this.approving = false;
		}
	}

	async onReject() {
		if (!this.submissionID || this.rejecting) return;

		this.confirmationService.confirm(
			'Reject Document?',
			`What is the reason for rejecting this <strong>${(this.documentName || 'document')}</strong>?`,
			'Reject',
			'btn-danger',
			async (reason?: string) => {
				if (!reason?.trim()) return false;

				try {
					this.rejecting = true;

					const approval = await this.approvalsService.rejectDocument(this.submissionID!, reason);
					this.rejected.emit(approval);
					this.toastrService.success(`${(this.documentName || 'Document')} Rejected`);
					this.onClose();

					return true;
				} catch (error) {
					this.toastrService.error(Messages.ErrorRetry, `Failed to Reject ${(this.documentName || 'Document')}`);

					return false;
				} finally {
					this.rejecting = false;
				}
			},
			true,
			true,
			'Rejection reason...'
		);
	}

	onZoomIn() {
		this.imageZoomLevel += 10;
	}

	onZoomOut() {
		this.imageZoomLevel -= 10;
	}

	onClose() {
		this.activeModal?.dismiss();
	}

	private changeBlobMimeType(originalBlob: Blob, newType: string): Blob {
		return new Blob([originalBlob], { type: newType });
	}
}
