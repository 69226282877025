import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-field-separator',
	templateUrl: 'field-separator.component.html',
	styleUrl: 'field-separator.component.scss',
	standalone: true
})
export class FieldSeparatorComponent {
}
