import { Injectable, OnDestroy, inject } from '@angular/core';
import {
	HttpClient,
	HttpErrorResponse,
	HttpParams,
} from '@angular/common/http';

import {
	ReplaySubject,
	catchError,
	throwError,
	lastValueFrom,
	map,
} from 'rxjs';

// import {  } from '../models/lookup.models';

import { GlobalFiltersService } from '@app/root/app/shared';

import { removeEmptyParams, handleHttpError } from '../../functions';
import { Environment } from '@app/environment';
import { LookupView } from '@app/root/app/shared';

@Injectable({ providedIn: 'root' })
export class LookupService implements OnDestroy {
	private readonly baseUrl = `${Environment.apiBaseUrl}/lookups`;

	// map of behavior subjects for each lookup type and their load status
	private lookupSubjects: { [key: string]: ReplaySubject<any[]> } = {};
	private lookupLoaded: { [key: string]: boolean } = {};

	// services
	private http: HttpClient = inject(HttpClient);
	private globalFiltersService: GlobalFiltersService =
		inject(GlobalFiltersService);

	ngOnDestroy() { }

	/**
	 * Fetches values for specified lookup (code) and returns an Observable of T[].
	 * All simple lookups use the Lookup data type. For complex lookups, use their complex
	 * data type (e.g. ClientLookup, PortalUserLookup, etc).
	 * @param lookupCode Code for the lookup to retrieve (use LookupCodes constants from \@app/constants - don't hardcode strings).
	 * @param parentId Optional parent ID for lookups with parent-child relationships (e.g. country's states; etc.).
	 */
	public getLookup<T>(lookupCode: string, parentId?: number) {
		this.loadLookup<T[]>(lookupCode, parentId);

		const cacheID = parentId ? `${lookupCode}-${parentId}` : lookupCode;
		return (
			this.lookupSubjects[cacheID] as ReplaySubject<T[]>
		).asObservable();
	}

	public clearLookup(lookupCode: string, parentId?: number) {
		const cacheID = parentId ? `${lookupCode}-${parentId}` : lookupCode;
		delete this.lookupSubjects[cacheID];
		delete this.lookupLoaded[cacheID];
	}

	public clearAllLookups() {
		this.lookupSubjects = {};
		this.lookupLoaded = {};
	}

	// ****
	//	NOTE: Don't add custom lookup methods for every lookup - use generics with getLookup<T>,
	//	where T is the data type of the lookup.
	//	Create a new method only if the lookup is exposed on a custom endpoint/requires additional parameters.
	// ****

	// public getClientPlanMembersLookup(
	// 	clientID: number | null | undefined,
	// 	clientPlanID: number | null | undefined,
	// 	activePlansOnly: boolean,
	// 	activeMembersOnly: boolean,
	// 	query: string | null | undefined,
	// 	page: number
	// ) {
	// 	let options = {
	// 		params: removeEmptyParams(new HttpParams({
	// 			fromObject: {
	// 				clientID: clientID,
	// 				clientPlanID: clientPlanID,
	// 				activePlansOnly: activePlansOnly,
	// 				activeMembersOnly: activeMembersOnly,
	// 				query: query,
	// 				page: page
	// 			}
	// 		}))
	// 	};

	// 	return lastValueFrom(
	// 		this.http
	// 			// TODO: CHANGE TO PAGED ONCE PAGED => PagedDataModel<ClientPlanMemberLookup>
	// 			.get<ClientPlanMemberLookup[]>(`${this.baseUrl}/client-plan-members`, options)
	// 			.pipe(catchError(handleHttpError))
	// 	);
	// }

	private loadLookup<T>(lookupCode: string, parentId?: number) {
		const cacheID = parentId ? `${lookupCode}-${parentId}` : lookupCode;

		if (this.lookupLoaded[cacheID]) {
			// || this.lookupSubjects[cacheID]) {
			return;
		}

		this.lookupSubjects[cacheID] = new ReplaySubject<T[]>(1);

		this.fetchLookup<T>(lookupCode, parentId)
			.pipe(
				catchError((err) => {
					handleHttpError(err);
					// propagate error to subject subscribers
					this.lookupSubjects[cacheID].error(err);
					// rethrow
					return throwError(() => err);
				}),
			)
			.subscribe((data) => {
				console.log(
					`LookupService.loadLookup(${lookupCode} - ${parentId || null})`,
				);
				// propagate data to subject subscribers
				this.lookupSubjects[cacheID].next(data);
				this.lookupLoaded[cacheID] = true;
				// complete the subject after emitting data
				// TODO: REVIEW WHETHER THIS IS CORRECT IF THE LOOKUP IS REFRESHED LATER
				this.lookupSubjects[cacheID].complete();
			});
	}

	private fetchLookup<T>(lookupCode: string, parentId?: number) {
		const httpParams = new HttpParams().appendAll({
			parentId: parentId ?? '',
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? '',
		});

		return this.http.get<T[]>(`${this.baseUrl}/${lookupCode}`, {
			params: httpParams,
		});
	}
}
