import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { PlayerIconComponent } from '../entity-icons/player-icon.component';

import { SystemConstants } from '../../../constants';

@Component({
	selector: 'app-players-badge',
	template: `
	<span class="badge" [ngClass]="[ hasWarning ? 'text-warning-emphasis bg-warning-subtle' : '', hasSuccess ? 'text-success-emphasis bg-success-subtle' : '', !hasWarning && !hasSuccess ? 'text-bg-light' : '', badgeClass ]" ngbTooltip="{{ tooltip }}"><app-player-icon class="me-1" /> {{ playerCount | number }} @if (teamCount === 0 || teamCount) { / {{ (teamCount * SystemConstants.TeamPlayersMax) | number }} } @if (showLabel) { Players }</span>
	`,
	standalone: true,
	imports: [CommonModule, FontAwesomeModule, NgbTooltipModule, PlayerIconComponent]
})
export class PlayersBadgeComponent {
	@Input({ required: true }) playerCount!: number;
	@Input() teamCount?: number;
	@Input() hasWarning: boolean = false;
	@Input() hasSuccess: boolean = false;
	@Input() showLabel: boolean = false;
	@Input() tooltip: string = 'Number of players (current/possible)';
	@Input() badgeClass: string = '';

	// constants
	SystemConstants = SystemConstants;
}
