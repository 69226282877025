import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-arrow-divider',
	template: `
	<svg width="20" height="80" viewBox="0 0 20 80" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2337_58152)">
<g clip-path="url(#clip1_2337_58152)">
<path d="M12 40L0 0V80L12 40Z" class="{{ startColorClass }}" />
<path d="M20 40L8 0H20V40V80H8L20 40Z" class="{{ endColorClass }}" />
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 40L0.000244141 0L8.00024 0L20.0002 40L8.00024 80H0.000244319L12 40Z" fill="transparent" />
</g>
</g>
<defs>
<clipPath id="clip0_2337_58152">
<rect width="20" height="80" class="white" />
</clipPath>
<clipPath id="clip1_2337_58152">
<rect width="20" height="80" class="white" />
</clipPath>
</defs>
</svg>
	`,
	standalone: true,
	imports: []
})
export class ArrowDividerComponent {
	@Input() startColorClass: string = 'fill-light';
	@Input() endColorClass: string = 'fill-light';
}
