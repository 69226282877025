import { Component, Input } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-info-icon',
	template: '<fa-icon [icon]="icon" [ngbPopover]="popoverTemplate" [popoverContext]="popoverContext" triggers="mouseenter:mouseleave" container="body"></fa-icon>',
	standalone: true,
	imports: [FontAwesomeModule, NgbPopoverModule]
})
export class InfoIconComponent {
	icon = faInfoCircle;

	@Input() popoverTemplate: any;
	@Input() popoverContext: any;
	@Input() class: string = '';
}
