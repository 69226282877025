import { Injectable, inject } from '@angular/core';
import { UrlSegment } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';

import { ReplaySubject, catchError, lastValueFrom } from 'rxjs';

import { AppInsightsService } from '@app/core/services/appinsights.service';
import { LoginResponseModel, AccessTokenModel } from '../models/auth.models';

import { handleHttpError } from '../../functions';
import { Environment } from '@app/environment';

interface TokenResponseModel {
	access_token: string
	token_type: string
	expires_in: number
	refresh_token: string
	"as:client_id": string
	audience: string
	".issued": string
	".expires": string
}

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private static readonly SessionKeys = {
		AccessToken: 'rpm_access_token',
		RefreshToken: 'rpm_refresh_token',
		ExpiresOn: 'rpm_expires_on'
	};

	private readonly baseUrl = `${Environment.authBaseUrl}/token`;
	private readonly userUrl = `${Environment.apiBaseUrl}/users`;

	private userAccessToken?: AccessTokenModel;
	private userSubject = new ReplaySubject<AccessTokenModel | null>(1);

	// services
	private httpClient = inject(HttpClient);
	private appInsights = inject(AppInsightsService);

	constructor() {
		const accessToken = this.accessToken;
		// const refreshToken = this.refreshToken;
		// const expiresOn = this.expiresOn;

		if (accessToken) {
			this.userAccessToken = this.parseAccessToken(accessToken);
			this.userSubject.next(this.userAccessToken);
		}
	}

	private _redirectUrl: UrlSegment[] | string | null = null;
	get redirectUrl() {
		return this._redirectUrl;
	}
	set redirectUrl(value: UrlSegment[] | string | null) {
		console.log('Redirect URL:', value ?? 'null');
		this._redirectUrl = value;
	}

	get accessToken() {
		return sessionStorage.getItem(AuthService.SessionKeys.AccessToken);
	}
	get refreshToken() {
		return sessionStorage.getItem(AuthService.SessionKeys.RefreshToken);
	}
	get expiresOn() {
		return sessionStorage.getItem(AuthService.SessionKeys.ExpiresOn);
	}

	isAuthenticated() {
		return !!this.accessToken;
	}

	getUserProfile() {
		return this.userAccessToken;
	}
	getUserProfile$() {
		return this.userSubject.asObservable();
	}

	isInRole(roleCode: string) {
		return this.userAccessToken?.role?.includes(roleCode);
	}

	hasPermission(permissionCode: string) {
		return this.userAccessToken?.permission?.includes(permissionCode);
	}

	async login(username: string, password: string) {
		const body = new URLSearchParams();
		body.set('grant_type', 'password');
		body.set('client_id', '099153c2625149bc8ecb3e85e03f0022');
		body.set('username', username);
		body.set('password', password);

		try {
			const response = await lastValueFrom(this.httpClient
				.post<TokenResponseModel>(
					this.baseUrl,
					body.toString(),
					{ headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
				)
				.pipe(catchError(handleHttpError)));

			this.processAccessTokenResponse(response);

			this.appInsights.setAuthenticatedUserContext(this.userAccessToken?.sub);

			return { success: true } as LoginResponseModel;
		} catch (err: any) {
			console.error(err);

			const message = err?.error?.error_description ?? 'Unexpected sign in error. Please try again.';

			return { success: false, message: message } as LoginResponseModel;
		}
	}

	async refreshAccessToken() {
		const body = new URLSearchParams();
		body.set('grant_type', 'refresh_token');
		body.set('client_id', '099153c2625149bc8ecb3e85e03f0022');
		body.set('refresh_token', this.refreshToken ?? '');

		const response = await lastValueFrom(this.httpClient
			.post<TokenResponseModel>(
				this.baseUrl,
				body.toString(),
				{ headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
			)
			.pipe(catchError(handleHttpError)));

		this.processAccessTokenResponse(response);

		return { success: true } as LoginResponseModel;
	}

	logout() {
		this.appInsights.clearAuthenticatedUserContext();

		sessionStorage.removeItem(AuthService.SessionKeys.AccessToken);
		sessionStorage.removeItem(AuthService.SessionKeys.RefreshToken);
		sessionStorage.removeItem(AuthService.SessionKeys.ExpiresOn);

		this.redirectUrl = null;

		this.userSubject.next(null);
	}

	resetUserPassword(email: string, recaptchaResponse: string) {
		const httpParams: HttpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: '',
		});

		return lastValueFrom(
			this.httpClient
				.post<void>(
					`${this.userUrl}/resetpassword`,
					{ email, recaptchaResponse, isV3: true },
					{ params: httpParams },
				).pipe(catchError(handleHttpError)),
		)
	}

	validateResetPasswordToken(resetToken: string) {
		const httpParams: HttpParams = new HttpParams().appendAll({
			resetToken,
			regionId: '',
			yearId: '',
		});

		return lastValueFrom(
			this.httpClient
				.get<string>(
					`${this.userUrl}/resetpassword/validatetoken`,
					{ params: httpParams },
				).pipe(catchError(handleHttpError)),
		);
	}

	changeUserPassword(email: string, resetToken: string, newPassword: string, regionId: string = '') {
		const httpParams: HttpParams = new HttpParams().appendAll({
			regionId,
			yearId: '',
		});

		return lastValueFrom(
			this.httpClient
				.post<void>(
					`${this.userUrl}/changepassword`,
					{ username: email, resetToken, newPassword },
					{
						params: httpParams,
					},
				)
				.pipe(catchError(handleHttpError)),
		);
	}

	private processAccessTokenResponse(response: TokenResponseModel) {
		console.log(response);

		const expiresOn = new Date((new Date()).getTime() + response.expires_in * 1000);

		// update session storage with access token, refresh token, and expiration
		sessionStorage.setItem(AuthService.SessionKeys.AccessToken, response.access_token);
		sessionStorage.setItem(AuthService.SessionKeys.RefreshToken, response.refresh_token);
		sessionStorage.setItem(AuthService.SessionKeys.ExpiresOn, expiresOn.toISOString());

		// decode access token jwt into user model
		this.userAccessToken = this.parseAccessToken(response.access_token);
		console.log('JWT', this.userAccessToken);
		this.userSubject.next(this.userAccessToken);
	}

	private parseAccessToken(accessToken: string) {
		const model = JSON.parse(atob(accessToken.split('.')[1])) as AccessTokenModel;

		// clean up the model (make strings to arrays and remove empty array items)
		model.region = this.removeEmptyArrayItems(this.convertStringToArray(model.region));
		model.limitedRegion = this.removeEmptyArrayItems(this.convertStringToArray(model.limitedRegion));
		model.fullLeague = this.removeEmptyArrayItems(this.convertStringToArray(model.fullLeague));
		model.guestLeague = this.removeEmptyArrayItems(this.convertStringToArray(model.guestLeague));
		model.role = this.removeEmptyArrayItems(this.convertStringToArray(model.role));
		model.roleName = this.removeEmptyArrayItems(this.convertStringToArray(model.roleName));
		model.permission = this.removeEmptyArrayItems(this.convertStringToArray(model.permission));
		model.permissionName = this.removeEmptyArrayItems(this.convertStringToArray(model.permissionName));

		return model;
	}

	private convertStringToArray(value: string | string[]) {
		if (typeof value === 'string') {
			return [value];
		} else {
			return value;
		}
	}

	private removeEmptyArrayItems(value: string[]) {
		return value?.filter((x: any) => !!x);
	}
}
