import { Component } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFiles } from '@fortawesome/pro-regular-svg-icons';

@Component({
	selector: 'app-docs-icon',
	template: '<fa-icon [icon]="icon" />',
	standalone: true,
	imports: [FontAwesomeModule]
})
export class DocumentsIconComponent {
	icon = faFiles;
}
