<div class="modal-header px-4">
	<h4 class="modal-title">
		{{ title }}
	</h4>

	<button type="button" class="btn-close" (click)="onCancel()">
		<span class="visually-hidden">&times;</span>
	</button>
</div>

<div class="modal-body px-4 py-3">
	<div>
		<label class="form-label" for="leagueSelect">LeagueApps site to connect</label>
		<ng-select labelForId="leagueSelect" name="leagueSelect" [(ngModel)]="selectedSiteID"
			[loading]="sitesLookupLoading" notFoundText="No matching sites" [clearable]="false"
			[loading]="sitesLookupLoading" [readonly]="sitesLookupLoading">
			@for (site of filteredSitesLookup; track site.id) {
			<ng-option [value]="site.id">{{ site.name }} ({{ site.id }})</ng-option>
			}
		</ng-select>
	</div>
	@if (!authService.hasPermission(AppPermissionCodes.Maintenance_Execute)) {
	<div class="alert alert-warning mt-3" role="alert">
		NOTE: MLB RBI program director will review your request to connect to the selected site.<br />
		Once approved, you will be able to use your LeagueApps players and coaches for your tournament teams.
	</div>
	}
</div>

<div class="modal-footer bg-white px-4">
	<button type="button" class="btn btn-outline-secondary" (click)="onCancel()">
		Cancel
	</button>
	<button type="submit" class="btn" [ngClass]="actionButtonClass" (click)="onConfirm()"
		[disabled]="inProgress || !selectedSiteID">
		{{ actionTitle }} @if(inProgress) { <app-spinner class="ms-1" /> }
	</button>
</div>
