import { Injectable, inject } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ActionConfirmationComponent } from '@app/root/app/shared';

@Injectable({
	providedIn: 'root',
})
export class ConfirmationService {
	private modalService: NgbModal = inject(NgbModal);

	confirm(
		title: string,
		messageHtml: string,
		actionTitle: string,
		actionButtonClass: string,
		actionFunc?: () => Promise<boolean>,
		showPrompt: boolean = false,
		promptRequired: boolean = false,
		promptPlaceholder?: string,
		promptValue?: string,
	) {
		const modal = this.modalService.open(ActionConfirmationComponent);

		modal.componentInstance.title = title;
		modal.componentInstance.messageHtml = messageHtml;
		modal.componentInstance.actionTitle = actionTitle;
		modal.componentInstance.actionButtonClass = actionButtonClass;
		modal.componentInstance.actionFunc = actionFunc;

		modal.componentInstance.showPrompt = showPrompt;
		modal.componentInstance.promptRequired = promptRequired;
		modal.componentInstance.promptPlaceholder = promptPlaceholder;
		modal.componentInstance.promptValue = promptValue;
	}
}
