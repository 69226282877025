<div class="modal-header px-4">
	<h4 class="modal-title"> Change Password </h4>

	<button type="button" class="btn-close" (click)="onCancel()">
		<span class="visually-hidden">&times;</span>
	</button>
</div>

<div class="modal-body px-4 pt-3">
	<div class="mb-3">
		<label class="form-label text-secondary" for="newPasswordInput">New password</label>
		<input
			id="newPasswordInput"
			name="newPasswordInput"
			class="form-control"
			type="password"
			placeholder="New password"
			autofocus
			required
			[(ngModel)]="passwordNew"
		/>
	</div>
	<div class="field-required">
		<label class="form-label text-secondary" for="changePasswordConfirmInput">Confirm password</label>
		<input
			id="changePasswordConfirmInput"
			name="changePasswordConfirmInput"
			class="form-control"
			type="password"
			placeholder="Confirm password"
			required
			[(ngModel)]="passwordConfirm"
			aria-describedby="passwordHelpBlock"
		/>
		<div id="passwordHelpBlock" style="height: 24px" class="mt-2">
			@if (passwordNew !== passwordConfirm) {
			<div class="form-text text-danger"> Password does not match. </div>
			}
		</div>
	</div></div
>

<div class="modal-footer bg-white px-4">
	<button type="button" class="btn btn-outline-secondary" (click)="onCancel()"> Cancel </button>
	<button type="submit" class="btn btn-primary" (click)="onChangePassword()" [disabled]="passwordSaving || !isValidPassword()">
		Save @if(passwordSaving) { <app-spinner class="ms-1" /> }
	</button>
</div>
