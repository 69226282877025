import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { Observable, Subscription, skip, catchError, tap, throwError } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';

import { SpinnerComponent } from '../../loaders/spinner/spinner.component';
import { LookupService } from '../../../services/lookup.service';
import { LookupView } from '../../../models/lookup.models';
import { LookupCodes, Messages } from '../../../../constants';

@Component({
	templateUrl: 'league-picker.modal.html',
	styleUrl: 'league-picker.modal.scss',
	standalone: true,
	imports: [CommonModule, FormsModule, NgSelectModule, SpinnerComponent]
})
export class LeaguePickerModal implements OnInit {
	// inputs
	@Input() title: string = '??????';

	@Input() actionTitle: string = '?????';
	@Input() actionButtonClass: string = 'btn-primary';
	@Input() actionFunc?: (leagueID: number) => Promise<boolean>;

	// output variables
	confirmed: boolean = false;

	// internal state
	selectedLeagueID: number | null = null;
	protected inProgress: boolean = false;

	// services
	private activeModal = inject(NgbActiveModal);
	private toastrService: ToastrService = inject(ToastrService);
	private lookupService: LookupService = inject(LookupService);

	// lookups
	leaguesLookup$!: Observable<LookupView[]>;
	leaguesLookupLoading = true;

	constructor() {
		this.initializeLookups();
	}

	private initializeLookups() {
		this.leaguesLookup$ = this.lookupService.getLookup<LookupView>(LookupCodes.Leagues)
			.pipe(
				tap(() => setTimeout(() => this.leaguesLookupLoading = false, 0)),
				catchError(err => {
					setTimeout(() => this.leaguesLookupLoading = false, 0);
					return this.showLookupErrorToast('Leagues', err);
				})
			);
	}

	private showLookupErrorToast(lookupName: string, error: any) {
		this.toastrService.error(Messages.ErrorRetry, `Failed to Load '${lookupName}'`);
		return throwError(() => error);
	}

	ngOnInit() {
	}

	async onConfirm() {
		if (!this.selectedLeagueID) {
			return;
		}

		if (!this.actionFunc) {
			this.confirmed = true;
			this.activeModal.close();
			return;
		}

		try {
			this.inProgress = true;
			const success = await this.actionFunc(this.selectedLeagueID);
			if (success) {
				this.confirmed = true;
				this.activeModal.close();
			} else {
				this.confirmed = false;
			}
		} catch (error: any) {
			this.confirmed = false;
		} finally {
			this.inProgress = false;
		}
	}

	onCancel() {
		this.activeModal.dismiss();
	}
}
