import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';  // Using default import

@Pipe({
	name: 'shortTimeAgo',
	standalone: true
})
export class ShortTimeAgoPipe implements PipeTransform {

	transform(value: any, args?: any): any {
		const date = moment(value);
		const now = moment();
		const diffYears = now.diff(date, 'years');
		const diffMonths = now.diff(date, 'months');
		const diffWeeks = now.diff(date, 'weeks');
		const diffDays = now.diff(date, 'days');
		const diffHours = now.diff(date, 'hours');
		const diffMinutes = now.diff(date, 'minutes');

		if (diffYears > 0) {
			return `${diffYears}y`;
		} else if (diffMonths > 0) {
			return `${diffMonths}mo`;
		} else if (diffWeeks > 0) {
			return `${diffWeeks}w`;
		} else if (diffDays > 0) {
			return `${diffDays}d`;
		} else if (diffHours > 0) {
			return `${diffHours}h`;
		} else if (diffMinutes > 0) {
			return `${diffMinutes}m`;
		} else {
			return 'now';
		}

		// return date.fromNow(); // Uses the standard moment.js relative time
	}
}
