
import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { ReplaySubject, tap, catchError, throwError, lastValueFrom } from 'rxjs';

import { AuthService } from './auth.service';
import { GlobalFiltersService } from './global-filters.service';

import { LeagueAgreementViewModel } from '../models/agreement.models';

import { handleHttpError, convertRandomDateTimeToISOString } from '../../functions';
import { Environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class AgreementsService {
	private readonly baseUrl = `${Environment.apiBaseUrl}/agreements`;

	private httpClient: HttpClient = inject(HttpClient);
	private authService: AuthService = inject(AuthService);
	private globalFiltersService: GlobalFiltersService = inject(GlobalFiltersService);

	getLeagueAgreement(leagueID: number) {
		const httpParams = new HttpParams().appendAll({
			leagueId: leagueID,
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.get<LeagueAgreementViewModel>(
					`${this.baseUrl}/league`,
					{ params: httpParams })
				.pipe(
					tap(agreement => {
						if (!agreement) return;

						agreement.dateSubmitted = convertRandomDateTimeToISOString(agreement.dateSubmitted);
					}),
					catchError(handleHttpError)
				)
		);
	}

	submitLeagueAgreement(leagueID: number, fileID: number, esignature: string) {
		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.post<LeagueAgreementViewModel>(
					`${this.baseUrl}/league/submit`,
					{
						leagueId: leagueID,
						leagueYearId: this.globalFiltersService.getLeagueYearID(),
						fileId: fileID,
						esignature: esignature
					},
					{ params: httpParams })
				.pipe(catchError(handleHttpError))
		);
	}
}
