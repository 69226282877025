import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { catchError, lastValueFrom, Observable } from 'rxjs';

import { Environment } from '@app/environment';
import { PagedModel, GlobalSearchModel } from '@app/shared/models';
import { handleHttpError } from '@app/root/app/functions';

@Injectable({
	providedIn: 'root'
})
export class GlobalSearchService {
	private baseUrl = `${Environment.apiBaseUrl}/search`;
	private httpClient: HttpClient = inject(HttpClient);

	getSearchResults$(
		searchText: string | null,
		includeLeagues: boolean,
		includeTeams: boolean,
		includePlayers: boolean,
		includeCoaches: boolean,
		includeDocuments: boolean,
		pageIndex: number,
		yearId: number | null,
		page = true,
		regionId: number | null = null,
	): Observable<PagedModel<GlobalSearchModel>> {
		const httpParams: HttpParams = new HttpParams().appendAll({
			regionId: regionId ?? '',
			yearId: yearId ?? '',
			page: page,
			pageIndex: pageIndex,
			pageSize: 50,
			searchText: searchText ?? '',
			includeLeagues: includeLeagues,
			includeTeams: includeTeams,
			includePlayers: includePlayers,
			includeCoaches: includeCoaches,
			includeDocuments: includeDocuments
		});

		return this.httpClient
			.get<
				PagedModel<GlobalSearchModel>
			>(`${this.baseUrl}`, { params: httpParams })
			.pipe(catchError(handleHttpError));
	}

	getSearchResults(
		searchText: string | null,
		includeLeagues: boolean,
		includeTeams: boolean,
		includePlayers: boolean,
		includeCoaches: boolean,
		includeDocuments: boolean,
		pageIndex: number,
		yearId: number | null,
		page = true,
		regionId: number | null = null,
	): Promise<PagedModel<GlobalSearchModel>> {
		return lastValueFrom(
			this.getSearchResults$(
				searchText,
				includeLeagues,
				includeTeams,
				includePlayers,
				includeCoaches,
				includeDocuments,
				pageIndex,
				yearId,
				page,
				regionId,
			),
		);
	}
}
