import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';

@Component({
	selector: 'app-error',
	templateUrl: 'error.component.html',
	styleUrl: 'error.component.scss',
	standalone: true,
	imports: [CommonModule, FontAwesomeModule]
})
export class ErrorComponent {
	errorIcon = faExclamationCircle;

	@Input() message!: string;
	@Input() showRetry = true;
	@Input() class: string = '';
	@Input() shortTemplate: boolean = false;
	// @Input() vertical: boolean = false;

	@Output() retry = new EventEmitter<void>();
}
