import { HttpParams, HttpErrorResponse } from '@angular/common/http';

import { throwError } from 'rxjs';

/**
 * Removes null/undefined params from HttpParams object.
 * @param params HttpParams object containing query parameters.
 * @returns Cleaned HttpParams object.
 */
export function removeEmptyParams(params: HttpParams) {
	return params.keys().reduce((queryParams: { [key: string]: any }, key) => {
		let value: any = params.get(key);

		if (!!value && value != 'undefined' && value != 'null') {
			queryParams[key] = value;
		}

		return queryParams;
	}, {});
}

/**
 * Handles HTTP errors, logs to Sentry, and returns the error observable.
 * @param error
 * @returns Error observable.
 */
export function handleHttpError(error: HttpErrorResponse) {
	console.error(error);
	// Sentry.captureException(error);
	return throwError(() => error);
}

/**
 * Generates a unique ID (not globally unique).
 * @returns Unique ID.
 */
export function generateUniqueId(): string {
	return 'id-' + Math.random().toString(36).substr(2, 9);
}

/**
 * Converts non-ISO-formatted date string to an ISO string.
 * (e.g. '2021-01-01 00:00:00' -> '2021-01-01T00:00:00Z')
 * (e.g. '01/01/2021 00:00:00' -> '2021-01-01T00:00:00Z')
 * @param date Non-ISO-formatted date string.
 * @returns ISO-formatted date string.
 */
export function convertRandomDateTimeToISOString(date: string | null) {
	if (!date || date === '0001-01-01T00:00:00' || date === '0001-01-01T12:00:00Z') {
		return null;
	}
	if (date.includes('/')) {
		// first split date from time
		let [datePart, timePart] = date.split(' ');
		// split date into parts
		let [month, day, year] = datePart.split('/');
		// reformat date
		date = `${year}-${month}-${day}`;
		// add time back
		date += `T${timePart}Z`;
	} else {
		date = date.replace(' ', 'T');
		if (!date.endsWith('Z')) {
			date += 'Z';
		}
	}
	return date;
}
