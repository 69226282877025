import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { CoachIconComponent } from '../entity-icons/coach-icon.component';
import { SystemConstants } from '../../../constants';

@Component({
	selector: 'app-coaches-badge',
	template: `
	<span class="badge" [ngClass]="[ hasWarning ? 'text-warning-emphasis bg-warning-subtle' : '', hasSuccess ? 'text-success-emphasis bg-success-subtle' : '', !hasWarning && !hasSuccess ? 'text-bg-light' : '', badgeClass ]" ngbTooltip="{{ tooltip }}"><app-coach-icon class="me-1" /> {{ coachCount | number }} @if (teamCount === 0 || teamCount) { / {{ (teamCount * SystemConstants.TeamCoachesMax) | number }} } @if (showLabel) { Coach{{ coachCount === 1 ? '' : 'es' }} }</span>
	`,
	standalone: true,
	imports: [CommonModule, FontAwesomeModule, NgbTooltipModule, CoachIconComponent]
})
export class CoachesBadgeComponent {
	@Input({ required: true }) coachCount!: number;
	@Input() teamCount?: number;
	@Input() hasWarning: boolean = false;
	@Input() hasSuccess: boolean = false;
	@Input() showLabel: boolean = false;
	@Input() tooltip: string = 'Number of coaches (current/possible)';
	@Input() badgeClass: string = '';

	SystemConstants = SystemConstants;
}
