import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'app-table-placeholder',
	templateUrl: 'table-placeholder.component.html',
	styleUrl: 'table-placeholder.component.scss',
	standalone: true,
	imports: [CommonModule]
})
export class TablePlaceholderComponent implements OnInit {
	@Input() columnsCount: number = 5;
	@Input() rowsCount: number = 3;
	@Input() isSmall: boolean = false;
	@Input() class?: string;

	ngOnInit() {
	}
}
