import { Component, input } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faEllipsis, faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';

@Component({
	selector: 'app-actions-icon',
	template: '<fa-icon [icon]="isVertical() ? verticalIcon : horizontalIcon" />',
	standalone: true,
	imports: [FontAwesomeModule]
})
export class ActionsIconComponent {
	// inputs
	isVertical = input<boolean>(false);

	horizontalIcon = faEllipsis;
	verticalIcon = faEllipsisVertical;
}
