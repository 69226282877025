import { Component } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';

@Component({
	selector: 'app-details-icon',
	template: '<fa-icon [icon]="icon" />',
	standalone: true,
	imports: [FontAwesomeModule]
})
export class DetailsIconComponent {
	icon = faChevronRight;
}
