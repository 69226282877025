<!-- flex-lg-row justify-content-lg-between -->
<div class="gap-2" [ngClass]="stackClass">
	<!-- justify-content-around -->
	@if (showIntake) {
	<button class="btn btn-primary flex-grow-1" [ngClass]="buttonSizeClass" type="button"
		[routerLink]="['/document-intake']"
		[queryParams]="{ leagueID: leagueID, leagueName: leagueName, teamID: teamID }" [disabled]="disabled">
		<fa-icon class="me-2" [icon]="intakeIcon" /> Document Intake
	</button>
	}

	@if (instructionsFileID) {
	<button class="btn btn-outline-dark border-0" [ngClass]="buttonSizeClass" type="button"
		ngbTooltip="Download form/instructions" (click)="onDownloadInstructions()"
		[disabled]="instructionsFileDownloading || disabled">
		@if (!instructionsFileDownloading) { <fa-icon [icon]="instructionsIcon" /> }
		@else { <app-spinner /> }
	</button>
	}

	@if (viewFileURL || viewFileID) {
	<div class="hstack justify-content-between gap-2">
		@if (showViewLabel && viewFileName) {
		<span class="fw-medium">{{ viewFileName }} @if (viewFileType) { ({{ viewFileType }}) }</span>
		}
		<button class="btn btn-outline-dark" [ngClass]="buttonSizeClass" type="button" ngbTooltip="View document"
			(click)="onView()" [disabled]="disabled"><app-view-icon /></button>
	</div>
	}

	@if (showUpload && leagueID) {
	<button class="btn btn-outline-dark" [ngClass]="buttonSizeClass" type="button"
		ngbTooltip="{{ uploadDocumentInstanceID ? 'Upload document' : 'Upload Document (any kind)' }}"
		(click)="anyFileInput.click()" [disabled]="disabled">
		<fa-icon [icon]="uploadIcon" />
		@if (uploadLabel) { <span class="ms-2">{{ uploadLabel }}</span> }
	</button>

	<!-- camera -->
	<!-- <input #cameraFileInput class="d-none" type="file" accept="image/*" capture="camera"
		(change)="onUploadFileInput($event)"> -->

	<!-- photo library -->
	<!-- <input #photoLibraryFileInput class="d-none" type="file" accept="image/*" multiple
		(change)="onUploadFileInput($event)" (click)="onResetFileInput(photoLibraryFileInput)"> -->

	<!-- any file (files) -->
	<input #anyFileInput class="d-none" type="file" multiple (change)="onUploadFileInput($event)"
		(click)="onResetFileInput(anyFileInput)" [disabled]="disabled">
	}
</div>
