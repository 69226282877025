import { Injectable, inject } from '@angular/core';

import { ReplaySubject, catchError, throwError, lastValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class GlobalFiltersService {
	// TODO: REMOVE REGIONS FROM GLOBAL FILTERS
	// private regionID: number | null = null;
	// private regionName: string | null = null;
	private leagueYearID: number | null = null;
	private leagueYearName: string | null = null;

	// private region$ = new ReplaySubject<{ regionID: number | null, regionName: string | null } | null>(1);
	private leagueYear$ = new ReplaySubject<{ leagueYearID: number | null, leagueYearName: string | null } | null>(1);

	// getRegionID() {
	// 	return this.regionID;
	// }
	// getRegionName() {
	// 	return this.regionName;
	// }

	getLeagueYearID() {
		return this.leagueYearID;
	}
	getLeagueYearName() {
		return this.leagueYearName;
	}

	// getRegion$() {
	// 	return this.region$.asObservable();
	// }
	getLeagueYear$() {
		return this.leagueYear$.asObservable();
	}

	// setRegion(regionID: number | null, regionName: string | null) {
	// 	this.regionID = regionID;
	// 	this.regionName = regionName;
	// 	this.region$.next({ regionID, regionName });
	// }
	setLeagueYear(leagueYearID: number | null, leagueYearName: string | null) {
		console.log('***Setting league year:', leagueYearID, leagueYearName);
		this.leagueYearID = leagueYearID;
		this.leagueYearName = leagueYearName;
		this.leagueYear$.next({ leagueYearID, leagueYearName });
	}
}
