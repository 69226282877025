import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'app-text-placeholder',
	templateUrl: 'text-placeholder.component.html',
	styleUrl: 'text-placeholder.component.scss',
	standalone: true,
	imports: [CommonModule]
})
export class TextPlaceholderComponent implements OnInit {
	@Input() bodyRowItemCounts: number[][] = [];
	@Input() class?: string;

	getNumbersArray(length: number) {
		return Array.from({ length: length }, (v, k) => k);
	}

	getRowItemPlaceholderWidth(index: number, ratio: number = 1.0) {
		// if (this.bodyRowItemCounts && this.bodyRowItemCounts.length > index) {
		// 	return `width: ${Math.round(1 / this.bodyRowItemCounts[index] * 100.0 * ratio)}%`;
		// }
		// return 'width: 100%';

		return `width: ${Math.round(100.0 * ratio)}%`;
	}

	ngOnInit() {
	}
}
