import { Component, inject, Input, numberAttribute } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { UsersService } from '@app/shared/services';
import { SpinnerComponent } from '@app/shared/components';
import { Messages } from '@app/constants';

@Component({
	templateUrl: './change-password.modal.html',
	styleUrl: './change-password.modal.scss',
	standalone: true,
	imports: [SpinnerComponent, ReactiveFormsModule, FormsModule],
})
export class ChangePasswordModal {
	// internal state
	@Input({ transform: numberAttribute }) userID!: number;
	passwordNew: string = '';
	passwordConfirm: string = '';
	passwordSaving: boolean = false;

	// services
	private activeModal = inject(NgbActiveModal);
	private toastrService: ToastrService = inject(ToastrService);
	private usersService: UsersService = inject(UsersService);

	async onChangePassword() {
		if (!this.isValidPassword()) {
			return;
		}

		try {
			this.passwordSaving = true;

			await this.usersService.changeUserPassword(this.userID, this.passwordNew);

			this.toastrService.success(`The password has been changed.`);
			this.activeModal.close();
		} catch (error) {
			this.toastrService.error(Messages.ErrorRetry, `Failed to save new password.`);
		} finally {
			this.passwordSaving = false;
		}
	}

	isValidPassword(): boolean {
		return this.passwordNew !== '' && this.passwordNew === this.passwordConfirm;
	}

	onCancel() {
		this.activeModal.dismiss();
	}
}
