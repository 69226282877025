
import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { ReplaySubject, tap, catchError, throwError, lastValueFrom } from 'rxjs';

import { AuthService } from './auth.service';
import { GlobalFiltersService } from './global-filters.service';

import { TournamentAppListViewModel, TournamentAppDetailsViewModel, TournamentAppEditModel } from '../models/tournament.models';
import { TournamentApplicationStatusCodes } from '../../constants';
import { handleHttpError, convertRandomDateTimeToISOString } from '../../functions';
import { Environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class TournamentsService {
	private readonly baseUrl = `${Environment.apiBaseUrl}/tournaments`;

	private httpClient: HttpClient = inject(HttpClient);
	private authService: AuthService = inject(AuthService);
	private globalFiltersService: GlobalFiltersService = inject(GlobalFiltersService);

	getLeagueTournamentApps(leagueID: number) {
		console.log(`LOADING TOURNAMENT APPS => ${this.globalFiltersService.getLeagueYearID() || 'NULL'}...`);

		const httpParams = new HttpParams().appendAll({
			leagueId: leagueID,
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.get<TournamentAppListViewModel[]>(
					`${this.baseUrl}`,
					{ params: httpParams })
				.pipe(
					tap(apps => {
						apps.forEach(app => {
							app.dateFinalSubmission = convertRandomDateTimeToISOString(app.dateFinalSubmission);
						});
					}),
					catchError(handleHttpError)
				)
		);
	}

	getLeagueTournamentAppDetails(tournamentAppID: number) {
		console.log(`LOADING TOURNAMENT APP DETAILS => ${this.globalFiltersService.getLeagueYearID() || 'NULL'}...`);

		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.get<TournamentAppDetailsViewModel>(
					`${this.baseUrl}/${tournamentAppID}`,
					{ params: httpParams })
				.pipe(
					tap(details => this.computeTournamentAppDetailsFields(details)),
					catchError(handleHttpError)
				)
		);
	}

	submitLeagueTournamentApp(tournamentApp: TournamentAppEditModel) {
		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		const app: any = { ...tournamentApp };
		app.divisionsMap = undefined;

		return lastValueFrom(
			this.httpClient
				.post<TournamentAppDetailsViewModel>(
					`${this.baseUrl}/submit`,
					app,
					{ params: httpParams })
				.pipe(
					tap(details => this.computeTournamentAppDetailsFields(details)),
					catchError(handleHttpError)
				)
		);
	}

	approveLeagueTournamentApp(tournamentAppID: number) {
		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.post<TournamentAppDetailsViewModel>(
					`${this.baseUrl}/${tournamentAppID}/approve`,
					null,
					{ params: httpParams })
				.pipe(
					tap(details => this.computeTournamentAppDetailsFields(details)),
					catchError(handleHttpError)
				)
		);
	}

	rejectLeagueTournamentApp(tournamentAppID: number, reason: string) {
		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? '',
			rejectionNotes: reason
		});

		return lastValueFrom(
			this.httpClient
				.post<TournamentAppDetailsViewModel>(
					`${this.baseUrl}/${tournamentAppID}/reject`,
					null,
					{ params: httpParams })
				.pipe(
					tap(details => this.computeTournamentAppDetailsFields(details)),
					catchError(handleHttpError)
				)
		);
	}

	private computeTournamentAppDetailsFields(details: TournamentAppDetailsViewModel) {
		if (!details) return;

		details.regularSeasonStartDate = this.removeTimeFromDate(details.regularSeasonStartDate ? new Date(details.regularSeasonStartDate).toISOString() : undefined);

		details.dateFinalSubmission = convertRandomDateTimeToISOString(details.dateFinalSubmission);

		if (!details.tournamentAppId) {
			details.statusID = TournamentApplicationStatusCodes.Unknown;
			details.statusName = 'Not Submitted';
		} else if (!details.dateFinalSubmission) {
			details.statusID = TournamentApplicationStatusCodes.Draft;
			details.statusName = 'Not Submitted';
		} else if (details.approved === false && details.verdictDate && details.verdictNotes) {
			details.statusID = TournamentApplicationStatusCodes.Rejected;
			details.statusName = 'Rejected';
		} else if (details.approved === true && details.verdictDate) {
			details.statusID = TournamentApplicationStatusCodes.Approved;
			details.statusName = 'Approved';
		} else if (details.dateFinalSubmission) {
			details.statusID = TournamentApplicationStatusCodes.InProgress;
			details.statusName = 'Submitted';
		} else {
			details.statusID = TournamentApplicationStatusCodes.Unknown;
			details.statusName = 'Unknown';
		}
	}

	private removeTimeFromDate(date?: string) {
		if (!date) {
			return date;
		}
		return date.split('T')?.[0];
	}
}
