import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { ReplaySubject, map, filter, tap, catchError, throwError, lastValueFrom } from 'rxjs';

import { AuthService } from './auth.service';
import { GlobalFiltersService } from './global-filters.service';

import { AnnouncementViewModel, AnnouncementEditModel } from '../models/announcement.models';

import { handleHttpError, removeEmptyParams } from '../../functions';
import { Environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class AnnouncementsService {
	private readonly baseUrl = `${Environment.apiBaseUrl}/announcements`;

	private httpClient: HttpClient = inject(HttpClient);
	private authService: AuthService = inject(AuthService);
	private globalFiltersService: GlobalFiltersService = inject(GlobalFiltersService);

	getActiveAnnouncements() {
		return lastValueFrom(
			this.httpClient
				.get<AnnouncementViewModel[]>(
					`${this.baseUrl}`)
				.pipe(
					map(announcements => announcements.filter(announcement =>
						!localStorage.getItem(`dismissed-announcement-${announcement.announcementID}`)
					)),
					tap(announcements => announcements.forEach(announcement => this.cleanMessage(announcement))),
					catchError(handleHttpError)
				)
		);
	}

	getAllAnnouncements() {
		return lastValueFrom(
			this.httpClient
				.get<AnnouncementViewModel[]>(
					`${this.baseUrl}/all`)
				.pipe(
					tap(announcements => announcements.forEach(announcement => this.cleanMessage(announcement))),
					catchError(handleHttpError)
				)
		);
	}

	getAnnouncementDetails(announcementID: number) {
		return lastValueFrom(
			this.httpClient
				.get<AnnouncementViewModel>(
					`${this.baseUrl}/${announcementID}`)
				.pipe(catchError(handleHttpError))
		);
	}

	createAnnouncement(announcement: AnnouncementEditModel) {
		return lastValueFrom(
			this.httpClient
				.post<AnnouncementViewModel>(`${this.baseUrl}`, announcement)
				.pipe(catchError(handleHttpError))
		);
	}

	updateAnnouncement(announcementID: number, announcement: AnnouncementEditModel) {
		return lastValueFrom(
			this.httpClient
				.put<AnnouncementViewModel>(`${this.baseUrl}/${announcementID}`, announcement)
				.pipe(catchError(handleHttpError))
		);
	}

	deleteAnnouncement(announcementID: number) {
		return lastValueFrom(
			this.httpClient
				.delete<AnnouncementViewModel>(`${this.baseUrl}/${announcementID}`)
				.pipe(catchError(handleHttpError))
		);
	}

	dismissAnnouncement(announcementID: number) {
		localStorage.setItem(`dismissed-announcement-${announcementID}`, 'true');
	}

	private cleanMessage(announcement: AnnouncementViewModel) {
		if (!announcement.message) return;

		announcement.message = announcement.message.replace(
			/<p>\s*<span\s+style="[^"]*">\s*(&nbsp;|\s)*\s*<\/span>\s*<\/p>/gm,
			'<p class="p-newline"><span>&nbsp;</span></p>');
	}

	private tmpAlerts = [
		{
			alertID: 1,
			typeID: 1,
			typeName: 'success',
			title: '2023 MLB Draft',
			message: 'Please let us know if any of your current or former athletes have been selected in this year\'s MLB Draft as we would like to find ways to highlight both them and their respective program during this special moment. If so, please share with us their name, round, pick, and team they were selected by in addition to the years in which they participated in your program.',
			dismissable: true
		},
		{
			alertID: 2,
			typeID: 2,
			typeName: 'warning',
			title: '2023 & 2024 Nike RBI Uniform Ordering',
			message: '2023 & 2024 Nike RBI Uniform Survey: <a href="https://mlb.az1.qualtrics.com/jfe/form/SV_0dEjawlh1BSy3s2" target="_blank">https://mlb.az1.qualtrics.com/jfe/form/SV_0dEjawlh1BSy3s2</a>',
			dismissable: true
		},
		{
			alertID: 3,
			typeID: 3,
			typeName: 'danger',
			title: 'IMPORTANT NIKE RBI REGIONAL TOURNAMENT DEADLINE: MONDAY MAY 8TH 2023',
			message: `Submitted your Application for Affiliation via the RPM<br/>
			Submitted your Tournament Application via the RPM - login to the RPM, click your organization name in blue, click the tournament tab at the top, and then complete the three (3) question application<br/>
			Returned a signed copy of the attached Tournament Compliance document for your program to<br/>
			Returned a signed copy of MLB\'s Youth Program Protection Policy - Summary for Authorized Adults for your program to`,
			dismissable: false
		}
	];
}
