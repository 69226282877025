import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter, OnDestroy, inject, booleanAttribute } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// import { faFileImport, faSquareInfo, faArrowUp } from '@fortawesome/pro-regular-svg-icons';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { RequiredIconComponent } from '../action-icons/required-icon.component';
import { InfoIconComponent } from '../action-icons/info-icon.component';
import { StatusIconComponent, StatusTypes } from '../status-icon/status-icon.component';
import { DocumentActionsComponent } from '../document-actions/document-actions.component';

import { TeamDocumentViewModel } from '../../models/team.models';
import { EntityDocumentViewModel } from '../../models/document.models';
import { DocumentStatusCodes, DocumentStatusCardColors } from '@app/constants';


@Component({
	selector: 'app-document-card',
	templateUrl: './document-card.component.html',
	styleUrl: './document-card.component.scss',
	standalone: true,
	imports: [
		CommonModule, RouterModule,
		FontAwesomeModule, NgbTooltipModule,
		RequiredIconComponent, InfoIconComponent, StatusIconComponent, DocumentActionsComponent
	]
})
export class DocumentCardComponent implements OnDestroy {
	@Input({ required: true }) document!: TeamDocumentViewModel;

	@Input() titleType: 'doc' | 'entity' | 'status' = 'doc';
	@Input() showUpload = true;
	@Input() uploadLabel = '';
	@Input({ transform: booleanAttribute }) showInlineStatusNotes = false;

	@Input() buttonSizeClass = 'btn-xs';
	@Input() cardless = false;

	// TODO: CHANGE TO EntityDocumentViewModel ONCE API RETURNS THAT
	@Output() uploaded = new EventEmitter<number>();
	@Output() approved = new EventEmitter<EntityDocumentViewModel>();
	@Output() rejected = new EventEmitter<EntityDocumentViewModel>();

	// internal state

	// services

	// icons

	// constants
	DocumentStatusCodes = DocumentStatusCodes;
	DocumentStatusCardColors = DocumentStatusCardColors;
	StatusTypes = StatusTypes;

	ngOnDestroy() {

	}
}
