import { Component } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFile } from '@fortawesome/pro-regular-svg-icons';

@Component({
	selector: 'app-doc-icon',
	template: '<fa-icon [icon]="icon" />',
	standalone: true,
	imports: [FontAwesomeModule]
})
export class DocumentIconComponent {
	icon = faFile;
}
