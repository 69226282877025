import { Component } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCalendars } from '@fortawesome/pro-regular-svg-icons';

@Component({
	selector: 'app-years-icon',
	template: '<fa-icon [icon]="icon" />',
	standalone: true,
	imports: [FontAwesomeModule]
})
export class YearsIconComponent {
	icon = faCalendars;
}
