import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter, OnInit, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { Observable, of, tap, catchError, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { SpinnerComponent } from '../../loaders/spinner/spinner.component';
import { CardPlaceholderComponent } from '../../loaders/card-placeholder/card-placeholder.component';
import { EmptyComponent } from '../../empty/empty.component';
import { ErrorComponent } from '../../error/error.component';
import { VerticalLineComponent } from '../../vline/vline.component';
import { DocumentCardComponent } from '../../document-card/document-card.component';
import { InlineEditComponent, InlineEditSaveEvent } from '../../inline-edit/inline-edit.component';

import { ConfirmationService } from '../../../services/confirmation.service';
import { LookupService } from '../../../services/lookup.service';
import { PlayersService } from '../../../services/players.service';
import { TeamsService } from '../../../services/teams.service';
import { LookupView } from '../../../models/lookup.models';
import { PlayerListViewModel } from '../../../models/player.models';
import { EntityDocumentViewModel } from '../../../models/document.models';
import { LookupCodes, Messages } from '@app/constants';
import { CloseIconComponent } from '@app/root/app/shared/components';


@Component({
	selector: 'app-player-card',
	templateUrl: './player-card.component.html',
	styleUrl: './player-card.component.scss',
	standalone: true,
	imports: [
		CommonModule, FormsModule,
		SpinnerComponent, CardPlaceholderComponent, EmptyComponent, ErrorComponent, VerticalLineComponent,
		DocumentCardComponent,
		InlineEditComponent, CloseIconComponent,
	]
})
export class PlayerCardComponent implements OnInit {
	@Input({ required: true }) player!: PlayerListViewModel;
	@Input() readOnly = false;
	@Input() class = '';
	@Output() playerChange = new EventEmitter<PlayerListViewModel>();
	@Output() playerRemoved = new EventEmitter<PlayerListViewModel>();

	// internal state
	playerSaving = false;

	docs: EntityDocumentViewModel[] | null = null;
	docsLoading = false;
	docsError = false;

	// services
	private toastrService: ToastrService = inject(ToastrService);
	private confirmationService: ConfirmationService = inject(ConfirmationService);
	private lookupService: LookupService = inject(LookupService);
	private playersService: PlayersService = inject(PlayersService);
	private teamsService: TeamsService = inject(TeamsService);

	// lookups
	gendersLookup$!: Observable<LookupView[]>;
	gendersLookupLoading = true;

	ethnicitiesLookup$!: Observable<LookupView[]>;
	ethnicitiesLookupLoading = true;

	countriesLookup$!: Observable<LookupView[]>;
	countriesLookupLoading = true;

	statesLookup$: Observable<LookupView[]> = of([]);
	statesLookupLoading = true;

	handednessLookup$!: Observable<LookupView[]>;
	handednessLookupLoading = true;

	positionsLookup$!: Observable<LookupView[]>;
	positionsLookupLoading = true;

	constructor() {
		this.initializeLookups();
	}

	private initializeLookups() {
		this.gendersLookup$ = this.lookupService.getLookup<LookupView>(LookupCodes.Genders)
			.pipe(
				tap(() => setTimeout(() => this.gendersLookupLoading = false, 0)),
				catchError(err => {
					setTimeout(() => this.gendersLookupLoading = false, 0);
					return this.showLookupErrorToast('Genders', err);
				})
			);

		this.ethnicitiesLookup$ = this.lookupService.getLookup<LookupView>(LookupCodes.Ethnicities)
			.pipe(
				tap(() => setTimeout(() => this.ethnicitiesLookupLoading = false, 0)),
				catchError(err => {
					setTimeout(() => this.ethnicitiesLookupLoading = false, 0);
					return this.showLookupErrorToast('Ethnicities', err);
				})
			);

		this.countriesLookup$ = this.lookupService.getLookup<LookupView>(LookupCodes.Countries)
			.pipe(
				tap(() => setTimeout(() => this.countriesLookupLoading = false, 0)),
				catchError(err => {
					setTimeout(() => this.countriesLookupLoading = false, 0);
					return this.showLookupErrorToast('Countries', err);
				})
			);

		this.handednessLookup$ = this.lookupService.getLookup<LookupView>(LookupCodes.Handedness)
			.pipe(
				tap(() => setTimeout(() => this.handednessLookupLoading = false, 0)),
				catchError(err => {
					setTimeout(() => this.handednessLookupLoading = false, 0);
					return this.showLookupErrorToast('Bats/Throws', err);
				})
			);

		this.positionsLookup$ = this.lookupService.getLookup<LookupView>(LookupCodes.Positions)
			.pipe(
				tap(() => setTimeout(() => this.positionsLookupLoading = false, 0)),
				catchError(err => {
					setTimeout(() => this.positionsLookupLoading = false, 0);
					return this.showLookupErrorToast('Positions', err);
				})
			);
	}

	private reinitializeStatesLookup(countryId: number | null | undefined) {
		if (!countryId) {
			this.statesLookup$ = of([]);
		} else {
			this.statesLookup$ = this.lookupService.getLookup<LookupView>(LookupCodes.States, countryId)
				.pipe(
					tap(() => setTimeout(() => this.statesLookupLoading = false, 0)),
					catchError(err => {
						setTimeout(() => this.statesLookupLoading = false, 0);
						return this.showLookupErrorToast('States', err);
					})
				);
		}
	}

	private showLookupErrorToast(lookupName: string, error: any) {
		this.toastrService.error(Messages.ErrorRetry, `Failed to Load '${lookupName}'`);
		return throwError(() => error);
	}

	ngOnInit() {
		this.reinitializeStatesLookup(this.player?.countryId);
		this.onLoadPlayerDocuments();
	}

	async onLoadPlayerDocuments() {
		if (this.docsLoading) return;

		this.docsLoading = true;
		this.docsError = false;

		try {
			this.docs = await this.playersService.getPlayerDocuments(this.player.playerTeamId);
		} catch (error) {
			this.docsError = true;
		} finally {
			this.docsLoading = false;
		}
	}

	async onSavePlayerChange(event: InlineEditSaveEvent, property: keyof PlayerListViewModel) {
		try {
			// decide whether need to reinitialize states lookup
			const needToClearState = property === 'countryId' && this.player?.countryId != event.updatedValue;

			await this.updatePlayer({
				...this.player,
				[property]: !!event.updatedValue ? event.updatedValue : null,
				// // clear currently selected state if country is changing
				// stateId: needToClearState ? 0 : this.leagueDetails?.stateId
			});

			if (needToClearState) {
				this.reinitializeStatesLookup(this.player?.countryId);
			}

			event.savedFunc?.(true);
		} catch (error: any) {
			event.savedFunc?.(false);
			this.toastrService.error(`Please try again (${property}).`, 'Failed to Save Player Changes');
		}
	}

	private async updatePlayer(updatedPlayer: PlayerListViewModel) {
		if (this.playerSaving) return;

		try {
			this.playerSaving = true;

			this.player = await this.playersService.updatePlayer(updatedPlayer);
			this.playerChange.emit(this.player);

			this.toastrService.success(`Player has been updated.`);
		} finally {
			this.playerSaving = false;
		}
	}

	async onRemovePlayer() {
		this.confirmationService.confirm(
			'Remove Player from Team?',
			`Are you sure you want to remove <strong>${this.player.fullName}</strong> player from the team?`,
			'Remove Player',
			'btn-danger',
			async () => {
				this.player.removingFromTeam = true;

				try {
					const removedPlayer = await this.teamsService.removePlayerFromTeam(this.player.teamId, this.player.playerTeamId);
					this.playerRemoved.emit(removedPlayer);

					this.toastrService.success('Player removed from the team.');

					return true;
				} catch (error) {
					this.toastrService.error(Messages.ErrorRetry, 'Failed to Remove Player.');

					return false;
				} finally {
					this.player.removingFromTeam = false;
				}
			}
		);
	}
}
