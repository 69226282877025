import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, DestroyRef, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { Observable, Subscription, skip, catchError, tap, throwError } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';

import { SpinnerComponent } from '../../loaders/spinner/spinner.component';
import { LookupService } from '../../../services/lookup.service';
import { AuthService } from '../../../services/auth.service';
import { LookupView } from '../../../models/lookup.models';
import { LookupCodes, Messages, AppPermissionCodes } from '../../../../constants';

@Component({
	templateUrl: 'site-picker.modal.html',
	styleUrl: 'site-picker.modal.scss',
	standalone: true,
	imports: [CommonModule, FormsModule, NgSelectModule, SpinnerComponent]
})
export class SitePickerModal implements OnInit {
	// inputs
	@Input() title: string = '??????';

	@Input() actionTitle: string = '?????';
	@Input() actionButtonClass: string = 'btn-primary';
	@Input() actionFunc?: (leagueID: number) => Promise<boolean>;

	@Input() exclusionSiteIDs: number[] = [];

	// output variables
	confirmed: boolean = false;

	// internal state
	selectedSiteID: number | null = null;
	protected inProgress: boolean = false;

	// services
	private destroyRef: DestroyRef = inject(DestroyRef);
	private activeModal = inject(NgbActiveModal);
	private toastrService: ToastrService = inject(ToastrService);
	protected authService: AuthService = inject(AuthService);
	private lookupService: LookupService = inject(LookupService);

	// lookups
	filteredSitesLookup: LookupView[] = [];
	sitesLookupLoading = true;

	// constants
	AppPermissionCodes = AppPermissionCodes;

	constructor() {
		this.initializeLookups();
	}

	private initializeLookups() {
		this.lookupService.getLookup<LookupView>(LookupCodes.LeagueAppsSites)
			.pipe(
				tap(sites => {
					console.log('exclusion sites', this.exclusionSiteIDs);
					this.filteredSitesLookup = sites.filter(site => !this.exclusionSiteIDs.includes(site.id));
					setTimeout(() => this.sitesLookupLoading = false, 0);
				}),
				catchError(err => {
					setTimeout(() => this.sitesLookupLoading = false, 0);
					return this.showLookupErrorToast('Sites', err);
				}),
				takeUntilDestroyed(this.destroyRef),
			).subscribe();
	}

	private showLookupErrorToast(lookupName: string, error: any) {
		this.toastrService.error(Messages.ErrorRetry, `Failed to Load '${lookupName}'`);
		return throwError(() => error);
	}

	ngOnInit() {
	}

	async onConfirm() {
		if (!this.selectedSiteID) {
			return;
		}

		if (!this.actionFunc) {
			this.confirmed = true;
			this.activeModal.close();
			return;
		}

		try {
			this.inProgress = true;
			const success = await this.actionFunc(this.selectedSiteID);
			if (success) {
				this.confirmed = true;
				this.activeModal.close();
			} else {
				this.confirmed = false;
			}
		} catch (error: any) {
			this.confirmed = false;
		} finally {
			this.inProgress = false;
		}
	}

	onCancel() {
		this.activeModal.dismiss();
	}
}
