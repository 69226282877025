import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { ReplaySubject, tap, catchError, throwError, lastValueFrom } from 'rxjs';

import { AuthService } from './auth.service';
import { GlobalFiltersService } from './global-filters.service';

import { PagedModel } from '../models/paged.model';
import {
	ForumPostViewModel,
	ForumPostEditModel,
	ForumCommentViewModel,
	ForumCommentEditModel
} from '../models/forum.models';

import { handleHttpError, removeEmptyParams } from '../../functions';
import { Environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class ForumService {
	private readonly baseUrl = `${Environment.apiBaseUrl}/forum`;

	private httpClient: HttpClient = inject(HttpClient);
	private authService: AuthService = inject(AuthService);
	private globalFiltersService: GlobalFiltersService = inject(GlobalFiltersService);

	// post endpoints
	getPosts(searchText: string | null, sortCode: string | null, pageNumber: number) {
		return lastValueFrom(this.getPosts$(searchText, sortCode, pageNumber));
	}

	getPosts$(searchText: string | null, sortCode: string | null, pageNumber: number) {
		const httpParams = new HttpParams().appendAll({
			searchText: searchText ?? '',
			sortCode: sortCode ?? '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? '',
			pageNumber: pageNumber.toString(),
		});

		return this.httpClient
			.get<PagedModel<ForumPostViewModel>>(`${this.baseUrl}/posts`, { params: httpParams })
			.pipe(
				tap(response => response.pageData.forEach(post => this.computeForumPostFields(post))),
				catchError(handleHttpError)
			);
	}

	getPostDetails(postID: number) {
		return lastValueFrom(
			this.httpClient
				.get<ForumPostViewModel>(`${this.baseUrl}/posts/${postID}`)
				.pipe(
					tap(post => this.computeForumPostFields(post)),
					catchError(handleHttpError)
				)
		);
	}

	createPost(post: ForumPostEditModel) {
		return lastValueFrom(
			this.httpClient
				.post<ForumPostViewModel>(`${this.baseUrl}/posts`, post)
				.pipe(
					tap(post => this.computeForumPostFields(post)),
					catchError(handleHttpError)
				)
		);
	}

	updatePost(postID: number, post: ForumPostEditModel) {
		return lastValueFrom(
			this.httpClient
				.put<ForumPostViewModel>(`${this.baseUrl}/posts/${postID}`, post)
				.pipe(
					tap(post => this.computeForumPostFields(post)),
					catchError(handleHttpError)
				)
		);
	}

	likePost(postID: number) {
		return lastValueFrom(
			this.httpClient
				.put<ForumPostViewModel>(`${this.baseUrl}/posts/${postID}/like`, null)
				.pipe(
					tap(post => this.computeForumPostFields(post)),
					catchError(handleHttpError)
				)
		);
	}

	unlikePost(postID: number) {
		return lastValueFrom(
			this.httpClient
				.put<ForumPostViewModel>(`${this.baseUrl}/posts/${postID}/unlike`, null)
				.pipe(
					tap(post => this.computeForumPostFields(post)),
					catchError(handleHttpError)
				)
		);
	}

	pinPost(postID: number) {
		return lastValueFrom(
			this.httpClient
				.put<ForumPostViewModel>(`${this.baseUrl}/posts/${postID}/pin`, null)
				.pipe(
					tap(post => this.computeForumPostFields(post)),
					catchError(handleHttpError)
				)
		);
	}

	unpinPost(postID: number) {
		return lastValueFrom(
			this.httpClient
				.put<ForumPostViewModel>(`${this.baseUrl}/posts/${postID}/unpin`, null)
				.pipe(
					tap(post => this.computeForumPostFields(post)),
					catchError(handleHttpError)
				)
		);
	}

	reportPost(postID: number) {
		return lastValueFrom(
			this.httpClient
				.put<ForumPostViewModel>(`${this.baseUrl}/posts/${postID}/report`, null)
				.pipe(
					tap(post => this.computeForumPostFields(post)),
					catchError(handleHttpError)
				)
		);
	}

	deletePost(postID: number) {
		return lastValueFrom(
			this.httpClient
				.delete<void>(`${this.baseUrl}/posts/${postID}`)
				.pipe(catchError(handleHttpError))
		);
	}

	// comment endpoints
	getPostComments(postID: number) {
		const httpParams = new HttpParams().appendAll({
			// dir: dir ?? '',
		});

		return lastValueFrom(
			this.httpClient
				.get<ForumCommentViewModel[]>(`${this.baseUrl}/posts/${postID}/comments`, { params: httpParams })
				.pipe(catchError(handleHttpError))
		);
	}

	createPostComment(comment: ForumCommentEditModel, postID: number) {
		return lastValueFrom(
			this.httpClient
				.post<ForumCommentViewModel>(`${this.baseUrl}/posts/${postID}/comments`, comment)
				.pipe(catchError(handleHttpError))
		);
	}

	updatePostComment(commentID: number, comment: ForumCommentEditModel, postID: number) {
		return lastValueFrom(
			this.httpClient
				.put<ForumCommentViewModel>(`${this.baseUrl}/posts/${postID}/comments/${commentID}`, comment)
				.pipe(catchError(handleHttpError))
		);
	}

	likePostComment(commentID: number, postID: number) {
		return lastValueFrom(
			this.httpClient
				.put<ForumCommentViewModel>(`${this.baseUrl}/posts/${postID}/comments/${commentID}/like`, null)
				.pipe(catchError(handleHttpError))
		);
	}

	unlikePostComment(commentID: number, postID: number) {
		return lastValueFrom(
			this.httpClient
				.put<ForumCommentViewModel>(`${this.baseUrl}/posts/${postID}/comments/${commentID}/unlike`, null)
				.pipe(catchError(handleHttpError))
		);
	}

	reportPostComment(commentID: number, postID: number) {
		return lastValueFrom(
			this.httpClient
				.put<ForumCommentViewModel>(`${this.baseUrl}/posts/${postID}/comments/${commentID}/report`, null)
				.pipe(catchError(handleHttpError))
		);
	}

	deletePostComment(commentID: number, postID: number) {
		return lastValueFrom(
			this.httpClient
				.delete<any>(`${this.baseUrl}/posts/${postID}/comments/${commentID}`)
				.pipe(catchError(handleHttpError))
		);
	}

	private computeForumPostFields(post: ForumPostViewModel) {
		// add .p-newline class to empty paragraphs to remove extra bottom margins that are part of bootstrap
		post.content = post.content.replace(
			/<p>\s*<span\s+style="[^"]*">\s*(&nbsp;|\s)*\s*<\/span>\s*<\/p>/gm,
			'<p class="p-newline"><span>&nbsp;</span></p>');
	}
}
