<div class="placeholder-glow" [ngClass]="class">
	@if (bodyRowItemCounts.length) {
	@for (bodyItemCounts of bodyRowItemCounts; track bodyItemCounts; let isLastBody = $last) {
	<div [class.border-bottom]="!isLastBody">
		@if (bodyItemCounts.length) {
		@for (row of getNumbersArray(bodyItemCounts.length); track row; let isLast = $last) {
		<div class="row" [class.mb-4]="!isLast">
			@for (col of getNumbersArray(bodyItemCounts[row]); track col) {
			<div class="col">
				<!-- <div [style]="getRowItemPlaceholderWidth(row, 0.7)" class="placeholder d-block"></div> -->
				<div [style]="getRowItemPlaceholderWidth(row)" class="placeholder d-block"></div>
			</div>
			}
		</div>
		}
		}
	</div>
	}
	}
</div>
