import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, inject, booleanAttribute } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
	faCircleExclamation
} from '@fortawesome/pro-regular-svg-icons';

import { GlobalFiltersService } from '../../../services/global-filters.service';
import { AnnouncementsService } from '../../../services/announcements.service';

import { ErrorComponent } from '../../../components/error/error.component';

import { AnnouncementViewModel } from '../../../models/announcement.models';

@Component({
	selector: 'app-announcements',
	templateUrl: './announcements.component.html',
	styleUrl: './announcements.component.scss',
	standalone: true,
	imports: [
		CommonModule, FontAwesomeModule, ErrorComponent
	]
})
export class AnnouncementsComponent implements OnInit {
	@Input({ transform: booleanAttribute }) showLoader = false;

	announcementIcon = faCircleExclamation;

	announcements: AnnouncementViewModel[] | null = null;
	announcementsLoading = false;
	announcementsError = false;

	globalFiltersService: GlobalFiltersService = inject(GlobalFiltersService);
	private announcementsService: AnnouncementsService = inject(AnnouncementsService);

	ngOnInit() {
		this.onLoadAnnouncements();
	}

	async onLoadAnnouncements() {
		try {
			this.announcementsLoading = true;
			this.announcementsError = false;

			this.announcements = await this.announcementsService.getActiveAnnouncements();
		} catch (error) {
			this.announcementsError = true;
		} finally {
			this.announcementsLoading = false;
		}
	}

	onDismissAnnouncement(a: AnnouncementViewModel) {
		this.announcementsService.dismissAnnouncement(a.announcementID);
	}
}
