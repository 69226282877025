import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter, OnInit, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';

import { SpinnerComponent } from '../../loaders/spinner/spinner.component';
import { CardPlaceholderComponent } from '../../loaders/card-placeholder/card-placeholder.component';
import { EmptyComponent } from '../../empty/empty.component';
import { ErrorComponent } from '../../error/error.component';
import { DocumentCardComponent } from '../../document-card/document-card.component';
import { InlineEditComponent, InlineEditSaveEvent } from '../../inline-edit/inline-edit.component';
import { ConfirmationService } from '../../../services/confirmation.service';
import { CoachesService } from '../../../services/coaches.service';
import { CoachListViewModel } from '../../../models/coach.models';
import { EntityDocumentViewModel } from '../../../models/document.models';
import { Messages } from '@app/constants';
import { CloseIconComponent } from '@app/root/app/shared/components';

@Component({
	selector: 'app-coach-card',
	templateUrl: './coach-card.component.html',
	styleUrl: './coach-card.component.scss',
	standalone: true,
	imports: [
		CommonModule, FormsModule,
		SpinnerComponent, CardPlaceholderComponent, EmptyComponent, ErrorComponent,
		DocumentCardComponent, InlineEditComponent, CloseIconComponent,
	]
})
export class CoachCardComponent implements OnInit {
	@Input({ required: true }) coach!: CoachListViewModel;
	@Input() readOnly = false;
	@Output() coachChange = new EventEmitter<CoachListViewModel>();
	@Output() coachRemoved = new EventEmitter<CoachListViewModel>();

	// internal state
	coachSaving = false;

	docs: EntityDocumentViewModel[] | null = null;
	docsLoading = false;
	docsError = false;

	// services
	private toastrService: ToastrService = inject(ToastrService);
	private confirmationService: ConfirmationService = inject(ConfirmationService);
	private coachesService: CoachesService = inject(CoachesService);

	ngOnInit() {
		this.onLoadCoachDocuments();
	}

	async onLoadCoachDocuments() {
		if (this.docsLoading) return;

		this.docsLoading = true;
		this.docsError = false;

		try {
			this.docs = await this.coachesService.getCoachDocuments(this.coach.coachId);
		} catch (error) {
			this.docsError = true;
		} finally {
			this.docsLoading = false;
		}
	}

	async onSaveCoachChange(event: InlineEditSaveEvent, property: keyof CoachListViewModel) {
		try {
			// // cast boolean values
			// if (event.updatedValue === 'true') {
			// 	event.updatedValue = true;
			// } else if (event.updatedValue === 'false') {
			// 	event.updatedValue = false;
			// }

			// // decide whether need to reinitialize states lookup
			// const needToClearState = property === 'countryId' && this.leagueDetails?.countryId != event.updatedValue;

			await this.updateCoach({
				...this.coach,
				[property]: !!event.updatedValue ? event.updatedValue : null,
				// // clear currently selected state if country is changing
				// stateId: needToClearState ? 0 : this.leagueDetails?.stateId
			});

			// if (needToClearState) {
			// 	this.reinitializeStatesLookup(this.leagueDetails?.countryId);
			// }

			event.savedFunc?.(true);
		} catch (error: any) {
			event.savedFunc?.(false);
			this.toastrService.error(`Please try again (${property}).`, 'Failed to Save Coach Changes');
		}
	}

	private async updateCoach(updatedCoach: CoachListViewModel) {
		if (this.coachSaving) return;

		try {
			this.coachSaving = true;

			this.coach = await this.coachesService.updateCoach(updatedCoach);
			this.coachChange.emit(this.coach);

			this.toastrService.success(`Coach has been updated.`);
		} finally {
			this.coachSaving = false;
		}
	}

	async onRemoveCoach() {
		this.confirmationService.confirm(
			'Remove Coach from Team?',
			`Are you sure you want to remove <strong>${this.coach.fullName}</strong> coach from the team?`,
			'Remove Coach',
			'btn-danger',
			async () => {
				this.coach.removingFromTeam = true;

				try {
					await this.coachesService.deleteCoach(this.coach.coachId);
					this.coachRemoved.emit(this.coach);

					this.toastrService.success('Coach removed from the team');

					return true;
				} catch (error) {
					this.toastrService.error(Messages.ErrorRetry, 'Failed to Remove Coach');

					return false;
				} finally {
					this.coach.removingFromTeam = false;
				}
			}
		);
	}
}
