import { Component } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faRefresh } from '@fortawesome/pro-regular-svg-icons';

@Component({
	selector: 'app-refresh-icon',
	template: '<fa-icon [icon]="icon" />',
	standalone: true,
	imports: [FontAwesomeModule]
})
export class RefreshIconComponent {
	icon = faRefresh;
}
