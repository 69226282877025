<div class="placeholder-glow" [ngClass]="class">
	<div class="card">
		@if (hasHeader) {
		<div class="card-header">
			<h4 class="mb-0 placeholder col-16"></h4>
		</div>
		}
		@if (hasTitle || hasSubTitle || bodyRowItemCounts.length) {
		@for (bodyItemCounts of bodyRowItemCounts; track bodyItemCounts; let isLastBody = $last) {
		<div class="card-body" [class.border-bottom]="!isLastBody">
			@if (hasTitle) {
			<h5 class="card-title mb-3 placeholder col-12" [class.mb-2]="hasSubTitle" [class.mb-4]="!hasSubTitle"></h5>
			}
			@if (hasSubTitle) {
			<h6 class="card-subtitle mb-4 text-body-secondary placeholder col-18"></h6>
			}
			@if (bodyItemCounts.length) {
			@for (row of getNumbersArray(bodyItemCounts.length); track row; let isLast = $last) {
			<div class="row" [class.mb-4]="!isLast">
				@for (col of getNumbersArray(bodyItemCounts[row]); track col) {
				<div class="col">
					@if (twoLineItems) {
					<div [style]="getRowItemPlaceholderWidth(row, 0.7)" class="placeholder d-block mb-2"></div>
					}
					<div [style]="getRowItemPlaceholderWidth(row)" class="placeholder d-block"></div>
				</div>
				}
			</div>
			}
			}
		</div>
		}
		}
		@if (listItemCount > 0) {
		<ul class="list-group list-group-flush">
			@for (i of getNumbersArray(listItemCount); track i) {
			<li class="list-group-item">
				<div class="placeholder col-15"></div>
			</li>
			}
		</ul>
		}
		@if (hasFooter) {
		<div class="card-footer">
			<div class="placeholder col-16"></div>
		</div>
		}
	</div>
</div>
