import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { catchError, lastValueFrom, map } from 'rxjs';

import { Environment } from '@app/environment';
import { handleHttpError } from '@app/root/app/functions';
import { GlobalFiltersService, LookupService } from '@app/shared/services';
import { SettingViewModel, LookupView } from '@app/shared/models';
import { LookupCodes } from '@app/constants';

@Injectable({
	providedIn: 'root',
})
export class SettingsService {
	private readonly baseUrl = `${Environment.apiBaseUrl}/lookups/configs`;

	// services
	private httpClient: HttpClient = inject(HttpClient);
	private lookupService: LookupService = inject(LookupService);
	private globalFiltersService: GlobalFiltersService = inject(GlobalFiltersService);

	getSettings(): Promise<LookupView[]> {
		return lastValueFrom(
			this.lookupService.getLookup<LookupView>(LookupCodes.Configs).pipe(
				map((settings) => settings.sort((a, b) => a.name.localeCompare(b.name))),
				catchError((error) => {
					return handleHttpError(error);
				})
			)
		);
	}

	createSetting(setting: SettingViewModel) {
		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? '',
		});

		return lastValueFrom(
			this.httpClient.post(`${this.baseUrl}`, setting, { params: httpParams })
				.pipe(catchError(handleHttpError))
		);
	}

	deleteSetting(id: number) {
		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? '',
		});

		return lastValueFrom(
			this.httpClient.delete(`${this.baseUrl}/${id}`, { params: httpParams })
				.pipe(catchError(handleHttpError))
		);
	}
}
