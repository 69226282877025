<div class="card border-0">
	<!-- coach documents -->
	<div class="card-header bg-dark-subtle fw-bold">
		Coach Documents
	</div>
	<div class="card-body d-flex align-items-start flex-wrap flex-lg-nowrap gap-2">
		@if (docsLoading) {
		<app-card-placeholder class="w-100" [bodyRowItemCounts]="[[3]]" [twoLineItems]="true" />
		} @else if (docsError) {
		<app-error message="Error loading coach documents" (retry)="onLoadCoachDocuments()" />
		} @else {
		<div class="row gx-3 gy-2">
			@for (doc of docs; track doc.documentId) {
			<div class="col">
				<app-document-card class="flex-grow-1" [document]="doc" [showUpload]="!readOnly"
					[showInlineStatusNotes]="true" (uploaded)="onLoadCoachDocuments()"
					(approved)="onLoadCoachDocuments()" (rejected)="onLoadCoachDocuments()" />
			</div>
			} @empty { <div class="col"><app-empty message="No coach documents" /></div> }
		</div>
		}
	</div>

	<!-- coach fields -->
	<div class="hstack justify-content-between card-header bg-dark-subtle fw-bold pe-0">
		<div>Coach Info</div>
		<!-- coach actions -->
		@if (!readOnly) {
			<button class="btn btn-sm text-danger" (click)="onRemoveCoach()" [disabled]="coach.removingFromTeam">
				<app-close-icon class="me-2"/> Remove Coach
				@if (coach.removingFromTeam) { <app-spinner class="ms-1" /> }
			</button>
		}
	</div>
	<div class="card-body">
		<div class="row gy-2">
			<div class="col-auto">
				<label class="form-label" for="firstNameField">
					First name
				</label>

				<app-inline-edit id="firstNameField" name="firstNameField" [value]="coach.firstName" [is-text]="true"
					(save)="onSaveCoachChange($event, 'firstName')" [readOnly]="readOnly" />
			</div>
			<div class="col-auto">
				<label class="form-label" for="middleNameField">
					Middle name
				</label>

				<app-inline-edit id="middleNameField" name="middleNameField" [value]="coach.middleName" [is-text]="true"
					(save)="onSaveCoachChange($event, 'middleName')" [readOnly]="readOnly" />
			</div>
			<div class="col-auto">
				<label class="form-label" for="lastNameField">
					Last name
				</label>

				<app-inline-edit id="lastNameField" name="lastNameField" [value]="coach.lastName" [is-text]="true"
					(save)="onSaveCoachChange($event, 'lastName')" [readOnly]="readOnly" />
			</div>
			<div class="col-auto">
				<label class="form-label" for="managerField">
					Is manager?
				</label>

				<app-inline-edit id="managerField" name="managerField" [value]="coach.isManager" [is-bool]="true"
					(save)="onSaveCoachChange($event, 'isManager')" [readOnly]="readOnly" />
			</div>
		</div>
	</div>
</div>
