import { Component, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { SpinnerComponent } from '@app/root/app/shared/components';

@Component({
	templateUrl: 'alert.component.html',
	styleUrl: 'alert.component.scss',
	standalone: true,
	imports: [CommonModule, SpinnerComponent]
})
export class AlertComponent {
	// inputs
	title = signal<string>('??????');
	messageHtml = signal<string | undefined>(undefined);

	okTitle = signal<string>('OK');
	okButtonClass = signal<string>('btn-primary');

	// services
	private activeModal = inject(NgbActiveModal);

	async onConfirm() {
		this.activeModal.close();
	}

	onCancel() {
		this.activeModal.dismiss();
	}
}
