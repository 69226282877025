export class SystemConstants {
	static readonly TeamPlayersMin = 15; // 15 players per team min
	static readonly TeamPlayersMax = 16; // 16 players per team max
	static readonly TeamCoachesMin = 1; // 1 coaches per team min
	static readonly TeamCoachesMax = 4; // 4 coaches per team max
	static readonly MaxPlayerRoommates = 3; // 3 players in room
	static readonly MaxCoachRoommates = 2; // 2 coaches in room
}

export class LookupCodes {
	static readonly LeagueYears = "leagueyears";
	static readonly Regions = "regions";
	static readonly Zones = "zones";
	static readonly Countries = "countries";
	static readonly States = "states";
	static readonly Employees = "employees";
	static readonly Divisions = "divisions";
	static readonly TeamTypes = "teamtypes";
	static readonly Genders = "genders";
	static readonly Ethnicities = "ethnicities";
	static readonly Positions = "positions";
	static readonly Handedness = "handedness";
	static readonly DocumentStatuses = "statuses";
	static readonly DocumentSubjects = "subjects";
	static readonly DocumentTypes = "documents";
	static readonly DocumentCategories = "documentcategories";
	static readonly FilterCategories = "filtercategories";
	static readonly Roles = "roles";
	static readonly AffiliationAppTeamDivisionGroups = "affappteamdivisiongroups";
	static readonly Configs = "configs";
	static readonly LogCategories = "logcategories";
	static readonly LeagueAppsFilterOptions = "leagueappsfilteroptions";
	static readonly RegistrationTypes = "registrationtypes";
	static readonly PitchHitRun = "pitchhitrun";
	static readonly Leagues = "leagues";
	static readonly LeaguesFilterTypes = "filterleaguetypes";
	static readonly AffiliationAppDocumentTypes = "affappdocumenttypes";
	static readonly LeagueAppsSites = "leagueappssites";
}

export class Messages {
	static readonly ErrorRetry = 'Please try again and let us know if the issue persists.';
}

export class AppRoleCodes {
	static readonly SystemAdministrator = "1";
	static readonly RegionAdministrator = "2";
	static readonly LeagueDirector = "3";
	static readonly InsuranceAdministrator = "4";
}

export class AppRoleCodesNumber {
	static readonly SystemAdministrator = 1;
	static readonly RegionAdministrator = 2;
	static readonly LeagueDirector = 3;
	static readonly InsuranceAdministrator = 4;
	static readonly SystemDeveloper = 5;
}

export class AppPermissionCodes {
	static readonly Region_FullAccess = "2"; // Gives user permission to access ALL regions, regardless of which regions they may or may not be associated with
	static readonly League_AccessViaMappingTable = "3"; // ??? Gives user permission to the leagues that are defined through a mapping in tblEmployeeLeague
	static readonly Region_AdminAccessViaMappingTable = "4"; // ??? Gives user permission to access regions as an admin for regions that they are mapped to as an admin

	static readonly Home_Execute = "50"; // Grants access to Home module.

	static readonly Leagues_Execute = "6"; // Grants access to Leagues module.
	static readonly Leagues_Dashboard_Filter = "7"; // Allows to see and control Leagues Dashboard filter.

	static readonly League_Create = "8"; // Allows to create new leagues.
	static readonly League_Update = "9"; // Allows to update existing leagues.
	static readonly League_Read = "10"; // Allows to read/open league details.
	static readonly League_Delete = "45"; // Allows to delete existing leagues leagues.

	static readonly League_RegularSeasonTeams_View = "11"; // Allows to view Regular Season Teams (tab).
	static readonly League_RegularSeasonTeam_Add = "12"; // Allows to add Regular Season teams.
	static readonly League_RegularSeasonTeam_Update = "13"; // Allows to edit Regular Season teams.
	static readonly League_RegularSeasonTeam_Delete = "14"; // Allows to delete Regular Season teams.

	static readonly League_TournamentTeams_View = "15"; // Allows to view Tournament Teams (tab).
	static readonly League_TournamentTeam_Add = "16"; // Allows to add Tournament teams.
	static readonly League_TournamentTeam_Update = "17"; // Allows to edit Tournament teams.
	static readonly League_TournamentTeam_Delete = "18"; // Allows to delete Tournament teams.

	static readonly League_AffiliationApplication_View = "19"; // Allows to view/edit/save draft/submit Affiliation Application (tab).
	static readonly League_AffiliationApplication_Edit = "46"; // Allows to edit/save draft/submit Affiliation Application.
	static readonly League_AffiliationApplication_Review = "47"; // Allows to review and either approve or reject Affiliation Application.

	static readonly League_Agreement_View = "20"; // Allows to view/submit (League) Agreement (tab).
	static readonly League_TournamentAgreement_View = "44"; // Allows to view and submit Tournament Agreement/Application.

	static readonly League_Insurance_View = "21"; // Allows to view league Insurance info (tab).
	static readonly League_Insurance_Create = "22"; // Allows to create league Insurance records (proposal/premium).
	static readonly League_Insurance_Update = "23"; // Allows to update league Insurance records (proposal/premium).
	static readonly League_Insurance_Delete = "24"; // Allows to delete league Insurance records (proposal/premium).

	static readonly League_Info_View = "25"; // Allows to view League Info (tab).

	static readonly Team_Read = "26"; // Allows to read/open team details.

	static readonly Forum_Execute = "52"; // Grants access to Forum module.

	static readonly Approvals_Execute = "27"; // Grants access to Approvals module.
	static readonly Approvals_Documents_View = "28"; // [OBSOLETE???] Grants access to Document Approvals submodule.
	static readonly Approvals_Tournaments_View = "29"; // [OBSOLETE???] Grants access to Tournament Eligibility submodule.

	static readonly Helpdesk_Execute = "30"; // [OBSOLETE???] Grants access to Helpdesk module.

	static readonly Resources_Execute = "31"; // Grants access to Resources module.
	static readonly Resources_Create = "32"; // Allows creation and upload of new generic resource documents (only region-year specific).
	static readonly Resources_Update = "33"; // Allows update (name only) and upload of any existing resource.

	static readonly Reports_Execute = "34"; // Grants access to Reporting module.
	static readonly Reports_TeamDocuments_View = "35"; // Allows running of the Team Documents report.
	static readonly Reports_Insurance_View = "36"; // Allows running of the Insurance report.
	static readonly Reports_LeagueContactSheets_View = "37"; // Allows running of the League Contact Sheets report.
	static readonly Reports_GeneralParticipation_View = "38"; // Allows running of the General Participation report.
	static readonly Reports_LeagueParticipation_View = "39"; // Allows running of the League Participation report.
	static readonly Reports_LocationCoverage_View = "40"; // Allows running of the Location Coverage report.
	static readonly Reports_LoginStatistics_View = "41"; // Allows running of the Login Statistics report.

	static readonly Search_Execute = "51"; // Grants access to Search module.

	static readonly Maintenance_Execute = "42"; // Grants access to Maintenance module (management of lookups and users).

	static readonly Profile_Execute = "43"; // Grants access to Profile module (info about self + password resets).
}

export class LeagueFilterCategoryCodes {
	static readonly All = 1;
	static readonly NEPCT = 2;
	static readonly MissingDocuments = 3;
	static readonly RejectedDocuments = 4;
	static readonly RejectedAffiliationApplication = 5;
	static readonly UsingRBIInsurance = 6;
	static readonly MissingAffiliationApplication = 7;
	static readonly StartedorCompletedAffiliationApplication = 8;
	static readonly ImportedfromLeagueApps = 9;
	static readonly NotImportedfromLeagueApps = 10;
	static readonly SubmittedAffiliationApplication = 11;
	static readonly ApprovedAffiliationApplication = 12;
}

export class ForumPostSortCodes {
	static readonly Newest = "newest";
	static readonly Oldest = "oldest";
}

export class AffiliationApplicationStatusCodes {
	static readonly Unknown = 0;
	static readonly NotStarted = 1;
	static readonly InProgress = 2;
	static readonly Rejected = 3;
	static readonly Approved = 4;
}

export class LeagueAgreementStatusCodes {
	static readonly NotSubmitted = 0;
	static readonly Submitted = 1;
}

export class TournamentApplicationStatusCodes {
	static readonly Unknown = 0;
	static readonly Draft = 1;
	static readonly InProgress = 2;
	static readonly Rejected = 3;
	static readonly Approved = 4;
}

export class TeamTypeCodes {
	static readonly RegularSeason = 1;
	static readonly Tournament = 2;
}

export class DocumentStatusCodes {
	static readonly NotStarted = 1;
	static readonly InProgress = 2;
	static readonly Rejected = 3;
	static readonly Approved = 4;
}

export class DocumentSubjectCodes {
	static readonly PlayerDocument = 14;
	static readonly CoachDocument = 15;
	static readonly TeamDocument = 17;
	static readonly OtherDocument = 18;
	static readonly LeagueDocument = 19;
}

export class DocumentCategoryCodes {
	static readonly Mapped = 1;
	static readonly Other = 2;
}

export class EntityTypeNames {
	static readonly Player = 'Player';
	static readonly Coach = 'Coach';
	static readonly Team = 'Team';
	static readonly League = 'League';
	static readonly Document = 'Document';
}

// these are the neverchanging DocumentID values from the database for DocumentSubject = League
export class LeagueDocumentTypeCodes {
	static readonly AffiliationApplication = 100;
	static readonly TournamentApplication = 151;
}

export class RegistrationProcessTypeCodes {
	static readonly Paper = 1;
	static readonly Digital = 2;
	static readonly Other = 3;
}

export class PitchHitRunAnswerCodes {
	static readonly Yes = 1;
	static readonly No = 2;
	static readonly Maybe = 3;
	static readonly NeedMoreInfo = 4;
}

export class AffAppDocumentTypeCodes {
	static readonly IRSTaxExemptStatusLetter = 1;
	static readonly IncomeSourcesCurrentandPriorYear = 2;
	static readonly InsurancePolicy = 3;
	static readonly BoardofDirectorsandAffiliations = 4;
	static readonly AnnualReport = 5;
	static readonly OrganizationBudget = 6;
	static readonly ProgramSupportBudget = 7;
	static readonly ProgramParticipantsSafetyGuidelines = 8;
	static readonly OrganizationLogo = 9;
}

export class AnnouncementImportaceLevelCodes {
	static readonly High = 'danger';
	static readonly Medium = 'warning';
	static readonly Normal = 'success';
	static readonly Low = 'light';
}

export class RoomTypeCodes {
	static readonly RoomTypePlayer = 1;
	static readonly RoomTypeCoach = 2;
}

export const AnnouncementImportanceCodeLabels: { [key: string]: string } = {
	[AnnouncementImportaceLevelCodes.High]: 'High',
	[AnnouncementImportaceLevelCodes.Medium]: 'Medium',
	[AnnouncementImportaceLevelCodes.Normal]: 'Normal',
	[AnnouncementImportaceLevelCodes.Low]: 'Low'
};

export const AffiliationApplicationStatusCardColors: { [key: number]: string } = {
	[AffiliationApplicationStatusCodes.Unknown]: 'bg-neutral border-neutral fill-neutral',
	[AffiliationApplicationStatusCodes.NotStarted]: 'bg-neutral border-neutral fill-neutral',
	[AffiliationApplicationStatusCodes.InProgress]: 'bg-warning border-warning fill-warning',
	[AffiliationApplicationStatusCodes.Rejected]: 'bg-danger border-danger fill-danger',
	[AffiliationApplicationStatusCodes.Approved]: 'bg-success border-success fill-success'
};

export const AffiliationApplicationStatusAlertColors: { [key: number]: string } = {
	[AffiliationApplicationStatusCodes.Unknown]: 'alert-neutral fill-neutral',
	[AffiliationApplicationStatusCodes.NotStarted]: 'alert-light fill-light',
	[AffiliationApplicationStatusCodes.InProgress]: 'alert-warning fill-warning',
	[AffiliationApplicationStatusCodes.Rejected]: 'alert-danger fill-danger',
	[AffiliationApplicationStatusCodes.Approved]: 'alert-success fill-success'
};

export const AffiliationApplicationStatusIconColors: { [key: number]: string } = {
	[AffiliationApplicationStatusCodes.Unknown]: 'bg-neutral-subtle border-neutral fill-neutral',
	[AffiliationApplicationStatusCodes.NotStarted]: 'bg-neutral-subtle border-neutral fill-neutral',
	[AffiliationApplicationStatusCodes.InProgress]: 'bg-warning-subtle border-warning fill-warning',
	[AffiliationApplicationStatusCodes.Rejected]: 'bg-danger-subtle border-danger fill-danger',
	[AffiliationApplicationStatusCodes.Approved]: 'bg-success-subtle border-success fill-success'
};

export const LeagueAgreementStatusCardColors: { [key: number]: string } = {
	[LeagueAgreementStatusCodes.NotSubmitted]: 'bg-neutral border-neutral fill-neutral',
	[LeagueAgreementStatusCodes.Submitted]: 'bg-success border-success fill-success'
};

export const LeagueAgreementStatusIconColors: { [key: number]: string } = {
	[LeagueAgreementStatusCodes.NotSubmitted]: 'bg-neutral-subtle border-neutral fill-neutral',
	[LeagueAgreementStatusCodes.Submitted]: 'bg-success-subtle border-success fill-success'
};

export const TournamentApplicationStatusCardColors: { [key: number]: string } = {
	[TournamentApplicationStatusCodes.Unknown]: 'bg-neutral border-neutral fill-neutral',
	[TournamentApplicationStatusCodes.Draft]: 'bg-neutral border-neutral fill-neutral',
	[TournamentApplicationStatusCodes.InProgress]: 'bg-warning border-warning fill-warning',
	[TournamentApplicationStatusCodes.Rejected]: 'bg-danger border-danger fill-danger',
	[TournamentApplicationStatusCodes.Approved]: 'bg-success border-success fill-success'
};

export const TournamentApplicationStatusAlertColors: { [key: number]: string } = {
	[TournamentApplicationStatusCodes.Unknown]: 'alert-neutral fill-neutral',
	[TournamentApplicationStatusCodes.Draft]: 'alert-neutral fill-neutral',
	[TournamentApplicationStatusCodes.InProgress]: 'alert-warning fill-warning',
	[TournamentApplicationStatusCodes.Rejected]: 'alert-danger fill-danger',
	[TournamentApplicationStatusCodes.Approved]: 'alert-success fill-success'
};

export const TournamentApplicationStatusIconColors: { [key: number]: string } = {
	[TournamentApplicationStatusCodes.Unknown]: 'bg-neutral-subtle border-neutral fill-neutral',
	[TournamentApplicationStatusCodes.Draft]: 'bg-neutral-subtle border-neutral fill-neutral',
	[TournamentApplicationStatusCodes.InProgress]: 'bg-warning-subtle border-warning fill-warning',
	[TournamentApplicationStatusCodes.Rejected]: 'bg-danger-subtle border-danger fill-danger',
	[TournamentApplicationStatusCodes.Approved]: 'bg-success-subtle border-success fill-success'
};

export const DocumentStatusCardColors: { [key: number]: string } = {
	[DocumentStatusCodes.NotStarted]: 'bg-neutral-subtle border-neutral',
	[DocumentStatusCodes.InProgress]: 'bg-warning-subtle border-warning',
	[DocumentStatusCodes.Rejected]: 'bg-danger-subtle border-danger',
	[DocumentStatusCodes.Approved]: 'bg-success-subtle border-success'
};

export const DocumentStatusIconColors: { [key: number]: string } = {
	[DocumentStatusCodes.NotStarted]: 'bg-neutral border-neutral',
	[DocumentStatusCodes.InProgress]: 'bg-warning border-warning',
	[DocumentStatusCodes.Rejected]: 'bg-danger border-danger',
	[DocumentStatusCodes.Approved]: 'bg-success border-success'
};
