<div class="modal-header">
	<h4 class="modal-title">Document Upload</h4>
	<button type="button" class="btn-close" aria-label="Close" (click)="onCancel()"></button>
</div>
<div class="modal-body" style="min-height: 80vh; height: 80vh;">
	<div class="container h-100 d-flex flex-column p-0">
		<!-- <div>{{ leagueID || 'null' }} / {{ teamID || 'null' }} / {{ teamPlayerID || 'null' }}</div> -->

		<!-- {{ file.name }}
									<div>{{ file.size }}</div>
									<div>{{ file.type }}</div>
									<div>{{ file.lastModified }}</div> -->

		@if (fileUrls && fileUrls.length > 0) {
		<!-- paging controls -->
		@if (!anyNonImages) {
		<div class="d-flex justify-content-between align-items-center mb-3">
			<div class="d-flex align-items-center">
				<button class="btn btn-light me-1" (click)="onPrevious()" [disabled]="currentFileIndex <= 0">
					<fa-icon [icon]="previousIcon" />
				</button>
				<button class="btn btn-light" (click)="onNext()" [disabled]="currentFileIndex >= (fileUrls.length - 1)">
					<fa-icon [icon]="nextIcon" />
				</button>

				<div class="dropdown d-none d-sm-block" routerLinkActive="active" ngbDropdown>
					<button class="btn btn-link dropdown-toggle px-2" type="button" data-bs-toggle="dropdown"
						aria-expanded="false" ngbDropdownToggle>
						Page {{ (currentFileIndex + 1) }} ({{ fileNames?.[currentFileIndex] }})
					</button>
					<ul class="dropdown-menu" ngbDropdownMenu>
						@for (file of files; track file; let index = $index) {
						<li class="c-pointer" ngbDropdownItem (click)="currentFileIndex = index">Page {{ (index + 1) }}
							({{ file.name }})</li>
						}
					</ul>
				</div>

				<label class="text-secondary ms-2">{{ fileUrls.length}} page{{ (fileUrls.length > 1 ? 's' : '')
					}}</label>
			</div>

			<div>
				<button class="btn btn-primary" (click)="photoLibraryFileInput.click()">
					<fa-icon [icon]="addIcon" />
					<span class="d-none d-sm-inline ms-2">Add Another Page</span>
				</button>

				<!-- camera -->
				<input #cameraFileInput class="d-none" type="file" accept="image/*" capture="camera"
					(change)="onFileInput($event)">

				<!-- photo library -->
				<input #photoLibraryFileInput class="d-none" type="file" accept="image/*" multiple
					(change)="onFileInput($event)" (click)="onResetFileInput(photoLibraryFileInput)">
			</div>
		</div>
		}

		<!-- file viewer -->
		<div class="p-2 border border-dotted flex-grow-1 overflow-auto d-flex flex-column h-100">
			<div class="px-3 py-3 text-bg-light d-flex align-items-center justify-content-between">
				<div>{{ files?.[currentFileIndex]?.name }} @if (files?.[currentFileIndex]?.size) { ({{
					(files?.[currentFileIndex]?.size || -1) | shortNumber }}) }</div>
				@if (!anyNonImages && !isSingleFile) {
				<button class="btn btn-sm px-2" (click)="onRemoveCurrentPageFile()"><fa-icon
						[icon]="closeIcon" /></button>
				}
			</div>
			<div class="bg-dark text-center p-3 flex-grow-1 overflow-auto">
				@if (anyNonImages) {
				<!-- non-image files -->
				<object class="w-100 h-100" [attr.data]="fileUrls[currentFileIndex] | safe:'resourceUrl'"></object>
				} @else {
				<!-- image files -->
				<div class="page-image-container">
					<img class="page-image" [src]="fileUrls[currentFileIndex]"
						alt="{{ files?.[currentFileIndex]?.name }}" />
				</div>
				}
			</div>
		</div>

		<!-- multi-image alert -->
		@if (!hideMultiImageAlert && !anyNonImages) {
		<div class="alert alert-warning alert-dismissible fade show d-flex align-items-top px-3 mt-3" role="alert">
			<fa-icon [icon]="alertIcon" />
			<div class="mx-3">NOTE: You can select multiple image files (not PDF, Word, etc) and they will be
				automatically merged
				into a
				single
				document during upload.</div>
			<fa-icon [icon]="closeIcon" data-bs-dismiss="alert" aria-label="Close"
				(click)="onDismissMultiImageAlert()" />
		</div>
		}

		<!-- document metadata -->
		<div class="mt-2">
			@if (affAppDocumentTypeID) {
			<div class="row g-3 align-items-center mb-1">
				<div class="col-9 d-none d-sm-block">
					<label class="col-form-label" for="entitySelect">Affiliation Application document</label>
				</div>
				<div class="col">
					<input id="entitySelect" name="entitySelect" class="form-control fw-bold" type="text"
						[value]="documentName" readonly />
				</div>
			</div>
			}
			@else if (documentInstanceID) {
			<!-- uploading specific document type/instance - don't allow to change -->
			<div class="row g-3 align-items-center mb-1">
				<div class="col-5 d-none d-sm-block">
					<label class="col-form-label" for="entitySelect">Who is it for?</label>
				</div>
				<div class="col">
					<input id="entitySelect" name="entitySelect" class="form-control fw-bold" type="text"
						[value]="teamPlayerName || coachName || teamName || 'UNKNOWN'" readonly />
				</div>
			</div>
			<div class="row g-3 align-items-center">
				<div class="col-5 d-none d-sm-block">
					<label class="col-form-label" for="typeSelect">Document type</label>
				</div>
				<div class="col">
					<input id="typeSelect" name="typeSelect" class="form-control fw-bold" type="text"
						[value]="documentName || 'UNKNOWN'" readonly />
				</div>
			</div>
			} @else {
			<!-- uploading for an entity (league/team/coach/player) - show entity/doc type dropdowns -->
			<div class="row g-3 align-items-center mb-1">
				<div class="col-5 d-none d-sm-block">
					<label class="col-form-label" for="entitySelect">Who is it for?</label>
				</div>
				<div class="col d-flex align-items-center">
					<ng-select id="entitySelect" name="entitySelect" class="flex-grow-1" [items]="documentEntities"
						bindLabel="title" groupBy="group" [multiple]="false" [(ngModel)]="selectedDocumentEntity"
						[loading]="documentEntitiesLoading" [clearable]="false" [searchable]="true"
						placeholder="Who is it for?" (change)="loadDocumentInstances()"
						[readonly]="documentEntitiesLoading">
						<!-- selected item template -->
						<ng-template ng-label-tmp let-item="item">
							<strong>{{ item.title }}</strong>
						</ng-template>
						<!-- dropdown group template -->
						<ng-template ng-optgroup-tmp let-item="item">
							<div class="bg-secondary-subtle fw-bold p-2 mx-n2">{{ item.group }}</div>
						</ng-template>
						<!-- dropdown item template -->
						<ng-template ng-option-tmp let-item="item">
							<span class="fw-medium">{{ item.title }}</span> @if (item.details) { <span
								class="text-secondary"> - {{
								item.details
								}}</span> }
						</ng-template>
					</ng-select>
				</div>
			</div>
			<div class="row g-3 align-items-center">
				<div class="col-5 d-none d-sm-block">
					<label class="col-form-label" for="typeSelect">Document type</label>
				</div>
				<!-- d-flex align-items-center -->
				<div class="col">
					<ng-select id="typeSelect" name="typeSelect" class="flex-grow-1" [items]="documentInstances"
						bindLabel="documentName" [multiple]="false" [(ngModel)]="selectedDocumentInstance"
						placeholder="Document type" [loading]="documentInstancesLoading" [clearable]="false"
						[searchable]="false" [readonly]="!selectedDocumentEntity || documentInstancesLoading">
						<!-- selected item template -->
						<ng-template ng-label-tmp let-item="item">
							<strong>{{ item.documentName }}</strong>
						</ng-template>
						<!-- dropdown item template -->
						<ng-template ng-option-tmp let-item="item">
							<span class="fw-medium">{{ item.documentName }}</span>
						</ng-template>
					</ng-select>
				</div>
			</div>
			}
		</div>
		}
	</div>
</div>
<div class="modal-footer text-center justify-content-center justify-content-sm-end">
	@if (hasMultipleNonImages) {
	<span class="text-danger me-2">You cannot upload multiple non-image files - they will not be merged.</span>
	}
	<button type="button" class="btn btn-primary" (click)="onUploadDocument()"
		[disabled]="!canUpload || documentUploading">
		<fa-icon class="me-2" [icon]="uploadIcon" />
		Upload Document
		@if (documentUploading) { <app-spinner class="ms-2" /> }
	</button>
</div>
