import { Component } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';

@Component({
	selector: 'app-save-icon',
	template: '<fa-icon [icon]="icon" />',
	standalone: true,
	imports: [FontAwesomeModule]
})
export class SaveIconComponent {
	icon = faCheck;
}
