import { Component, computed, input } from '@angular/core';

import { AgChartsAngular } from 'ag-charts-angular';
import { AgChartOptions } from 'ag-charts-community';

export interface ChartOptions {
	name: string;
	count: number;
}

@Component({
	selector: 'app-doughnut-chart',
	templateUrl: './doughnut-chart.component.html',
	styleUrl: './doughnut-chart.component.scss',
	standalone: true,
	imports: [AgChartsAngular],
})
export class DoughnutChartComponent {
	// inputs
	chartOptions = input.required<ChartOptions[]>();
	totalCount = computed<number>(() => this.chartOptions().reduce((total, option) => total + option.count, 0));
	options = computed<AgChartOptions>(() => {
		return {
			data: this.chartOptions(),
			overlays: {
				noData: {
					text: 'No Data',
				},
				loading: {},
			},
			theme: {
				palette: {
					fills: ['#ae3045', '#2d72c0', '#3fb1f1', '#182c97', '#275c57', '#ed5c2a'],
				},
			},
			autoSize: true,
			width: 120,
			height: 120,
			series: [
				{
					type: 'donut',
					calloutLabelKey: 'name',
					angleKey: 'count',
					innerRadiusRatio: 0.7,
					innerLabels: [
						{
							text: `${this.totalCount()}`,
							fontWeight: 'bold',
						},
						{
							text: 'total',
						},
					],
					calloutLabel: {
						enabled: false,
					},
					showInLegend: false,
					tooltip: {
						renderer: ({ datum }) => {
							return {
								title: datum['name'],
								content: datum['count'],
							};
						},
					},
				},
			],
		};
	});
}
