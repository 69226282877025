import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { CountryIconComponent } from '../entity-icons/country-icon.component';

@Component({
	selector: 'app-location-badge',
	template: `
	<span class="badge text-bg-light" ngbTooltip="Country/Zone"><app-country-icon class="me-1" countryCode="{{ countryCode }}" /> {{ countryCode }}@if (zoneName) { / {{ zoneName }} }</span>
	`,
	standalone: true,
	imports: [CommonModule, NgbTooltipModule, CountryIconComponent]
})
export class LocationBadgeComponent {
	@Input({ required: true }) countryCode!: string;
	@Input() zoneName?: string;
}
