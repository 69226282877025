<div class="alert alert-danger" [ngClass]="class">
	<!-- TODO: REMOVE vertical VARIABLE AND USE RESPONSIVE LAYOUT -->
	<div class="d-flex gap-2 flex-column flex-md-row align-items-md-center justify-content-md-between">
		<div class="d-flex align-items-center">
			<div>
				<div [class.h4]="!shortTemplate" [class.h5]="shortTemplate">
					@if (!shortTemplate) {
					<fa-icon class="me-1" [icon]="errorIcon" />
					}
					{{ message }}
				</div>
				@if (!shortTemplate) {
				<div class="text-start" *ngIf="!shortTemplate">Please try again and let us know if the issue persists.
				</div>
				}
			</div>
		</div>

		<button class="btn btn-danger" type="button" (click)="retry.emit()">
			Try Again
		</button>
	</div>
</div>
