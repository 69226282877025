import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-country-icon',
	template: '<span class="fi fi-{{ countryCode | lowercase }}"></span>',
	standalone: true,
	imports: [CommonModule]
})
export class CountryIconComponent {
	@Input() countryCode!: string;
}
