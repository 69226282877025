import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { ReplaySubject, tap, catchError, throwError, lastValueFrom, of } from 'rxjs';

import { AuthService } from './auth.service';
import { GlobalFiltersService } from './global-filters.service';

import { PagedModel } from '../models/paged.model';
import { LeagueListViewModel, LeagueDashboardViewModel, LeagueDetailsViewModel, LeagueEditModel } from '../models/league.models';

import { handleHttpError, removeEmptyParams } from '../../functions';
import { SystemConstants } from '../../constants';

import { Environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class LeaguesService {
	private readonly baseUrl = `${Environment.apiBaseUrl}/leagues`;

	private httpClient: HttpClient = inject(HttpClient);
	private authService: AuthService = inject(AuthService);
	private globalFiltersService: GlobalFiltersService = inject(GlobalFiltersService);

	getLeagues(
		categoryID: number | null,
		name: string | null,
		zoneID: number | null,
		regionID: number | null,
		page: number
	) {
		return lastValueFrom(
			this.getLeagues$(
				categoryID,
				name,
				zoneID,
				regionID,
				page
			)
		);
	}

	getLeagues$(
		categoryID: number | null,
		name: string | null,
		zoneID: number | null,
		regionID: number | null,
		page: number
	) {
		console.log(`LOADING LEAGUES => ${this.globalFiltersService.getLeagueYearID() || 'NULL'}...`);

		const httpParams = new HttpParams().appendAll({
			categoryId: categoryID ?? '',
			name: name ?? '',
			zoneId: zoneID ?? '',
			regionId: regionID ?? '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? '',
			page: true,
			pageIndex: page,
			pageSize: 50
		});

		return this.httpClient
			.get<PagedModel<LeagueListViewModel>>(
				`${this.baseUrl}`,
				{ params: httpParams })
			.pipe(
				tap(async pagedLeagues => {
					pagedLeagues.pageData.forEach(league => this.computeLeagueProperties(league));
				}),
				catchError(handleHttpError)
			);
	}

	getLeagueDashboard(leagueID: number) {
		console.log(`LOADING LEAGUE DASHBOARD => ${this.globalFiltersService.getLeagueYearID() || 'NULL'}...`);

		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.get<LeagueDashboardViewModel>(
					`${this.baseUrl}/${leagueID}/dashboard`,
					{ params: httpParams })
				.pipe(
					tap(async league => this.computeLeagueProperties(league)),
					catchError(handleHttpError)
				)
		);
	}

	getLeagueDetails(leagueID: number) {
		console.log(`LOADING LEAGUE DETAILS => ${this.globalFiltersService.getLeagueYearID() || 'NULL'}...`);

		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.get<LeagueDetailsViewModel>(
					`${this.baseUrl}/${leagueID}`,
					{ params: httpParams })
				.pipe(catchError(handleHttpError))
		);
	}

	getLeagueAffiliationApplication(leagueID: number) {
		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.get<LeagueDetailsViewModel>(
					`${this.baseUrl}/${leagueID}`,
					{ params: httpParams })
				.pipe(catchError(handleHttpError))
		);
	}

	saveLeague(league: LeagueEditModel) {
		if (!league.fullDirectorIds) {
			league.fullDirectorIds = [];
		}
		if (!league.guestDirectorIds) {
			league.guestDirectorIds = [];
		}

		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.post<LeagueDetailsViewModel>(
					`${this.baseUrl}/${league.leagueId}`,
					league,
					{ params: httpParams })
				.pipe(catchError(handleHttpError))
		);
	}

	private computeLeagueProperties(league: LeagueListViewModel | LeagueDashboardViewModel) {
		league.hasTeamWarning = league.tournamentTeamCount === 0;

		league.hasPlayerWarning = league.playerCount < (league.tournamentTeamCount * SystemConstants.TeamPlayersMin);
		league.hasPlayerSuccess = league.playerCount > 0 && league.playerCount >= (league.tournamentTeamCount * SystemConstants.TeamPlayersMin);

		league.hasCoachWarning = league.coachCount < (league.tournamentTeamCount * SystemConstants.TeamCoachesMin);
		league.hasCoachSuccess = league.coachCount > 0 && league.coachCount >= (league.tournamentTeamCount * SystemConstants.TeamCoachesMin);

		const badCount = league.notStartedCount + league.rejectedCount;
		const goodCount = league.inProgressCount + league.approvedCount;
		league.hasDocumentWarning = badCount > 0;
		league.hasDocumentSuccess = badCount === 0 && goodCount >= league.totalCount && league.totalCount > 0;
	}
}
