import { CommonModule } from '@angular/common';
import { Component, Input, booleanAttribute } from '@angular/core';

import { BrowseIconComponent } from '@app/root/app/shared/components';

@Component({
	selector: 'app-field-value',
	templateUrl: 'field-value.component.html',
	styleUrl: 'field-value.component.scss',
	standalone: true,
	imports: [CommonModule, BrowseIconComponent]
})
export class FieldValueComponent {
	@Input({ required: true }) label!: string;
	@Input({ required: true }) value?: string | null;
	@Input({ transform: booleanAttribute }) isRequired = false;
	@Input({ transform: booleanAttribute }) isUrl = false;
	@Input() labelValueCols = { label: 9, value: 15 };

	protected fieldID = `field-${Math.random().toString(36).substring(7)}`;
	protected get cleanUrl() {
		// append https:// to the URL if it doesn't have it
		return this.value?.startsWith('http') ? this.value : `https://${this.value}`;
	}
}
