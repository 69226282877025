<div class="modal-header">
	<h4 class="modal-title">{{ title || 'Document Viewer' }}</h4>
	<button type="button" class="btn-close" aria-label="Close" (click)="onClose()"></button>
</div>
<div class="modal-body" style="min-height: 80vh; height: 80vh;">
	<div class="container h-100 d-flex flex-column p-0">
		<div class="p-2 h-100 bg-light d-flex justify-content-center align-items-center">
			@if (fileLoading) {
			<app-spinner class="h1" />
			} @else if (fileError) {
			<app-error message="Error loading document file" (retry)="onLoadDocumentFile()" />
			} @else if (fileUrl) {
			@if (isImageFile) {
			<div class="hstack gap-1 position-absolute top-0 end-0 mt-4 me-4">
				<button type="button" class="btn btn-sm btn-outline-dark" ngbTooltip="Zoom In" (click)="onZoomIn()">
					<fa-icon [icon]="zoonIn"></fa-icon>
				</button>

				<button type="button" class="btn btn-sm btn-outline-dark" ngbTooltip="Zoom Out" (click)="onZoomOut()">
					<fa-icon [icon]="zoomOut"></fa-icon>
				</button>
			</div>
			<!-- mx-auto -->
			<figure style="overflow: auto;" class="w-100 h-100">
				<img class="img-fluid" [src]="fileUrl | safe:'resourceUrl'"
					[style]="{ 'height': imageZoomLevel + '%' }" />
			</figure>
			} @else {
			<object class="w-100 h-100" [attr.data]="fileUrl | safe:'resourceUrl'"></object>
			}
			}
		</div>
	</div>
</div>
<div class="modal-footer">
	<div class="hstack w-100" [class.justify-content-between]="canReview">
		<button type="button" class="btn btn-outline-secondary" (click)="onClose()">
			Close
		</button>
		@if (canReview) {
		<div class="hstack gap-2">
			<button class="btn btn-success" (click)="onApprove()" [disabled]="approving || rejecting">
				Approve
				@if (approving) { <app-spinner class="ms-1" /> }
			</button>
			<button class="btn btn-danger" (click)="onReject()" [disabled]="rejecting || approving">
				Reject
				@if (rejecting) { <app-spinner class="ms-1" /> }
			</button>
		</div>
		}
	</div>
</div>
