import { Component } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faPenLine } from '@fortawesome/pro-regular-svg-icons';

@Component({
	selector: 'app-edit-icon',
	template: '<fa-icon [icon]="icon" />',
	standalone: true,
	imports: [FontAwesomeModule]
})
export class EditIconComponent {
	icon = faPenLine;
}
