import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class FileService {
	downloadFile(file: Blob, fileName: string) {
		if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
			// for IE and Edge
			(window.navigator as any).msSaveOrOpenBlob(file, fileName);
		} else {
			// for other browsers
			const blobUrl = window.URL.createObjectURL(file);
			const anchor = document.createElement('a');
			anchor.href = blobUrl;
			anchor.download = fileName;
			anchor.click();

			// clean up
			setTimeout(() => {
				window.URL.revokeObjectURL(blobUrl);
				anchor.remove();
			}, 0);
		}
	}

	convertToCSV(file: any[], headers: string, dataOrder: string[]): Blob {
		const rows: string = file
			.map((obj) => {
				const rowData = dataOrder.map((key: string) => {
					const value = obj[key];

					return value !== null && value !== undefined ? `"${value}"`: '';
				});
				return rowData.join(',');
			})
			.join('\n');

		return new Blob([headers + rows], {
			type: 'text/csv',
		});
	}
}
