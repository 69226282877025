import { Component, Input } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faUserHeadset } from '@fortawesome/pro-regular-svg-icons';

@Component({
	selector: 'app-leagueapps-icon',
	template: '<img height="{{ size }}" src="/assets/images/leagueapps-logo-icon.svg" alt="LeagueApps logo" />',
	standalone: true,
	imports: [FontAwesomeModule]
})
export class LeagueAppsIconComponent {
	@Input() size: number = 16;

	icon = faUserHeadset;
}
