import { Component } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';

@Component({
	selector: 'app-browse-icon',
	template: '<fa-icon class="small" [icon]="icon" />',
	standalone: true,
	imports: [FontAwesomeModule]
})
export class BrowseIconComponent {
	icon = faArrowUpRightFromSquare;
}
