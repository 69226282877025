import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { DocumentsIconComponent } from '../entity-icons/documents-icon.component';

@Component({
	selector: 'app-documents-badge',
	template: `
	<span class="badge" [ngClass]="[ hasWarning ? 'text-warning-emphasis bg-warning-subtle' : '', hasSuccess ? 'text-success-emphasis bg-success-subtle' : '', !hasWarning && !hasSuccess ? 'text-bg-light' : '', badgeClass ]" ngbTooltip="Number of documents (current/required)" container="body"><app-docs-icon class="me-1" /> {{ (inProgressCount + approvedCount) | number }} / {{ totalCount | number }} @if (showLabel) { Docs }</span>
	`,
	standalone: true,
	imports: [CommonModule, FontAwesomeModule, NgbTooltipModule, DocumentsIconComponent]
})
export class DocumentsBadgeComponent {
	@Input({ required: true }) inProgressCount!: number;
	@Input({ required: true }) approvedCount!: number;
	@Input({ required: true }) totalCount!: number;
	@Input() hasWarning: boolean = false;
	@Input() hasSuccess: boolean = false;
	@Input() showLabel: boolean = false;
	@Input() badgeClass: string = '';
}
