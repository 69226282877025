import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
	selector: 'app-stacked-value',
	standalone: true,
	imports: [NgClass],
	templateUrl: './stacked-value.component.html',
	styleUrl: './stacked-value.component.scss',
})
export class StackedValueComponent {
	@Input({ required: true }) label!: string;
	@Input({ required: true }) value?: string | null;
}
