import { Component } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTrophy } from '@fortawesome/pro-regular-svg-icons';

@Component({
	selector: 'app-league-icon',
	template: '<fa-icon [icon]="icon" />',
	standalone: true,
	imports: [FontAwesomeModule]
})
export class LeagueIconComponent {
	icon = faTrophy;
}
