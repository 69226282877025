import { Injectable, inject } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AlertComponent } from '../../shared/components/alert/alert.component';

@Injectable({
	providedIn: 'root',
})
export class AlertService {
	private modalService: NgbModal = inject(NgbModal);

	show(
		title: string,
		messageHtml: string,
		okTitle: string = 'OK',
		okButtonClass: string = 'btn-primary',
		size: 'sm' | 'lg' | 'xl' | undefined = undefined
	) {
		const modal = this.modalService.open(AlertComponent, { size: size });

		modal.componentInstance.title.set(title);
		modal.componentInstance.messageHtml.set(messageHtml);
		modal.componentInstance.okTitle.set(okTitle);
		modal.componentInstance.okButtonClass.set(okButtonClass);
	}
}
