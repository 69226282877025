import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { ReplaySubject, tap, catchError, throwError, lastValueFrom } from 'rxjs';

import { AuthService } from './auth.service';
import { GlobalFiltersService } from './global-filters.service';

import { PagedModel } from '../models/paged.model';
import { PlayerEditModel, PlayerListViewModel, PlayerSearchListViewModel } from '../models/player.models';
import { EntityDocumentViewModel } from '../models/document.models';

import { handleHttpError, removeEmptyParams } from '../../functions';
import { Environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class PlayersService {
	private readonly baseUrl = `${Environment.apiBaseUrl}/players`;
	private readonly v2BaseUrl = `${Environment.apiBaseUrl}/v2/players`;

	private httpClient: HttpClient = inject(HttpClient);
	private globalFiltersService: GlobalFiltersService = inject(GlobalFiltersService);

	getPlayerDocuments(playerTeamID: number) {
		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.get<EntityDocumentViewModel[]>(
					`${this.baseUrl}/${playerTeamID}/documents`,
					{ params: httpParams })
				.pipe(catchError(handleHttpError))
		);
	}

	createPlayer(player: PlayerEditModel) {
		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.post<PlayerListViewModel>(
					this.baseUrl,
					player,
					{ params: httpParams })
				.pipe(catchError(handleHttpError))
		);
	}

	updatePlayer(player: PlayerListViewModel) {
		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.post<PlayerListViewModel>(
					`${this.baseUrl}/${player.playerTeamId}`,
					player,
					{ params: httpParams })
				.pipe(catchError(handleHttpError))
		);
	}

	searchPlayers(
		leagueYearID: number | null,
		teamID: number,
		searchText: string | null,
		dateOfBirth: string | null,
		applyAgeLimits: boolean,
		pageNumber: number
	) {
		return lastValueFrom(
			this.searchPlayers$(
				leagueYearID,
				teamID,
				searchText,
				dateOfBirth,
				applyAgeLimits,
				pageNumber
			)
		);
	}

	searchPlayers$(
		leagueYearID: number | null,
		teamID: number,
		searchText: string | null,
		dateOfBirth: string | null,
		applyAgeLimits: boolean,
		pageNumber: number
	) {
		const httpParams = new HttpParams().appendAll({
			leagueYearID: leagueYearID ?? '',
			teamID: teamID,
			searchText: searchText ?? '',
			dateOfBirth: dateOfBirth ?? '',
			applyAgeLimits: applyAgeLimits,
			page: pageNumber
		});

		return this.httpClient
			.get<PagedModel<PlayerSearchListViewModel>>(
				`${this.v2BaseUrl}/search`,
				{ params: httpParams })
			.pipe(catchError(handleHttpError));
	}
}
