import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { ReplaySubject, tap, catchError, throwError, lastValueFrom } from 'rxjs';

import { AuthService } from './auth.service';
import { GlobalFiltersService } from './global-filters.service';

import { PagedModel } from '../models/paged.model';
import { EntityDocumentViewModel } from '../models/document.models';
import { CoachEditModel, CoachListViewModel, CoachSearchListViewModel } from '../models/coach.models';
import { TeamsService } from './teams.service';

import { handleHttpError, removeEmptyParams } from '../../functions';
import { Environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class CoachesService {
	private readonly baseUrl = `${Environment.apiBaseUrl}/coaches`;

	private httpClient: HttpClient = inject(HttpClient);
	private globalFiltersService: GlobalFiltersService = inject(GlobalFiltersService);

	getCoachDocuments(coachID: number) {
		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.get<EntityDocumentViewModel[]>(
					`${this.baseUrl}/${coachID}/documents`,
					{ params: httpParams })
				.pipe(catchError(handleHttpError))
		);
	}

	createCoach(coach: CoachEditModel, teamID: number) {
		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.post<CoachListViewModel>(
					this.baseUrl,
					{ ...coach, teamId: teamID },
					{ params: httpParams })
				.pipe(
					tap(coach => TeamsService.ComputePlayerCoachProperties(coach)),
					catchError(handleHttpError)
				)
		);
	}

	updateCoach(coach: CoachListViewModel) {
		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.post<CoachListViewModel>(
					`${this.baseUrl}/${coach.coachId}`,
					coach,
					{ params: httpParams })
				.pipe(
					tap(coach => TeamsService.ComputePlayerCoachProperties(coach)),
					catchError(handleHttpError)
				)
		);
	}

	searchCoaches(
		leagueYearID: number | null,
		teamID: number,
		searchText: string | null,
		pageNumber: number
	) {
		return lastValueFrom(
			this.searchCoaches$(
				leagueYearID,
				teamID,
				searchText,
				pageNumber
			)
		);
	}

	searchCoaches$(
		leagueYearID: number | null,
		teamID: number,
		searchText: string | null,
		pageNumber: number
	) {
		const httpParams = new HttpParams().appendAll({
			leagueYearID: leagueYearID ?? '',
			teamID: teamID,
			searchText: searchText ?? '',
			page: pageNumber
		});

		return this.httpClient
			.get<PagedModel<CoachSearchListViewModel>>(
				`${this.baseUrl}/search`,
				{ params: httpParams })
			.pipe(catchError(handleHttpError));
	}

	deleteCoach(coachID: number) {
		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.delete(
					`${this.baseUrl}/${coachID}`,
					{ params: httpParams })
				.pipe(catchError(handleHttpError))
		);
	}
}
