@if (announcementsLoading && showLoader) {
<div class="alert alert-light placeholder-glow border-2">
	<div class="mb-3">
		<span class="placeholder col-1 me-2"></span>
		<span class="placeholder col-18"></span>
	</div>
	<div>
		<span class="placeholder col-14"></span>
		<span class="placeholder col-18"></span>
	</div>
</div>
} @else if (announcementsError) {
<app-error message="Error loading announcements" (retry)="onLoadAnnouncements()" />
} @else if (announcements?.length) {
@for (a of announcements; track a.announcementID; let isLast = $last) {
<div class="alert alert-{{ a.importanceLevelCode | lowercase }} fade show border-2 d-flex align-items-center"
	[class.alert-dismissible]="a.isDismissable" [class.mb-2]="!isLast" role="alert">
	<div>
		<h4>
			<fa-icon class="me-2" [icon]="announcementIcon" />
			{{ a.title }}
		</h4>
		@if (a.message) {
		<p class="announcement-message mb-0" [innerHtml]="a.message"></p>
		}
	</div>
	@if (a.isDismissable) {
	<button type="button" class="btn-close small" data-bs-dismiss="alert" aria-label="Close"
		(click)="onDismissAnnouncement(a)"></button>
	}
</div>
}
}
