<div class="card border-0" [ngClass]="class">
	<!-- player documents -->
	<div class="card-header bg-dark-subtle fw-bold">
		Player Documents
	</div>
	<div class="card-body d-flex align-items-start flex-wrap flex-lg-nowrap gap-2">
		@if (docsLoading) {
		<app-card-placeholder class="w-100" [bodyRowItemCounts]="[[4]]" [twoLineItems]="true" />
		} @else if (docsError) {
		<app-error message="Error loading player documents" (retry)="onLoadPlayerDocuments()" />
		} @else {
		<div class="row gx-3 gy-2">
			@for (doc of docs; track doc.documentId) {
			<div class="col">
				<app-document-card [document]="doc" [showUpload]="!readOnly" [showInlineStatusNotes]="true"
					(uploaded)="onLoadPlayerDocuments()" (approved)="onLoadPlayerDocuments()"
					(rejected)="onLoadPlayerDocuments()" />
			</div>
			} @empty {
			<div class="col"><app-empty message="No player documents" /></div>
			}
		</div>
		}
	</div>

	<!-- player fields -->
	<div class="hstack justify-content-between card-header bg-dark-subtle fw-bold pe-0">
		<div>Player Info</div>
		<!-- player actions -->
		@if (!readOnly) {
			<button class="btn btn-sm text-danger" (click)="onRemovePlayer()">
				<app-close-icon class="me-2"/>Remove Player
			</button>
		}
	</div>
	<div class="card-body">
		<div class="row gy-2">
			<div class="col-auto">
				<label class="form-label" for="firstNameField">
					First name
				</label>

				<app-inline-edit id="firstNameField" name="firstNameField" [value]="player.firstName" [is-text]="true"
					(save)="onSavePlayerChange($event, 'firstName')" [readOnly]="readOnly" />
			</div>
			<div class="col-auto">
				<label class="form-label" for="middleNameField">
					Middle name
				</label>

				<app-inline-edit id="middleNameField" name="middleNameField" [value]="player.middleName"
					[is-text]="true" (save)="onSavePlayerChange($event, 'middleName')" [readOnly]="readOnly" />
			</div>
			<div class="col-auto">
				<label class="form-label" for="lastNameField">
					Last name
				</label>

				<app-inline-edit id="lastNameField" name="lastNameField" [value]="player.lastName" [is-text]="true"
					(save)="onSavePlayerChange($event, 'lastName')" [readOnly]="readOnly" />
			</div>
			<div class="col-auto">
				<label class="form-label" for="dobField">
					Date of birth
				</label>

				<app-inline-edit id="dobField" name="dobField" [value]="player.dob" [is-date]="true"
					(save)="onSavePlayerChange($event, 'dob')" [readOnly]="readOnly" />
			</div>
			<div class="col-auto">
				<label class="form-label" for="genderField">
					Gender
				</label>

				<app-inline-edit id="genderField" name="genderField" [value]="player.genderId" [is-select]="true"
					select-id="id" select-display="name" [select-values]="(gendersLookup$ | async)"
					(save)="onSavePlayerChange($event, 'genderId')" [readOnly]="readOnly" />
			</div>
			<div class="col-auto">
				<label class="form-label" for="ethnicityField">
					Ethnicity
				</label>

				<app-inline-edit id="ethnicityField" name="ethnicityField" [value]="player.ethnicityId"
					[is-select]="true" select-id="id" select-display="name"
					[select-values]="(ethnicitiesLookup$ | async)" (save)="onSavePlayerChange($event, 'ethnicityId')"
					[readOnly]="readOnly" />
			</div>
		</div>
		<div class="row">
			<div class="col">
				<hr />
			</div>
		</div>
		<div class="row gy-2">
			<div class="col-auto">
				<label class="form-label" for="cellPhoneField">
					Cell phone
				</label>

				<app-inline-edit id="cellPhone" name="cellPhone" [value]="player.telephoneCell" [is-phone]="true"
					(save)="onSavePlayerChange($event, 'telephoneCell')" [readOnly]="readOnly" />
			</div>
			<div class="col-auto">
				<label class="form-label" for="countryField">
					Country
				</label>

				<app-inline-edit id="countryField" name="countryField" [value]="player.countryId" [is-select]="true"
					select-id="id" select-display="name" [select-values]="(countriesLookup$ | async)"
					(save)="onSavePlayerChange($event, 'countryId')" [readOnly]="readOnly" />
			</div>
			<div class="col-auto">
				<label class="form-label" for="stateField">
					State
				</label>

				<app-inline-edit id="stateField" name="stateField" [value]="player.stateId" [is-select]="true"
					select-id="id" select-display="name" [select-values]="(statesLookup$ | async)"
					(save)="onSavePlayerChange($event, 'stateId')" [readOnly]="readOnly" />
			</div>
			<div class="col-auto">
				<label class="form-label" for="cityField">
					City
				</label>

				<app-inline-edit id="cityField" name="cityField" [value]="player.city" [is-text]="true"
					(save)="onSavePlayerChange($event, 'city')" [readOnly]="readOnly" />
			</div>
			<div class="col-auto">
				<label class="form-label" for="postalCodeField">
					Postal code
				</label>

				<app-inline-edit id="postalCodeField" name="postalCodeField" [value]="player.postalCode"
					[is-text]="true" (save)="onSavePlayerChange($event, 'postalCode')" [readOnly]="readOnly" />
			</div>

			<!-- <app-vertical-line /> -->

			<div class="col-auto">
				<label class="form-label" for="schoolField">
					School
				</label>

				<app-inline-edit id="schoolField" name="schoolField" [value]="player.school" [is-text]="true"
					(save)="onSavePlayerChange($event, 'school')" [readOnly]="readOnly" />
			</div>
			<div class="col-auto">
				<label class="form-label" for="gradYearField">
					HS graduation year
				</label>

				<app-inline-edit id="gradYearField" name="gradYearField" [value]="player.gradYear" [is-text]="true"
					(save)="onSavePlayerChange($event, 'gradYear')" [readOnly]="readOnly" />
			</div>
		</div>
		<div class="row">
			<div class="col">
				<hr />
			</div>
		</div>
		<div class="row gy-2">
			<div class="col-auto">
				<label class="form-label" for="heightFeetField">
					Height (ft/in)
				</label>

				<div class="d-flex align-items-center">
					<app-inline-edit id="heightField" name="heightFeetField" [value]="player.heightFeet"
						[is-text]="true" (save)="onSavePlayerChange($event, 'heightFeet')" [readOnly]="readOnly" />
					<span class="ms-1 me-4">(ft)</span>
					<app-inline-edit id="heightField" name="heightInchesField" [value]="player.heightInches"
						[is-text]="true" (save)="onSavePlayerChange($event, 'heightInches')" [readOnly]="readOnly" />
					<span class="ms-1">(in)</span>
				</div>
			</div>
			<div class="col-auto">
				<label class="form-label" for="weightField">
					Weight (lbs)
				</label>

				<app-inline-edit id="weightField" name="weightField" [value]="player.weight" [is-text]="true"
					(save)="onSavePlayerChange($event, 'weight')" [readOnly]="readOnly" />
			</div>

			<div class="col-auto">
				<label class="form-label" for="batsField">
					Bats
				</label>

				<app-inline-edit id="batsField" name="batsField" [value]="player.batsId" [is-select]="true"
					select-id="id" select-display="name" [select-values]="(handednessLookup$ | async)"
					(save)="onSavePlayerChange($event, 'batsId')" [readOnly]="readOnly" />
			</div>
			<div class="col-auto">
				<label class="form-label" for="throwsField">
					Throws
				</label>

				<app-inline-edit id="throwsField" name="throwsField" [value]="player.throwsId" [is-select]="true"
					select-id="id" select-display="name" [select-values]="(handednessLookup$ | async)"
					(save)="onSavePlayerChange($event, 'throwsId')" [readOnly]="readOnly" />
			</div>

			<div class="col-auto">
				<label class="form-label" for="numberField">
					Number
				</label>

				<app-inline-edit id="numberField" name="numberField" [value]="player.number" [is-text]="true"
					(save)="onSavePlayerChange($event, 'number')" [readOnly]="readOnly" />
			</div>
			<div class="col-auto">
				<label class="form-label" for="positionField">
					Position
				</label>

				<app-inline-edit id="positionField" name="positionField" [value]="player.positionId" [is-select]="true"
					select-id="id" select-display="name" [select-values]="(positionsLookup$ | async)"
					(save)="onSavePlayerChange($event, 'positionId')" [readOnly]="readOnly" />
			</div>
			<div class="col-auto">
				<label class="form-label" for="gamesPlayedField">
					Games played
				</label>

				<app-inline-edit id="gamesPlayedField" name="gamesPlayedField" [value]="player.gamesPlayed"
					[is-number]="true" (save)="onSavePlayerChange($event, 'gamesPlayed')" [readOnly]="readOnly" />
			</div>
		</div>
		<div class="row">
			<div class="col">
				<hr />
			</div>
		</div>
		<div class="row gy-2">
			<div class="col-auto">
				<label class="form-label" for="contactNameField">
					Emergency contact name
				</label>

				<app-inline-edit id="contactNameField" name="contactNameField" [value]="player.emergencyName"
					[is-text]="true" (save)="onSavePlayerChange($event, 'emergencyName')" [readOnly]="readOnly" />
			</div>
			<div class="col-auto">
				<label class="form-label" for="contactRelationField">
					Contact relation to child
				</label>

				<app-inline-edit id="contactRelationField" name="contactRelationField"
					[value]="player.emergencyRelation" [is-text]="true"
					(save)="onSavePlayerChange($event, 'emergencyRelation')" [readOnly]="readOnly" />
			</div>
			<div class="col-auto">
				<label class="form-label" for="contactAddressField">
					Contact address
				</label>

				<app-inline-edit id="contactAddressField" name="contactAddressField" [value]="player.emergencyAddress"
					[is-text]="true" (save)="onSavePlayerChange($event, 'emergencyAddress')" [readOnly]="readOnly" />
			</div>
			<div class="col-auto">
				<label class="form-label" for="contactCellField">
					Contact cell #
				</label>

				<app-inline-edit id="contactCellField" name="contactCellField" [value]="player.emergencyCell"
					[is-phone]="true" (save)="onSavePlayerChange($event, 'emergencyCell')" [readOnly]="readOnly" />
			</div>
		</div>
		<div class="row">
			<div class="col">
				<hr />
			</div>
		</div>
		<div class="row gy-2">
			<div class="col-auto">
				<label class="form-label" for="insCompanyField">
					Health insurance company
				</label>

				<app-inline-edit id="insCompanyField" name="insCompanyField" [value]="player.healthInsuranceCompany"
					[is-text]="true" (save)="onSavePlayerChange($event, 'healthInsuranceCompany')"
					[readOnly]="readOnly" />
			</div>
			<div class="col-auto">
				<label class="form-label" for="insPolicyField">
					Insurance policy #
				</label>

				<app-inline-edit id="insPolicyField" name="insPolicyField" [value]="player.healthInsurancePolicyNumber"
					[is-text]="true" (save)="onSavePlayerChange($event, 'healthInsurancePolicyNumber')"
					[readOnly]="readOnly" />
			</div>
			<div class="col-auto">
				<label class="form-label" for="insSubscriberField">
					Insurance subscriber
				</label>

				<app-inline-edit id="insSubscriberField" name="insSubscriberField"
					[value]="player.healthInsuranceSubscriber" [is-text]="true"
					(save)="onSavePlayerChange($event, 'healthInsuranceSubscriber')" [readOnly]="readOnly" />
			</div>
		</div>
	</div>

</div>
