import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { LeagueIconComponent } from '../entity-icons/league-icon.component';

@Component({
	selector: 'app-leagues-badge',
	template: `
	<span class="badge" [ngClass]="{ 'text-warning-emphasis bg-warning-subtle': hasWarning, 'text-success-emphasis bg-success-subtle': !hasWarning }" ngbTooltip="{{ tooltip }}"><app-league-icon class="me-1" /> {{ leagueCount | number }} @if (showLabel) { League{{ leagueCount === 1 ? '' : 's' }} }</span>
	`,
	standalone: true,
	imports: [CommonModule, FontAwesomeModule, NgbTooltipModule, LeagueIconComponent]
})
export class LeaguesBadgeComponent {
	@Input({ required: true }) leagueCount!: number;
	@Input() hasWarning: boolean = false;
	@Input() showLabel: boolean = false;
	@Input() tooltip: string = 'Number of leagues';
}
