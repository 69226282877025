import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-object-viewer',
    templateUrl: './object-viewer.component.html',
    styleUrl: './object-viewer.component.scss',
	standalone: true,
	imports: [CommonModule]
})
export class ObjectViewerComponent implements OnInit {
    @Input() object: any;
	@Input() deep: boolean = false;
    @Input() class?: string;

    ngOnInit(): void {

    }

    isPrimitive(value: any): boolean {
        return value !== Object(value);
    }

    isArray(value: any): boolean {
        return Array.isArray(value);
    }

    isObject(value: any): boolean {
        return typeof value === 'object' && !Array.isArray(value) && value !== null;
    }

    getObjectKeys(obj: any): string[] {
		// don't show keys for primitive values
		if (this.isPrimitive(obj)) {
			return [];
		}

		return Object.keys(obj).filter(key => this.deep || !this.isArray(obj[key]));
    }

    getNameForKey(key: string): string {
        switch (key) {
            // case 'entityTypeCode': return 'Entity';
            default: return key;
        }
    }
}
