import { Pipe, PipeTransform } from '@angular/core';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

@Pipe({
	name: 'phone',
	standalone: true,
})
export class PhonePipe implements PipeTransform {
	transform(value: string | number | null): string {
		if (!value) {
			return '';
		}
		// Convert value to string if it's a number
		const phoneNumber: string =
			typeof value === 'number' ? value.toString() : value;

		if (!phoneNumber.startsWith('+') && phoneNumber.length <= 10) {
			return phoneNumber.replace(/(\d{3})(\d{3})(\d{0,4})/, '($1) $2-$3');
		}

		const phoneUtil = PhoneNumberUtil.getInstance();
		let number;
		try {
			number = phoneUtil.parseAndKeepRawInput(phoneNumber);
			if (!phoneUtil.isValidNumber(number)) {
				return phoneNumber; // return original value if it's not a valid number
			}
		} catch (e) {
			return phoneNumber; // return original value if parsing fails
		}

		return phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL);
	}
}
