<svg width="100%" viewBox="0 0 900 116" xmlns="http://www.w3.org/2000/svg">
	<!-- Affiliation Application -->
	<rect class="{{ affAppClass }}" x="0" y="0" width="281" height="122" [ngbTooltip]="affAppStatusName" />
	<!-- font-family="Arial" -->
	<text x="30" y="49" font-size="30" font-weight="600" fill="#27251F" text-anchor="start">
		Affiliation
		<tspan x="30" dy="35">Application</tspan>
	</text>
	<polygon class="{{ affAppClass }}" points="280,0 310,61 280,122 280,0" />

	<!-- League Agreement -->
	<polygon class="{{ leagueAgreementClass }}" points="390,0 360,61 330,122 300,122 330,61 300,0" />
	<rect class="{{ leagueAgreementClass }}" x="330" y="0" width="261" height="122" [ngbTooltip]="leagueAgreementSubmitted ? 'Submitted' : 'Not Submitted'" />
	<text x="360" y="49" font-size="30" font-weight="600" fill="#27251F" text-anchor="start">
		League
		<tspan x="360" dy="35">Agreement</tspan>
	</text>
	<polygon class="{{ leagueAgreementClass }}" points="590,0 620,61 590,122 590,0" />

	<!-- Tournament Application -->
	<polygon class="{{ tournamentAppClass }}" points="640,0 670,61 640,122 610,122 640,61 610,0" />
	<rect class="{{ tournamentAppClass }}" x="640" y="0" width="300" height="122" [ngbTooltip]="tournamentAppStatusName" />
	<text x="670" y="49" font-size="30" font-weight="600" fill="#27251F" text-anchor="start">
		Tournament
		<tspan x="670" dy="35">Application</tspan>
	</text>
</svg>
