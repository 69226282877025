import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, Input, OnInit, booleanAttribute, inject, numberAttribute } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconDefinition, faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';

import { SpinnerComponent } from '../../components/loaders/spinner/spinner.component';

import { DocumentsService } from '../../services';

@Component({
	selector: 'app-document-thumbnail',
	templateUrl: './document-thumbnail.component.html',
	styleUrl: './document-thumbnail.component.scss',
	standalone: true,
	imports: [
		CommonModule, RouterModule, NgOptimizedImage,
		FontAwesomeModule,
		SpinnerComponent
	]
})
export class DocumentThumbnailComponent implements OnInit {
	@Input({ transform: numberAttribute }) documentFileID?: number;
	@Input() thumbnailURL?: string;
	@Input() emptyChars?: string;
	@Input() emptyIcon?: IconDefinition;
	@Input({ transform: booleanAttribute }) isCircle = true;
	@Input() bgClass = 'bg-light';
	@Input() size: 'sm' | 'lg' | null = null;

	// internal state
	protected thumbnailLoading = false;
	protected thumbnailError = false;

	// services
	private documentsService: DocumentsService = inject(DocumentsService);

	// icons
	errorIcon = faExclamationCircle;

	ngOnInit() {
		this.onLoadThumbnail();
	}

	protected async onLoadThumbnail() {
		if (this.thumbnailLoading || !this.documentFileID) return;

		this.thumbnailLoading = true;
		this.thumbnailError = false;

		try {
			const thumbnail = await this.documentsService.getFileThumbnail(this.documentFileID);
			this.thumbnailURL = URL.createObjectURL(thumbnail);
		} catch (error) {
			this.thumbnailError = true;
		} finally {
			this.thumbnailLoading = false;
		}
	}
}
