import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { YearsIconComponent } from '../entity-icons/years-icon.component';

@Component({
	selector: 'app-years-badge',
	template: `
	<span class="badge text-bg-light" ngbTooltip="{{ tooltip }}"><app-years-icon class="me-1" /> {{ leagueYears }}</span>
	`,
	standalone: true,
	imports: [CommonModule, FontAwesomeModule, NgbTooltipModule, YearsIconComponent]
})
export class YearsBadgeComponent {
	@Input({ required: true }) leagueYears!: string;
	@Input() tooltip: string = 'Affiliation years';
}
