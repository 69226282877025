import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import {
	AffiliationApplicationStatusCodes,
	TournamentApplicationStatusCodes,
} from '@app/constants';

@Component({
	selector: 'app-status',
	templateUrl: './app-status.component.html',
	styleUrl: './app-status.component.scss',
	standalone: true,
	imports: [CommonModule, NgbTooltipModule],
})
export class AppStatusComponent implements OnInit {
	AffiliationApplicationStatusCodes = AffiliationApplicationStatusCodes;
	TournamentApplicationStatusCodes = TournamentApplicationStatusCodes;

	// input properties
	@Input() affAppStatusCode!: number;
	@Input() affAppStatusName!: string;

	@Input() leagueAgreementSubmitted!: boolean;

	@Input() tournamentAppStatusCode!: number;
	@Input() tournamentAppStatusName!: string;

	// computed fields
	get affAppClass() {
		switch (this.affAppStatusCode) {
			case AffiliationApplicationStatusCodes.NotStarted:
				return 'fill-medium';
			case AffiliationApplicationStatusCodes.InProgress:
				return 'fill-warning';
			case AffiliationApplicationStatusCodes.Approved:
				return 'fill-success';
			case AffiliationApplicationStatusCodes.Rejected:
				return 'fill-danger';
			default:
				return 'fill-medium';
		}
	}
	get leagueAgreementClass() {
		return this.leagueAgreementSubmitted ? 'fill-success' : 'fill-medium';
	}
	get tournamentAppClass() {
		switch (this.tournamentAppStatusCode) {
			case TournamentApplicationStatusCodes.Draft:
				return 'fill-medium';
			case TournamentApplicationStatusCodes.InProgress:
				return 'fill-warning';
			case TournamentApplicationStatusCodes.Approved:
				return 'fill-success';
			case TournamentApplicationStatusCodes.Rejected:
				return 'fill-danger';
			default:
				return 'fill-medium';
		}
	}

	ngOnInit() {}
}
