import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { tap, catchError, lastValueFrom } from 'rxjs';

import { Environment } from '@app/environment';
import { handleHttpError } from '@app/root/app/functions';
import { SystemConstants, TeamTypeCodes } from '@app/constants';

import { GlobalFiltersService } from '@app/root/app/shared/services/global-filters.service';
import { EntityDocumentViewModel } from '@app/root/app/shared/models/document.models';
import { TeamDetailsViewModel, TeamListViewModel } from '@app/root/app/shared/models/team.models';
import { CoachListViewModel } from '@app/shared/models/coach.models';
import { RoomingListViewModel, RoomingEditModel } from '@app/shared/models/rooming.models';
import { PlayerListViewModel } from '@app/root/app/shared/models/player.models';

@Injectable({
	providedIn: 'root',
})
export class TeamsService {
	private readonly baseUrl = `${Environment.apiBaseUrl}`;

	private httpClient: HttpClient = inject(HttpClient);
	private globalFiltersService: GlobalFiltersService = inject(GlobalFiltersService);

	getTournamentTeams(leagueID: number) {
		console.log(`LOADING TOURNAMENT TEAMS => ${this.globalFiltersService.getLeagueYearID() || 'NULL'}...`);

		const httpParams = new HttpParams().appendAll({
			teamTypeId: TeamTypeCodes.Tournament,
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.get<TeamListViewModel[]>(
					`${this.baseUrl}/leagues/${leagueID}`,
					{ params: httpParams })
				.pipe(
					tap(teams => {
						teams.forEach(team => this.computeTeamProperties(team));
					}),
					catchError(handleHttpError)
				)
		);
	}

	getTeamDetails(leagueID: number, teamID: number) {
		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.get<TeamDetailsViewModel>(
					`${this.baseUrl}/leagues/${leagueID}/teams/${teamID}`,
					{ params: httpParams })
				.pipe(
					tap(team => this.computeTeamProperties(team)),
					catchError(handleHttpError)
				)
		);
	}

	getTeamDocuments(teamID: number) {
		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.get<EntityDocumentViewModel[]>(
					`${this.baseUrl}/teams/${teamID}/documents`,
					{ params: httpParams })
				.pipe(catchError(handleHttpError))
		);
	}

	getTeamCoaches(teamID: number) {
		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.get<CoachListViewModel[]>(
					`${this.baseUrl}/teams/${teamID}/coaches`,
					{ params: httpParams })
				.pipe(
					tap(coaches => coaches.forEach(coaches => TeamsService.ComputePlayerCoachProperties(coaches))),
					catchError(handleHttpError)
				)
		);
	}

	getTeamPlayers(teamID: number, includeDeleted: boolean) {
		const httpParams = new HttpParams().appendAll({
			includeDeleted: includeDeleted.toString(),
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.get<PlayerListViewModel[]>(
					`${this.baseUrl}/teams/${teamID}/players`,
					{ params: httpParams })
				.pipe(
					tap(players => players.forEach(player => TeamsService.ComputePlayerCoachProperties(player))),
					catchError(handleHttpError)
				)
		);
	}

	addPlayerToTeam(teamID: number, playerTeamID: number) {
		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.post<PlayerListViewModel>(
					`${this.baseUrl}/teams/${teamID}/addplayer/${playerTeamID}`, null, { params: httpParams })
				.pipe(
					tap(player => TeamsService.ComputePlayerCoachProperties(player)),
					catchError(handleHttpError)
				)
		);
	}

	removePlayerFromTeam(teamID: number, playerTeamID: number) {
		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.put<PlayerListViewModel>(
					`${this.baseUrl}/players/${playerTeamID}/false`, null, { params: httpParams })
				.pipe(
					tap(player => TeamsService.ComputePlayerCoachProperties(player)),
					catchError(handleHttpError)
				)
		);
	}

	downloadTeamPlayersReport(teamID: number, includeDeleted: boolean) {
		const httpParams = new HttpParams().appendAll({
			includeDeleted: includeDeleted.toString(),
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.get<Blob>(
					`${this.baseUrl}/teams/${teamID}/players/report`,
					{ params: httpParams, responseType: 'blob' as 'json' })
				.pipe(catchError(handleHttpError))
		);
	}

	getTeamRooming(teamID: number) {
		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.get<RoomingListViewModel[]>(
					`${this.baseUrl}/teams/${teamID}/rooming`,
					{ params: httpParams })
				.pipe(catchError(handleHttpError))
		);
	}

	saveRooming(teamID: number, roomingList: RoomingEditModel[]): Promise<RoomingListViewModel[]> {
		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.post<RoomingListViewModel[]>(
					`${this.baseUrl}/teams/${teamID}/rooming`, roomingList, { params: httpParams })
				.pipe(catchError(handleHttpError))
		);
	}

	downloadTeamRoomingReport(teamID: number) {
		const httpParams = new HttpParams().appendAll({
			regionId: '',
			yearId: this.globalFiltersService.getLeagueYearID() ?? ''
		});

		return lastValueFrom(
			this.httpClient
				.get<Blob>(
					`${this.baseUrl}/teams/${teamID}/rooming/report`,
					{ params: httpParams, responseType: 'blob' as 'json' })
				.pipe(catchError(handleHttpError))
		);
	}

	private computeTeamProperties(team: TeamListViewModel | TeamDetailsViewModel) {
		// move teamDocuments to documents (model mismatch on the backend for different endpoints)
		if (team.teamDocuments) {
			(<any>team).documents = team.teamDocuments;
		}

		team.totalCount = team.teamTotalCount + team.playersTotalCount + team.coachesTotalCount;

		team.hasPlayerWarning = team.playersOnTeam < SystemConstants.TeamPlayersMin;
		team.hasPlayerSuccess = team.playersOnTeam >= SystemConstants.TeamPlayersMin;

		team.hasCoachWarning = team.coachesOnTeam < SystemConstants.TeamCoachesMin;
		team.hasCoachSuccess = team.coachesOnTeam >= SystemConstants.TeamCoachesMin;

		const badCount = team.totalNotStartedCount + team.totalRejectedCount;
		const goodCount = team.totalInProgressCount + team.totalApprovedCount;
		team.hasDocumentWarning = badCount > 0;
		team.hasDocumentSuccess = badCount === 0 && goodCount >= team.totalCount && team.totalCount > 0;
	}

	public static ComputePlayerCoachProperties(entity: PlayerListViewModel | CoachListViewModel) {
		entity.totalDocumentCount = entity.notStartedCount + entity.inProgressCount + entity.rejectedCount + entity.approvedCount;

		const badCount = entity.notStartedCount + entity.rejectedCount;
		const goodCount = entity.inProgressCount + entity.approvedCount;
		entity.hasDocumentWarning = badCount > 0;
		entity.hasDocumentSuccess = badCount === 0 && goodCount >= entity.totalDocumentCount && entity.totalDocumentCount > 0;
	}
}
