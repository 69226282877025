import { Component } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faAsterisk } from '@fortawesome/pro-regular-svg-icons';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-required-icon',
	template: '<fa-icon class="text-danger small" [icon]="icon" ngbTooltip="Required" />',
	standalone: true,
	imports: [FontAwesomeModule, NgbTooltipModule]
})
export class RequiredIconComponent {
	icon = faAsterisk;
}
