import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, OnChanges, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faAngleLeft, faAngleRight, faPlus, faSquareExclamation, faXmark, faArrowUp } from '@fortawesome/pro-regular-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { jsPDF } from "jspdf";

import { SpinnerComponent } from '@app/shared/components';
import {
	AlertService,
	ConfirmationService,
	// PdfService,
	TeamsService,
	CoachesService,
	PlayersService,
	DocumentsService,
	AffAppService
} from '@app/shared/services';
import { EntityDocumentViewModel, AffAppDocumentViewModel } from '@app/shared/models';
import { SafePipe, ShortNumberPipe } from '@app/shared/pipes';

interface DocumentEntity {
	title: string;
	details: string | null;
	group: string | null;

	leagueID: number;
	teamID: number;
	coachID: number | null;
	playerTeamID: number | null;
}

@Component({
	selector: 'app-doc-upload-page',
	templateUrl: './document-upload.page.html',
	styleUrl: './document-upload.page.scss',
	standalone: true,
	imports: [
		CommonModule, FormsModule, FontAwesomeModule, NgbDropdownModule, NgSelectModule,
		SafePipe, ShortNumberPipe,
		SpinnerComponent
	]
})
export class DocumentUploadPage implements OnInit, OnChanges {
	@Input({ required: true }) leagueID!: number;
	@Input() leagueName?: string;
	@Input() teamID?: number;
	@Input() teamName?: string;
	@Input() coachID?: number;
	@Input() coachName?: string;
	@Input() teamPlayerID?: number;
	@Input() teamPlayerName?: string;
	@Input() affAppID?: number;

	@Input() documentInstanceID?: number;
	@Input() affAppDocumentTypeID?: number;
	@Input() documentName?: string;

	@Input() files?: File[] = [];

	// computed properties
	get isUploadForLeague() {
		return !!this.leagueID && !this.teamID && !this.teamPlayerID;
	}
	get isUploadForTeam() {
		return !!this.leagueID && !!this.teamID && !this.teamPlayerID;
	}
	get isUploadForCoach() {
		return !!this.leagueID && !!this.teamID && !!this.coachID;
	}
	get isUploadForPlayer() {
		return !!this.leagueID && !!this.teamID && !!this.teamPlayerID;
	}
	get anyNonImages() {
		return this.files?.some(file => !file.type.startsWith('image/'));
	}
	get hasMultipleNonImages() {
		return this.anyNonImages && this.fileUrls!.length > 1;
	}
	get canUpload() {
		return (
			// either uploading for selected entity/doc type
			(this.selectedDocumentEntity && this.selectedDocumentInstance) ||
			// or for pre-selected document instance
			this.documentInstanceID ||
			// or for specific affiliation application document type
			this.affAppDocumentTypeID)
			&& !this.hasMultipleNonImages;
	}

	// internal state
	fileUrls: string[] | ArrayBuffer[] | null = null;
	fileNames: string[] | null = null;
	currentFileIndex = 0;
	get isSingleFile() {
		return (this.fileUrls?.length ?? 0) === 1;
	}

	documentEntities: DocumentEntity[] | null = null;
	documentEntitiesLoading = false;
	documentEntitiesError = false;
	selectedDocumentEntity: DocumentEntity | null = null;

	documentInstances: EntityDocumentViewModel[] | null = null;
	documentInstancesLoading = false;
	documentInstancesError = false;
	selectedDocumentInstance: EntityDocumentViewModel | null = null;

	documentUploading = false;

	hideMultiImageAlert = localStorage.getItem('hideMultiImageAlert') === 'true';

	// services
	private toastrService: ToastrService = inject(ToastrService);
	private modal: NgbActiveModal = inject(NgbActiveModal);
	private alertService: AlertService = inject(AlertService);
	private confirmationService: ConfirmationService = inject(ConfirmationService);
	// private pdfService: PdfService = inject(PdfService);
	private teamsService: TeamsService = inject(TeamsService);
	private coachesService: CoachesService = inject(CoachesService);
	private playersService: PlayersService = inject(PlayersService);
	private documentsService: DocumentsService = inject(DocumentsService);
	private affAppService: AffAppService = inject(AffAppService);

	// icons
	previousIcon = faAngleLeft;
	nextIcon = faAngleRight;
	addIcon = faPlus;
	alertIcon = faSquareExclamation;
	closeIcon = faXmark;
	uploadIcon = faArrowUp;

	ngOnInit() {
		this.processRawFiles();
		if (!this.enforceOversizedFileLimit()) {
			this.modal?.dismiss();
			return;
		}

		if (!this.documentInstanceID) {
			this.loadDocumentEntities();
		}
	}

	ngOnChanges() {
		this.processRawFiles();
	}

	private async processRawFiles() {
		if (this.files) {
			// ensure all images are resized to a reasonable size
			const processedFiles: File[] = [];
			for (const file of this.files) {
				if (this.isImageFile(file)) {
					const resizedFile = await this.resizeImageFile(file);
					processedFiles.push(resizedFile);
					// TODO: UNCOMMENT ONCE HAVE A PDF SERVICE THAT CAN DOWNSIZE IMAGE PDFS
					// } else if (file.type === 'application/pdf') {
					// 	const resizedFile = await this.pdfService.processLargePdf(file);
					// 	processedFiles.push(resizedFile);
				} else {
					processedFiles.push(file);
				}
			}
			this.files = processedFiles;

			// extract file URLs
			this.fileUrls = this.files.map(file => URL.createObjectURL(file));
			// shorten file names
			this.fileNames = this.files.map(file => file.name.length > 20 ? file.name.substring(0, 20) + '...' : file.name);
		} else {
			this.fileUrls = null;
			this.fileNames = null;
		}
	}

	async loadDocumentEntities() {
		if (this.documentEntitiesLoading) return;

		try {
			this.documentEntitiesLoading = true;
			this.documentEntitiesError = false;

			this.documentEntities = null;
			this.selectedDocumentEntity = null;

			// TODO: GET FROM A UNIFIED LOOKUP ENDPOINT
			const entities: DocumentEntity[] = [];
			if (this.isUploadForLeague) {
				const teams = await this.teamsService.getTournamentTeams(this.leagueID);
				entities.push(...teams.map(team => ({
					title: team.teamName,
					details: null,
					group: 'Teams',

					leagueID: this.leagueID,
					teamID: team.teamId,
					coachID: null,
					playerTeamID: null
				})));

				for (const team of teams) {
					entities.push(...await this.getCoachDocumentEntities(team.teamId, team.teamName));
					entities.push(...await this.getPlayerDocumentEntities(team.teamId, team.teamName));
				}
			} else if (this.isUploadForTeam) {
				entities.push({
					title: this.teamName || 'TEAMNAME',
					details: null,
					group: null, //'Teams',

					leagueID: this.leagueID,
					teamID: this.teamID!,
					coachID: null,
					playerTeamID: null
				});
				entities.push(...await this.getCoachDocumentEntities(this.teamID!, null));
				entities.push(...await this.getPlayerDocumentEntities(this.teamID!, null));
			} else if (this.isUploadForCoach) {
				this.selectedDocumentEntity = {
					title: 'COACHNAME',
					details: null,
					group: null, //'Coaches',

					leagueID: this.leagueID,
					teamID: this.teamID!,
					coachID: this.coachID!,
					playerTeamID: null
				};
			} else if (this.isUploadForPlayer) {
				this.selectedDocumentEntity = {
					title: 'PLAYERNAME',
					details: null,
					group: null, //'Players',

					leagueID: this.leagueID,
					teamID: this.teamID!,
					coachID: null,
					playerTeamID: this.teamPlayerID!
				};
			}

			this.documentEntities = entities;
		} catch (error) {
			this.documentEntitiesError = true;
			this.toastrService.error('Failed to initialize \'Who is it for?\' dropdown.');
		} finally {
			this.documentEntitiesLoading = false;
		}
	}

	private async getCoachDocumentEntities(teamID: number, teamName: string | null) {
		const coaches = await this.teamsService.getTeamCoaches(teamID);
		const entities = coaches.map(coach => ({
			title: coach.fullName,
			details: teamName,
			group: 'Coaches' + (teamName ? ` - ${teamName}` : ''),

			leagueID: this.leagueID,
			teamID: teamID,
			coachID: coach.coachId,
			playerTeamID: null
		}));
		return entities;
	}

	private async getPlayerDocumentEntities(teamID: number, teamName: string | null) {
		const players = await this.teamsService.getTeamPlayers(teamID, false);
		const entities = players.map(player => ({
			title: player.fullName,
			details: teamName,
			group: 'Players' + (teamName ? ` - ${teamName}` : ''),

			leagueID: this.leagueID,
			teamID: teamID,
			coachID: null,
			playerTeamID: player.playerTeamId
		}));
		return entities;
	}

	async loadDocumentInstances() {
		if (!this.selectedDocumentEntity || this.documentInstancesLoading) return;

		console.log('loading document instances for', this.selectedDocumentEntity);

		try {
			this.documentInstancesLoading = true;
			this.documentInstancesError = false;

			this.documentInstances = null;
			this.selectedDocumentInstance = null;

			let docs: EntityDocumentViewModel[] = [];
			if (this.selectedDocumentEntity.playerTeamID) {
				docs = await this.playersService.getPlayerDocuments(this.selectedDocumentEntity.playerTeamID);
			} else if (this.selectedDocumentEntity.coachID) {
				docs = await this.coachesService.getCoachDocuments(this.selectedDocumentEntity.coachID);
			} else if (this.selectedDocumentEntity.teamID) {
				docs = await this.teamsService.getTeamDocuments(this.selectedDocumentEntity.teamID);
			}

			// keep only latest document version based on isCurrentVersion
			this.documentInstances = docs.filter(doc => doc.isCurrentVersion);
		} catch (error) {
			this.documentInstancesError = true;
			this.toastrService.error('Failed to initialize \'Document type\' dropdown.');
		} finally {
			this.documentInstancesLoading = false;
		}
	}

	onPrevious() {
		this.currentFileIndex = Math.max(0, this.currentFileIndex - 1);
	}

	onNext() {
		this.currentFileIndex = Math.min((this.fileUrls?.length ?? 1) - 1, this.currentFileIndex + 1);
	}

	onFileInput(event: Event) {
		const element = event.currentTarget as HTMLInputElement;
		const files: File[] = Array.from(element.files || []);
		if (files?.length > 0) {
			const newIndex = (this.files ?? []).length;

			const previousFiles = [...(this.files ?? [])];
			this.files = [...(this.files ?? []), ...files];
			this.processRawFiles();

			// prevent file sizes larger than 7MB per file
			if (!this.enforceOversizedFileLimit()) {
				this.files = previousFiles;
			} else {
				this.currentFileIndex = newIndex;
			}
		}
	}

	/*
		Enforce a limit of 10MB per file.
		Returns true if all files are within the limit, false otherwise.
	*/
	private enforceOversizedFileLimit() {
		// prevent file sizes larger than 10MB per file
		const maxSize = 10 * 1024 * 1024;
		const oversizedFiles = this.files?.filter(file => file.size > maxSize);
		if (oversizedFiles && oversizedFiles.length > 0) {
			const pluralSuffix = oversizedFiles.length > 1 ? 's' : '';
			let message = `
			You've selected ${oversizedFiles.length} file${pluralSuffix} larger than <strong>10MB</strong>.<br/><br/>
			Please resize your file${pluralSuffix} and try uploading again (or let us know if you need help resizing).<br/><br/>
			<ul>`;
			oversizedFiles.forEach(f => message += `<li>${f.name} (<strong class='text-danger'>${(f.size / 1024 / 1024).toFixed(2)}MB</strong>)</li>`);
			message += '</ul>';

			this.alertService.show('File Size Limit Exceeded', message, 'OK', 'btn-danger');

			return false;
		}

		return true;
	}

	onResetFileInput(fileInput: HTMLInputElement) {
		// clear the input (so user can select the same file after cancelling out)
		fileInput.value = '';
	}

	onRemoveCurrentPageFile() {
		const pageNumber = this.currentFileIndex + 1;
		this.confirmationService.confirm(
			`Remove Page ${pageNumber}?`,
			`Are you sure you want to remove page ${pageNumber} from this document?`,
			'Remove',
			'btn-danger',
			() => {
				this.files?.splice(this.currentFileIndex, 1);
				this.processRawFiles();
				this.currentFileIndex = this.currentFileIndex > 0 ? this.currentFileIndex - 1 : 0;
				return Promise.resolve(true);
			});
	}

	onDismissMultiImageAlert() {
		localStorage.setItem('hideMultiImageAlert', 'true');
	}

	async onUploadDocument() {
		if (!this.canUpload || this.documentUploading) return;

		try {
			this.documentUploading = true;

			// merge multiple image files into a single PDF (if needed)
			let documentFile: File | Blob | null;
			let documentFileName: string;
			if (!this.isSingleFile && !this.anyNonImages) {
				documentFile = await this.mergeImagesToPDF();
				documentFileName = this.files!.map(file => file.name).join('_') + '.pdf';
				if (!documentFile) {
					this.toastrService.warning('Failed to merge images into a single PDF.');
					return;
				}
			} else {
				documentFile = this.files![this.currentFileIndex];
				documentFileName = (documentFile as File).name;
			}

			let result: number | AffAppDocumentViewModel;
			if (this.affAppDocumentTypeID) {
				// upload for specific affiliation application document type
				result = await this.affAppService.uploadAffAppDocument(
					this.affAppID ?? 0,
					this.affAppDocumentTypeID,
					documentFile
				);
			} else if (this.documentInstanceID) {
				// upload to pre-selected document instance
				result = await this.documentsService.uploadDocument(
					this.documentInstanceID,
					this.teamID ?? null,
					this.coachID ?? null,
					this.teamPlayerID ?? null,
					documentFile,
					documentFileName
				);
			} else {
				// upload to selected document instance
				result = await this.documentsService.uploadDocument(
					this.selectedDocumentInstance!.documentInstanceId,
					this.selectedDocumentInstance!.teamId,
					this.selectedDocumentInstance!.coachId,
					this.selectedDocumentInstance!.playerTeamId,
					documentFile,
					documentFileName
				);
			}

			this.toastrService.success('Document uploaded successfully.');
			this.modal?.close(result);
		} catch (error) {
			// TODO: LOG ERROR IN CASE NOT API ERROR
			this.toastrService.error('Document Upload Error', 'Unexpected error uploading the document. Please try again.');
		} finally {
			this.documentUploading = false;
		}
	}

	onCancel() {
		this.modal?.dismiss();
	}

	private async mergeImagesToPDF() {
		if (!this.files) return null;

		// Initialize variables to hold the jsPDF instance
		let pdf = new jsPDF();

		for (let i = 0; i < this.files.length; i++) {
			const file = this.files[i];
			const imgData = await this.readFileAsDataURL(file);
			const imgProps = await this.getImageProps(imgData);
			const { width, height, orientation } = this.getAdjustedDimensions(imgProps);

			if (i > 0) {
				// Add a new page with the correct orientation for the next image
				pdf.addPage([width, height], orientation);
			} else {
				// For the first image, adjust the initial PDF setup
				pdf = new jsPDF(orientation, 'pt', [width, height]);
			}

			pdf.addImage(imgData, 'JPEG', 0, 0, width, height, undefined, 'FAST');

			// Do not add a new page after the last image has been processed
		}

		// convert PDF to blob
		return pdf.output('blob');
	}

	private readFileAsDataURL(file: File): Promise<string> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => resolve(reader.result as string);
			reader.onerror = reject;
			reader.readAsDataURL(file);
		});
	}

	// Utility function to get image dimensions
	private getImageProps(imgData: string): Promise<{ width: number, height: number }> {
		return new Promise(resolve => {
			const img = new Image();
			img.onload = () => resolve({ width: img.width, height: img.height });
			img.src = imgData;
		});
	}

	private getAdjustedDimensions({ width, height }: { width: number, height: number }): { width: number, height: number, orientation: 'p' | 'l' } {
		// Standard letter size dimensions in points (72 points per inch)
		const portrait = { width: 8.5 * 72, height: 11 * 72 };
		const landscape = { width: 11 * 72, height: 8.5 * 72 };

		// Image aspect ratio
		const imageAspectRatio = width / height;

		// Letter page aspect ratios
		const portraitAspectRatio = portrait.width / portrait.height;
		const landscapeAspectRatio = landscape.width / landscape.height;

		// Determine if the image is closer to portrait or landscape orientation by comparing aspect ratios
		const isPortrait = Math.abs(imageAspectRatio - portraitAspectRatio) < Math.abs(imageAspectRatio - landscapeAspectRatio);

		// Target dimensions based on chosen orientation
		const target = isPortrait ? portrait : landscape;

		// Calculate scaling factor
		const scalingFactor = Math.min(target.width / width, target.height / height);

		// If the image is smaller than the target, don't scale up (ensure scaling factor is at most 1)
		const scale = Math.min(1, scalingFactor);

		// Apply scaling factor
		const newWidth = width * scale;
		const newHeight = height * scale;

		// Return new dimensions, ensuring the image fits within the selected page size
		return { width: newWidth, height: newHeight, orientation: isPortrait ? 'p' : 'l' };
	}

	private isImageFile(file: File): boolean {
		return file.type.startsWith('image/');
	}

	private async resizeImageFile(file: File): Promise<File> {
		const MAX_SIZE = 1024 * 750; // 750KB
		let blob: Blob = file;

		while (blob.size > MAX_SIZE) {
			const canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d');
			const img = await new Promise<HTMLImageElement>((resolve, reject) => {
				const image = new Image();
				image.onload = () => resolve(image);
				image.onerror = reject;
				image.src = URL.createObjectURL(blob);
			});

			const scaleFactor = 0.9; // Reduce dimensions by 10% each iteration
			canvas.width = img.width * scaleFactor;
			canvas.height = img.height * scaleFactor;
			ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);

			blob = await new Promise<Blob>((resolve, reject) => {
				canvas.toBlob((resultBlob) => {
					if (resultBlob) {
						resolve(resultBlob);
					} else {
						reject(new Error('Canvas to Blob conversion failed'));
					}
				}, 'image/jpeg', 0.9); // Adjust the quality to reduce file size
			});
		}

		return new File([blob], file.name, { type: 'image/jpeg', lastModified: Date.now() });
	}
}
