import { Component, Input } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-regular-svg-icons';

@Component({
	selector: 'app-expand-icon',
	template: '<fa-icon [icon]="expanded ? expandedIcon : collapsedIcon" />',
	standalone: true,
	imports: [FontAwesomeModule]
})
export class ExpandIconComponent {
	@Input({ required: true }) expanded!: boolean;

	collapsedIcon = faAngleDown;
	expandedIcon = faAngleUp;
}
