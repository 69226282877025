<div class="row mb-3">
	<div class="col d-flex align-items-center gap-3">
		<h1 class="mb-0">Forum</h1>
		<!-- TODO: REENABLE SEARCH ONCE AVAILABLE AT API -->
		<!-- @if (postSearchActive) {
		<div class="input-group">
			<input class="form-control form-control-search" type="search" autocomplete="noautocomplete"
				placeholder="Search posts & comments..." aria-label="Post & comment search" [ngModel]="postSearchText"
				(ngModelChange)="postSearchChange.next($event)">
			<button class="btn btn-outline-secondary" type="button"
				(click)="onTogglePostSearch()"><app-cancel-icon /></button>
		</div>
		} @else {
		<button class="btn btn-lg text-secondary border-0 p-1" (click)="onTogglePostSearch()">
			<fa-icon [icon]="searchIcon" />
		</button>
		} -->
	</div>
	<div class="col-auto d-flex align-items-center gap-3">
		<div class="col-auto d-flex align-items-center gap-2">
			<label class="form-label mb-0 d-none d-sm-block" for="sortByDropdown">Sort by</label>
			<div class="nav-item dropdown" routerLinkActive="active" ngbDropdown>
				<a id="sortByDropdown" class="nav-link dropdown-toggle text-decoration-dotted" href="#" role="button"
					data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
					<span class="d-none d-sm-inline">
						@switch (postSortCode) {
						@case (ForumPostSortCodes.Newest) { Newest First }
						@case (ForumPostSortCodes.Oldest) { Oldest First }
						@default { ????? }
						}
					</span>
					<fa-icon class="ms-1 d-none d-sm-inline" [icon]="dropdownIcon" />
					<fa-icon class="px-3 text-secondary d-inline d-sm-none" [icon]="sortIcon" />
				</a>
				<div class="dropdown-menu" aria-labelledby="sortByDropdown" ngbDropdownMenu>
					<button class="dropdown-item" ngbDropdownItem
						(click)="onPostSortChange(ForumPostSortCodes.Newest)">Newest First</button>
					<button class="dropdown-item" ngbDropdownItem
						(click)="onPostSortChange(ForumPostSortCodes.Oldest)">Oldest First</button>
				</div>
			</div>
		</div>
		<div class="col-auto text-end">
			<!-- desktop -->
			<button class="btn btn-lg btn-primary d-none d-sm-inline-block" [routerLink]="['/forum/post/edit']"
				[queryParams]="{ postID: null }">
				<fa-icon class="me-sm-2" [icon]="newPostIcon" /> Add Post
			</button>
			<!-- mobile -->
			<button class="btn btn-primary d-inline-block d-sm-none" [routerLink]="['/forum/post/edit']"
				[queryParams]="{ postID: null }">
				<fa-icon class="me-sm-2" [icon]="newPostIcon" />
			</button>
		</div>
	</div>
</div>
<div class="row mb-5">
	<div class="col">
		@if (postsLoading) {
		<app-card-placeholder [hasTitle]="true" [hasFooter]="true" [bodyRowItemCounts]="[[3],[1]]" />
		} @else if (postsError) {
		<app-error message="Error loading posts" (retry)="onLoadPosts(true)" />
		} @else if (!posts?.length) {
		<div class="card">
			<div class="card-body">
				<app-empty message="There are no forum posts." />
			</div>
		</div>
		} @else {
		<div infiniteScroll (scrolled)="onLoadPosts(false)">
			@for (post of posts; track post.postID; let isLast = $last) {
			<div class="card" [class.mb-4]="!isLast">
				<div class="card-body p-sm-4" [class.pb-2]="post.allowComments">
					<div class="d-flex align-items-center justify-content-between mb-2">
						<div class="text-secondary">
							{{ post.postedDateTime | date:'MMM dd, yyyy' | uppercase }}
						</div>
						<div class="hstack gap-2 h4 mb-0">
							@if (post.isPinned) {
							<span class="badge text-bg-warning">
								<fa-icon class="me-1" [icon]="pinnedIcon" /> <span
									class="d-none d-sm-inline">Pinned</span>
							</span>
							}
							<span class="badge" [class.text-bg-dark]="post.hasUnreadComments"
								[class.text-bg-light]="!post.hasUnreadComments">
								<fa-icon class="me-1" [icon]="commentIcon" /> {{ post.commentCount }}
							</span>
							<span class="badge c-pointer" [class.text-bg-primary]="post.isLikedByMe"
								[class.text-bg-light]="!post.isLikedByMe"
								ngbTooltip="{{ post.isLikedByMe ? 'Remove like' : 'Like post' }}"
								(click)="onTogglePostLiked(post)">
								{{ post.likeCount }}
								@if (post.togglingLike) {
								<app-spinner class="ms-1" />
								} @else {
								<fa-icon class="ms-1" [icon]="post.isLikedByMe ? upvotedIcon : upvoteIcon" />
								}
							</span>
						</div>
					</div>
					<h1 class="card-title mb-3">{{ post.title }}</h1>
					<div class="mb-3 fs-5" [innerHTML]="post.content"></div>
					<div class="d-flex align-items-center justify-content-between">
						<div class="hstack gap-2 text-secondary">
							<div class="d-flex align-items-center gap-2">
								@if (post.isMyPost) { <span class="badge text-bg-light ms-2">You</span> }
								@else { <span class="text-dark">{{ post.postedByFirstName }} {{ post.postedByLastName[0]
									}}</span> }
								<span>{{ post.postedDateTime | shortTimeAgo }}</span>
							</div>
							@if (post.lastEditedDateTime) {
							<app-vertical-line />
							<div><fa-icon class="me-1 text-secondary" [icon]="lastEditedIcon"
									ngbTooltip="Last edited" /> {{
								post.lastEditedDateTime | shortTimeAgo }}</div>
							}
							@if (post.lastCommentDateTime) {
							<app-vertical-line />
							<div><fa-icon class="me-1 text-secondary" [icon]="lastCommentIcon"
									ngbTooltip="Last commented" /> {{
								post.lastCommentDateTime | shortTimeAgo }}</div>
							}
						</div>
						<div class="dropdown" placement="bottom-end" ngbDropdown>
							<button id="postActionsDropdownButton{{ post.postID }}" class="btn btn-sm" type="button"
								data-bs-toggle="dropdown" aria-expanded="false"
								[attr.aria-controls]="'postActionsDropdown' + post.postID" ngbDropdownToggle><fa-icon
									[icon]="actionsIcon" /></button>
							<ul id="postActionsDropdown{{ post.postID }}" class="dropdown-menu" role="menu"
								[attr.aria-labelledby]="'postActionsDropdownButton' + post.postID" ngbDropdownMenu>
								@if (post.isMyPost) { <li class="c-pointer" role="menuitem" ngbDropdownItem
									[routerLink]="['/forum/post/edit']" [queryParams]="{ postID: post.postID }"><a
										class="dropdown-item">Edit</a></li> }
								@if (post.isMyPost) { <li class="c-pointer" role="menuitem" (click)="onDeletePost(post)"
									ngbDropdownItem><a class="dropdown-item text-danger">Delete</a></li> }
								@if (!post.isMyPost) { <li class="c-pointer" role="menuitem"
									(click)="onReportPost(post)" ngbDropdownItem><a class="dropdown-item">Report</a>
								</li> }
							</ul>
						</div>
					</div>
				</div>
				@if (post.allowComments) {
				@defer (on viewport) {
				<app-forum-comments [postID]="post.postID" (commentsChange)="onCommentsChange(post, $event)" />
				} @placeholder {
				<app-spinner />
				}
				}
			</div>
			}
		</div>
		}
	</div>
</div>
