import { CommonModule } from '@angular/common';
import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { Observable, Subject, Subscription, skip, debounceTime, distinctUntilChanged, switchMap, catchError, of, tap, throwError } from 'rxjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faPlus, faUserLock } from '@fortawesome/pro-regular-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { GlobalFiltersService, AuthService, LookupService, LeaguesService } from '@app/shared/services';
// import { LeagueDetailsService } from '@app/leagues/services';

import { SpinnerComponent } from '../../../components/loaders/spinner/spinner.component';
import { TablePlaceholderComponent } from '../../../components/loaders/table-placeholder/table-placeholder.component';
import { CardPlaceholderComponent } from '../../../components/loaders/card-placeholder/card-placeholder.component';
import { ErrorComponent } from '../../../components/error/error.component';
import { EmptyComponent } from '../../../components/empty/empty.component';
import { DocumentActionsComponent } from '../../document-actions/document-actions.component';
import { DetailsIconComponent } from '../../action-icons/details-icon.component';
import { StatusIconComponent, StatusTypes } from '../../status-icon/status-icon.component';
import { TeamsBadgeComponent } from '../../../components/entity-badges/teams-badge.component';
import { PlayersBadgeComponent } from '../../../components/entity-badges/players-badge.component';
import { CoachesBadgeComponent } from '../../../components/entity-badges/coaches-badge.component';
import { DocumentsBadgeComponent } from '../../../components/entity-badges/documents-badge.component';
import { LocationBadgeComponent } from '../../../components/entity-badges/location-badge.component';
import { YearsBadgeComponent } from '../../../components/entity-badges/years-badge.component';
import { AppStatusComponent } from '../../../components/app-status/app-status.component';
import { DocumentThumbnailComponent } from '../../../components/document-thumbnail/document-thumbnail.component';

import { ForumComponent } from '../../../components/data/forum/forum.component';

import { PagedModel } from '@app/shared/models';
import { LeagueListViewModel } from '../../../models/league.models';

import { LookupView } from '../../../models';

import {
	LookupCodes,
	Messages,
	AppPermissionCodes,
	LeagueFilterCategoryCodes,
	LeagueAgreementStatusCodes
} from '@app/constants';

interface LeagueFilters {
	categoryID: number | null;
	q: string | null;
	regionID: number | null;
	zoneID: number | null;
}

// TODO: MOVE THIS INTO THE LEAGUES MODULE

@Component({
	selector: 'app-leagues',
	templateUrl: './leagues.component.html',
	styleUrl: './leagues.component.scss',
	standalone: true,
	imports: [
		CommonModule, RouterModule, FormsModule, FontAwesomeModule, NgbTooltipModule, InfiniteScrollModule,
		SpinnerComponent, TablePlaceholderComponent, CardPlaceholderComponent, ErrorComponent, EmptyComponent, DocumentActionsComponent, DetailsIconComponent, StatusIconComponent, TeamsBadgeComponent, PlayersBadgeComponent, CoachesBadgeComponent, DocumentsBadgeComponent, LocationBadgeComponent, YearsBadgeComponent,
		AppStatusComponent, DocumentThumbnailComponent, ForumComponent
	]
})
export class LeaguesComponent implements OnInit, OnDestroy {
	// internal state
	leagues: LeagueListViewModel[] = [];
	leaguesTotal: number | null = null;
	leaguesLoading = false;
	leaguesError = false;
	leaguesCurrentPage = 1;

	private leagueYearSubscription: Subscription | null = null;

	filters: LeagueFilters = {
		categoryID: LeagueFilterCategoryCodes.All,
		q: null,
		regionID: null,
		zoneID: null
	};
	searchChange = new Subject<string>();
	searchSubscription: Subscription | null = null;

	// services
	private toastrService: ToastrService = inject(ToastrService);
	protected globalFiltersService: GlobalFiltersService = inject(GlobalFiltersService);
	protected authService: AuthService = inject(AuthService);
	protected lookupService: LookupService = inject(LookupService);
	private leaguesService: LeaguesService = inject(LeaguesService);
	// protected leagueDetailsService: LeagueDetailsService = inject(LeagueDetailsService);

	// lookups
	filterCategoriesLookup$!: Observable<LookupView[]>;
	filterCategoriesLookupLoading = true;

	regionsLookup$!: Observable<LookupView[]>;
	regionsLookupLoading = true;

	zonesLookup$!: Observable<LookupView[]>;
	zonesLookupLoading = true;

	// icons
	protected addIcon = faPlus;
	protected guestUserIcon = faUserLock;

	// constants
	AppPermissionCodes = AppPermissionCodes;
	LeagueFilterCategoryCodes = LeagueFilterCategoryCodes;
	StatusTypes = StatusTypes;
	LeagueAgreementStatusCodes = LeagueAgreementStatusCodes;

	constructor() {
		this.initializeLookups();
	}

	ngOnInit() {
		this.leagueYearSubscription = this.globalFiltersService.getLeagueYear$()
			.subscribe(() => {
				if (!this.globalFiltersService.getLeagueYearID()) {
					return;
				}
				this.onLoadLeagues(true);
			});
		this.connectLeagueSearch();
	}

	ngOnDestroy() {
		this.leagueYearSubscription?.unsubscribe();
	}

	private initializeLookups() {
		this.filterCategoriesLookup$ = this.lookupService.getLookup<LookupView>(LookupCodes.FilterCategories)
			.pipe(
				tap(() => setTimeout(() => this.filterCategoriesLookupLoading = false, 0)),
				catchError(err => {
					setTimeout(() => this.filterCategoriesLookupLoading = false, 0);
					return this.showLookupErrorToast('Filter Categories', err);
				})
			);

		this.regionsLookup$ = this.lookupService.getLookup<LookupView>(LookupCodes.Regions)
			.pipe(
				tap(() => setTimeout(() => this.regionsLookupLoading = false, 0)),
				catchError(err => {
					setTimeout(() => this.regionsLookupLoading = false, 0);
					return this.showLookupErrorToast('Regions', err);
				})
			);

		this.zonesLookup$ = this.lookupService.getLookup<LookupView>(LookupCodes.Zones)
			.pipe(
				tap(() => setTimeout(() => this.zonesLookupLoading = false, 0)),
				catchError(err => {
					setTimeout(() => this.zonesLookupLoading = false, 0);
					return this.showLookupErrorToast('Zones', err);
				})
			);
	}

	private showLookupErrorToast(lookupName: string, error: any) {
		this.toastrService.error(Messages.ErrorRetry, `Failed to Load '${lookupName}'`);
		return throwError(() => error);
	}

	private connectLeagueSearch() {
		this.searchSubscription = this.searchChange
			.pipe(
				// 500ms input delay
				debounceTime(500),
				// ignore if query hasn't changed
				distinctUntilChanged(),
				// switch to new search observable (and cancel previous)
				switchMap(query => {
					// save search query
					this.filters.q = query;

					// reset results and page
					this.resetLeagueResults();

					// show loading indicator
					this.leaguesLoading = true;
					this.leaguesError = false;

					return this.leaguesService
						.getLeagues$(
							this.filters.categoryID,
							this.filters.q,
							this.filters.zoneID,
							this.filters.regionID,
							this.leaguesCurrentPage)
						.pipe(
							// handle fetch errors
							catchError(error => {
								console.error('error searching leagues', error);
								this.leaguesError = true;
								this.leaguesLoading = false;
								return of(null);
							}));
				})
			)
			.subscribe(response => {
				this.processLeaguesPageData(response);
				this.leaguesLoading = false;
			});
	}

	private resetLeagueResults() {
		this.leaguesCurrentPage = 1;
		this.leagues = [];
		this.leaguesTotal = null;
	}

	async onLoadLeagues(reset: boolean) {
		if (reset) {
			this.resetLeagueResults();
		}

		if (this.leaguesLoading || (this.leaguesTotal && this.leagues.length >= this.leaguesTotal)) {
			return;
		}

		try {
			this.leaguesLoading = true;
			this.leaguesError = false;

			const response = await this.leaguesService.getLeagues(
				this.filters.categoryID,
				this.filters.q,
				this.filters.zoneID,
				this.filters.regionID,
				this.leaguesCurrentPage
			);

			this.processLeaguesPageData(response);
		} catch (error) {
			this.leaguesError = true;
		} finally {
			this.leaguesLoading = false;
		}
	}

	private processLeaguesPageData(response: PagedModel<LeagueListViewModel> | null) {
		if (!response) {
			return;
		}

		if (!this.leagues) {
			this.leagues = [];
		}

		this.leagues.push(...response.pageData.filter(l => this.leagues.findIndex(ll => ll.leagueId === l.leagueId) === -1));
		this.leaguesTotal = response.totalRecords || this.leagues.length;
		if (response.pageData.length > 0) {
			this.leaguesCurrentPage++;
		}
	}

	// onUpdateLeagueDetails(league: LeagueListViewModel) {
	// 	this.leagueDetailsService.updateCurrentLeague({
	// 		leagueID: league.leagueId,
	// 		leagueName: league.leagueName
	// 	});
	// }
}
