@if (commentsLoading) {
<div class="card-body border-top"><app-table-placeholder [columnsCount]="2" [rowsCount]="2" /></div>
} @else if (commentsError) {
<div class="card-body border-top"><app-error message="Error loading comments" (retry)="onLoadComments()" /></div>
} @else if (comments.length) {
<div class="card-body border-top">
	<!-- @if (post.olderCommentCount > 0) {
		<button class="btn btn-link text-secondary px-0 pt-0 mb-3" (click)="onLoadOlderComments(post)"
			[disabled]="post.loadingOlderComments">
			Load {{ post.olderCommentCount }} older comments...
			@if (post.loadingOlderComments) { <app-spinner class="ms-1" /> }
		</button>
		} -->
	@for (comment of comments; track comment.commentID; let isLastComment = $last) {
	<div class="card bg-light" [class.mb-2]="!isLastComment">
		<div class="card-body py-2 px-3">
			<div class="d-flex align-items-center justify-content-between">
				<h6 class="card-title hstack gap-2">
					@if (comment.isMyComment) { <span class="badge bg-white text-dark">You</span> }
					@else { {{ comment.commentedByFirstName }} {{ comment.commentedByLastName[0] }} }
					<span class="fw-normal text-secondary">{{ comment.commentedDateTime | shortTimeAgo
						}}</span>
				</h6>
				<div class="hstack g-2 h4">
					<div class="dropdown" placement="bottom-end" ngbDropdown>
						<button id="commentActionsDropdownButton{{ comment.commentID }}" class="btn btn-sm"
							type="button" data-bs-toggle="dropdown" aria-expanded="false"
							[attr.aria-controls]="'commentActionsDropdown' + comment.commentID"
							ngbDropdownToggle><fa-icon [icon]="actionsIcon" /></button>
						<ul id="commentActionsDropdown{{ comment.commentID }}" class="dropdown-menu" role="menu"
							[attr.aria-labelledby]="'commentActionsDropdownButton' + comment.commentID" ngbDropdownMenu>
							@if (comment.isMyComment) { <li class="c-pointer" role="menuitem" ngbDropdownItem><a
									class="dropdown-item">Edit</a></li> }
							@if (comment.isMyComment) { <li class="c-pointer" role="menuitem"
								(click)="onDeleteComment(comment)" ngbDropdownItem><a
									class="dropdown-item text-danger">Delete</a></li> }
							@if (!comment.isMyComment) { <li class="c-pointer" role="menuitem"
								(click)="onReportComment(comment)" ngbDropdownItem><a class="dropdown-item">Report</a>
							</li> }
						</ul>
					</div>
					<span class="badge c-pointer"
						[ngClass]="{ 'text-bg-primary': comment.isLikedByMe, 'bg-white text-dark': !comment.isLikedByMe }"
						ngbTooltip="{{ comment.isLikedByMe ? 'Remove like' : 'Like comment' }}"
						(click)="onToggleCommentLiked(comment)">
						{{ comment.likeCount }}
						@if (comment.togglingLike) {
						<app-spinner class="ms-1" />
						} @else {
						<fa-icon class="ms-1" [icon]="comment.isLikedByMe ? upvotedIcon : upvoteIcon" />
						}
					</span>
				</div>
			</div>
			<p class="card-text" [innerHTML]="comment.comment"></p>
		</div>
	</div>
	}
	<!-- @if (post.newerCommentCount > 0) {
		<button class="btn btn-link text-secondary px-0 pb-0 mt-3" (click)="onLoadNewerComments(post)"
			[disabled]="post.loadingNewerComments">
			Load {{ post.newerCommentCount }} newer comments...
			@if (post.loadingNewerComments) { <app-spinner class="ms-1" /> }
		</button>
		} -->
</div>
}
<div class="card-footer">
	<form (ngSubmit)="onAddNewComment()">
		<fieldset [disabled]="newCommentSaving">
			<div class="row gx-2">
				<div class="col">
					<input #commentInput id="commentInput{{ postID }}" name="commentInput{{ postID }}"
						class="form-control" placeholder="Add a comment..." autocomplete="noautocomplete" required
						[(ngModel)]="newComment" />
				</div>
				<div class="col-auto">
					<button class="btn btn-primary" type="submit" [disabled]="!newComment">
						@if (!newCommentSaving) { <fa-icon [icon]="sendIcon" /> }
						<span class="ms-2 d-none d-sm-inline">Add Comment</span>
						@if (newCommentSaving) { <app-spinner class="ms-1" /> }
					</button>
				</div>
			</div>
		</fieldset>
		<!-- <pre>{{commentInput.value | json}}</pre> -->
	</form>
</div>
