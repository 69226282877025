import { Injectable } from '@angular/core';

// import * as pdfjsLib from 'pdfjs-dist/build/pdf';
// // import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
// import { PDFDocument } from 'pdf-lib';
// import pica from 'pica';

// NOTE: THIS SERVICE DOESN'T WORK YET

@Injectable({
	providedIn: 'root'
})
export class PdfService {
	constructor() {
		// pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
	}

	// async processLargePdf(file: File): Promise<File> {
	// 	const pdfData = new Uint8Array(await file.arrayBuffer());
	// 	const pdf = await pdfjsLib.getDocument({ data: pdfData }).promise;

	// 	const isImageOnly = await this.isImageOnlyPdf(pdf);
	// 	if (!isImageOnly) {
	// 		return file; // Return the original file if it contains text
	// 	}

	// 	const downsizedPdf = await this.downsizeImagePages(pdf);
	// 	const pdfBytes = await downsizedPdf.save();
	// 	const blob = new Blob([pdfBytes], { type: 'application/pdf' });

	// 	return new File([blob], file.name, {
	// 		type: file.type,
	// 		lastModified: file.lastModified,
	// 	});
	// }

	// async isImageOnlyPdf(pdf: any): Promise<boolean> {
	// 	for (let i = 1; i <= pdf.numPages; i++) {
	// 		const page = await pdf.getPage(i);
	// 		const textContent = await page.getTextContent();
	// 		if (textContent.items.length > 0) {
	// 			return false; // Found text content
	// 		}
	// 	}
	// 	return true; // All pages are image only
	// }

	// private async downsizeImagePages(pdf: any) {
	// 	const pdfDoc = await PDFDocument.create();
	// 	const picaResizer = pica();

	// 	for (let i = 1; i <= pdf.numPages; i++) {
	// 		const page = await pdf.getPage(i);
	// 		const viewport = page.getViewport({ scale: 1 });
	// 		const canvas = document.createElement('canvas');
	// 		const context = canvas.getContext('2d');

	// 		canvas.width = viewport.width;
	// 		canvas.height = viewport.height;

	// 		const renderContext = {
	// 			canvasContext: context,
	// 			viewport: viewport,
	// 		};
	// 		await page.render(renderContext).promise;

	// 		const resizedCanvas = document.createElement('canvas');
	// 		resizedCanvas.width = viewport.width / 2;
	// 		resizedCanvas.height = viewport.height / 2;

	// 		await picaResizer.resize(canvas, resizedCanvas);

	// 		const imgData = resizedCanvas.toDataURL('image/jpeg');
	// 		const img = await pdfDoc.embedJpg(imgData);

	// 		const pdfPage = pdfDoc.addPage([resizedCanvas.width, resizedCanvas.height]);
	// 		pdfPage.drawImage(img, {
	// 			x: 0,
	// 			y: 0,
	// 			width: resizedCanvas.width,
	// 			height: resizedCanvas.height,
	// 		});
	// 	}
	// 	return pdfDoc;
	// }
}
