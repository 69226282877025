import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter, OnDestroy, inject, booleanAttribute } from '@angular/core';
import { RouterModule } from '@angular/router';

import { Subscription } from 'rxjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFileImport, faSquareArrowDown, faArrowUp } from '@fortawesome/pro-regular-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { SpinnerComponent } from '../loaders/spinner/spinner.component';
import { ViewIconComponent } from '../action-icons/view-icon.component';

import { AlertService, DocumentsService, FileService } from '../../services';
import { DocumentUploadPage } from '../../../document-upload/document-upload.page';
import { DocumentViewerPage } from '../../../document-viewer/document-viewer.page';
import { EntityDocumentViewModel } from '../../models/document.models';

import { Messages } from '../../../constants';

@Component({
	selector: 'app-document-actions',
	templateUrl: './document-actions.component.html',
	styleUrl: './document-actions.component.scss',
	standalone: true,
	imports: [
		CommonModule, RouterModule, FontAwesomeModule, NgbTooltipModule,
		SpinnerComponent, ViewIconComponent,
		DocumentUploadPage
	]
})
export class DocumentActionsComponent implements OnDestroy {
	@Input() leagueID?: number;
	@Input() leagueName?: string;
	@Input() teamID?: number;
	@Input() teamName?: string;
	@Input() coachID?: number;
	@Input() coachName?: string;
	@Input() teamPlayerID?: number;
	@Input() teamPlayerName?: string;
	@Input() affAppID?: number;

	@Input() instructionsFileID?: number;
	@Input() instructionsFileName?: string;

	@Input() viewFileURL?: string;
	@Input() viewFileID?: number;
	@Input() viewSubmissionID?: number;
	@Input() viewFileType?: string;
	@Input() viewFileName?: string;
	@Input({ transform: booleanAttribute }) showViewLabel: boolean = false;

	@Input() uploadDocumentInstanceID?: number;
	@Input() uploadAffAppDocumentTypeID?: number;
	@Input() uploadDocumentName?: string;

	@Input({ transform: booleanAttribute }) showIntake = true;
	@Input({ transform: booleanAttribute }) showUpload = true;
	@Input() uploadLabel = '';

	@Input() buttonSizeClass = '';
	@Input() stackClass = 'hstack';

	@Input({ transform: booleanAttribute }) disabled = false;

	// TODO: CHANGE TO EntityDocumentViewModel ONCE API RETURNS THAT
	@Output() uploaded = new EventEmitter<number>();
	@Output() approved = new EventEmitter<EntityDocumentViewModel>();
	@Output() rejected = new EventEmitter<EntityDocumentViewModel>();

	// internal state
	instructionsFileDownloading = false;

	modalSubscription: Subscription | null = null;

	// icons
	intakeIcon = faFileImport;
	instructionsIcon = faSquareArrowDown;
	uploadIcon = faArrowUp;

	// services
	toastrService: ToastrService = inject(ToastrService);
	modalService: NgbModal = inject(NgbModal);
	alertService: AlertService = inject(AlertService);
	documentsService: DocumentsService = inject(DocumentsService);
	fileService: FileService = inject(FileService);

	ngOnDestroy() {
		this.modalSubscription?.unsubscribe();
	}

	async onDownloadInstructions() {
		if (!this.instructionsFileID) {
			// TODO: SHOW ERROR - SHOULDNT REACH HERE
			return;
		}

		if (this.instructionsFileDownloading) return;

		try {
			this.instructionsFileDownloading = true;

			let file = await this.documentsService.downloadFile(this.instructionsFileID);
			// TODO: DYNAMICALLY DETERMINE FILE TYPE/EXTENSION
			this.fileService.downloadFile(file, this.instructionsFileName ?? 'file');
		} catch (error) {
			console.error(error);
			this.toastrService.error(Messages.ErrorRetry, 'Error Downloading File');
		} finally {
			this.instructionsFileDownloading = false;
		}
	}

	onView() {
		if (!this.viewFileURL && !this.viewFileID) {
			// TODO: SHOW ERROR - SHOULDNT REACH HERE
			return;
		}

		const screenWidth = window.innerWidth;
		const modalOptions = screenWidth <= 576 ? { fullscreen: true } : { size: 'lg' };
		const modalRef = this.modalService.open(DocumentViewerPage, modalOptions);

		modalRef.componentInstance.leagueName = this.leagueName;
		modalRef.componentInstance.teamName = this.teamName;
		modalRef.componentInstance.personName = this.teamPlayerName;

		modalRef.componentInstance.documentName = this.viewFileName;
		modalRef.componentInstance.documentFileURL = this.viewFileURL;
		modalRef.componentInstance.documentFileID = this.viewFileID;
		modalRef.componentInstance.submissionID = this.viewSubmissionID;
		modalRef.componentInstance.extension = this.viewFileType;

		// notify subscribers on successful approvals/rejections
		const approvedSubscription = modalRef.componentInstance.approved.subscribe((result: EntityDocumentViewModel) => this.approved.emit(result));
		const rejectedSubscription = modalRef.componentInstance.rejected.subscribe((result: EntityDocumentViewModel) => this.rejected.emit(result));

		modalRef.closed.subscribe(() => {
			approvedSubscription.unsubscribe();
			rejectedSubscription.unsubscribe();
		});
	}

	async onUploadFileInput(event: Event) {
		const element = event.currentTarget as HTMLInputElement;
		const files: File[] = Array.from(element.files || []);
		if (files?.length > 0) {
			// make sure selected files are not a mix of images and non-images
			// and no multi non-image files
			const isImages = files.every(file => file.type.startsWith('image/'));
			const isNonImages = files.every(file => !file.type.startsWith('image/'));
			if (!isImages && !isNonImages) {
				this.alertService.show('Incompatible File Types', 'You cannot mix images and non-images (PDF, Word, etc) for document uploads.<br/><br/>Please select either multiple images (document pages) or a single non-image file (PDF, Word, Excel. etc) to upload.');
				return;
			} else if (isNonImages && files.length > 1) {
				this.alertService.show('Multiple Non-Image Files', 'You can multi-select only image files (representing pages of a document).<br/><br/>You can select only a single non-image file (PDF, Word, Excel. etc) to upload.');
				return;
			}

			const screenWidth = window.innerWidth;
			const modalOptions = screenWidth <= 576 ? { fullscreen: true } : { size: 'lg' };
			const modalRef = this.modalService.open(DocumentUploadPage, modalOptions);
			modalRef.componentInstance.leagueID = this.leagueID;
			modalRef.componentInstance.leagueName = this.leagueName;
			modalRef.componentInstance.teamID = this.teamID;
			modalRef.componentInstance.teamName = this.teamName;
			modalRef.componentInstance.coachID = this.coachID;
			modalRef.componentInstance.coachName = this.coachName;
			modalRef.componentInstance.teamPlayerID = this.teamPlayerID;
			modalRef.componentInstance.teamPlayerName = this.teamPlayerName;
			modalRef.componentInstance.affAppID = this.affAppID;

			modalRef.componentInstance.documentInstanceID = this.uploadDocumentInstanceID;
			modalRef.componentInstance.affAppDocumentTypeID = this.uploadAffAppDocumentTypeID;
			modalRef.componentInstance.documentName = this.uploadDocumentName;

			modalRef.componentInstance.files = files;
			// notify subscribers on successful uploads
			// TODO: CHANGE TO EntityDocumentViewModel ONCE API RETURNS THAT
			this.modalSubscription = modalRef.closed.subscribe((result: number) => {
				this.uploaded.emit(result);
				this.modalSubscription?.unsubscribe();
			});
		}
	}

	onResetFileInput(fileInput: HTMLInputElement) {
		// clear the input (so user can select the same file after cancelling out)
		fileInput.value = '';
	}
}
