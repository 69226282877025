<div class="modal-header px-4">
	<h4 class="modal-title">
		{{ title }}
	</h4>

	<button type="button" class="btn-close" (click)="onCancel()">
		<span class="visually-hidden">&times;</span>
	</button>
</div>

<div class="modal-body px-4 py-3">
	<label class="form-label" for="leagueSelect">League to connect</label>
	<ng-select labelForId="leagueSelect" name="leagueSelect" [(ngModel)]="selectedLeagueID"
		[loading]="leaguesLookupLoading" notFoundText="No matching leagues" [clearable]="false"
		[loading]="leaguesLookupLoading" [readonly]="leaguesLookupLoading">
		@for (league of (leaguesLookup$ | async); track league.id) {
		<ng-option [value]="league.id">{{ league.name }}</ng-option>
		}
	</ng-select>
</div>

<div class="modal-footer bg-white px-4">
	<button type="button" class="btn btn-outline-secondary" (click)="onCancel()">
		Cancel
	</button>
	<button type="submit" class="btn" [ngClass]="actionButtonClass" (click)="onConfirm()"
		[disabled]="inProgress || !selectedLeagueID">
		{{ actionTitle }} @if(inProgress) { <app-spinner class="ms-1" /> }
	</button>
</div>
